import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ICloudFile, ICloudFiles } from "./model";

declare global {
    interface IStoreState {
        cloudFiles: ICloudFiles;
    }
}

const useStats = "cloudFiles";
const isEqual = (a: ICloudFile, b: ICloudFile) => a.properties.lastModified == b.properties.lastModified;

const cloudFiles = createSlice({
    name: "cloudFiles",
    initialState: null as ICloudFiles,
    reducers: {
        set: (state, { payload }: PayloadAction<ICloudFile[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ name }) => name), isEqual, useStats })
        ),
    },
});

export const actionsCreators = {
    ...cloudFiles.actions,
};

export default {
    [cloudFiles.name]: cloudFiles.reducer,
};