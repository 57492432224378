import _ from "lodash";
import { getNodeFirstParentOfType } from "../node/selectors";
import { reportIsMultiNode } from "../reports/configurations";

export const getMachineSamples = ({ samples }: IStoreState) => samples;

export const getMachineCategories = ({ categories }: IStoreState) => categories;

// samples are on a machine and machine children nodes have access to those samples
export const getNodeMachineSamplesByType = (state: IStoreState, sampleType: string) => (
    _.pickBy(state.samples, ({ type, nodeId }) => {
        const nodeIds = state.reportConfigurations[state.userPrefs.selectedReportConfigurationId]?.nodeIds;
        // multi node reports do not have samples
        return type == sampleType && !reportIsMultiNode({ nodeIds }) && _.find(nodeIds, (id) => getNodeFirstParentOfType(state, id, "machineCodec", true)?.id == nodeId);
    })
);

// samples are on a machine and machine children nodes have the same categories
export const getNodeMachineCategoriesByType = (state: IStoreState, sampleType: string) => (
    _.pickBy(state.categories, ({ type, nodeId }) => {
        const nodeIds = state.reportConfigurations[state.userPrefs.selectedReportConfigurationId]?.nodeIds;
        // multi node reports do not have samples
        return type == sampleType && !reportIsMultiNode({ nodeIds }) && _.find(nodeIds, (id) => getNodeFirstParentOfType(state, id, "machineCodec", true)?.id == nodeId);
    })
);