import { t } from "@comact/crc";
import { ICustomValidator } from "js/components/codeEditor";
import { FileType } from "js/utils";
import _ from "lodash";

export enum ReportJobType {
    PRINT_JOB_TYPE = "print",
    MAIL_JOB_TYPE = "mail"
}

export interface IReportJob {
    id: string;
    type: ReportJobType;
    jobTitle: string;
    fileType: FileType;
    reportIDs: string[];
    delay: number;
    useAsDefault: boolean;
}

export interface IMailReportJob extends IReportJob {
    type: ReportJobType.MAIL_JOB_TYPE;
    usersIDs: string[];
    subject: string;
    message: string;
    fileNameTemplate: string;
}

export interface IPrintReportJob extends IReportJob {
    type: ReportJobType.PRINT_JOB_TYPE;
}

export type IReportJobs = { [jobId: string]: IReportJob; };

/// ////////// EDIT /////////////

type ICustomValidators<T extends IReportJob> = { [key in keyof T]?: ICustomValidator };

export interface IReportJobEdit {
    job: IPrintReportJob | IMailReportJob;
    errors: IReportJobErrors<IPrintReportJob | IMailReportJob>;
    customValidators: ICustomValidators<IPrintReportJob | IMailReportJob>;
    isModified: boolean;
}

export const createNew = (type: ReportJobType = null) => {
    switch (type) {
        case ReportJobType.PRINT_JOB_TYPE: {
            const obj: IPrintReportJob = {
                id: "new_" + Date.now(),
                delay: 0,
                type: ReportJobType.PRINT_JOB_TYPE,
                jobTitle: "",
                reportIDs: [],
                fileType: FileType.pdf,
                useAsDefault: true,
            };
            return obj;
        }
        default:
        case ReportJobType.MAIL_JOB_TYPE: {
            const obj: IMailReportJob = {
                id: "new_" + Date.now(),
                delay: 0,
                type: ReportJobType.MAIL_JOB_TYPE,
                jobTitle: "",
                reportIDs: [],
                fileType: FileType.pdf,
                usersIDs: [],
                subject: t("reports.jobs.defaultSubject"),
                message: "",
                fileNameTemplate: t("reports.jobs.defaultFileNameTemplate"),
                useAsDefault: true,
            };
            return obj;
        }
    }
};

// ----------- Gestion d'erreurs ---------------

const JOB_TITLE_MAX_CHAR = 36;
const EMAIL_CONTENT_MAX_CHAR = 256;
const EMAIL_SUBJECT_MAX_CHAR = 64;
const EMAIL_FILENAME_TEMPLATE_MAX_CHAR = 64;

export type IReportJobErrors<T extends IReportJob> = {
    [key in keyof T]?: string[];
};

const validateMailJob = (job: IMailReportJob): IReportJobErrors<IMailReportJob> => {
    // global validation
    const errors: IReportJobErrors<IMailReportJob> = {};

    // mail job validation
    const { usersIDs, subject, message, fileType, fileNameTemplate } = job;

    if (!subject || subject.length == 0 || subject.length > EMAIL_SUBJECT_MAX_CHAR) {
        errors.subject = [t("reports.jobs.errors.emailSubjectLength", { maxLength: EMAIL_SUBJECT_MAX_CHAR })];
    }

    if (!fileNameTemplate || fileNameTemplate.length == 0 || fileNameTemplate.length > EMAIL_FILENAME_TEMPLATE_MAX_CHAR) {
        errors.fileNameTemplate = [t("reports.jobs.errors.emailFileNameTemplateLength", { maxLength: EMAIL_SUBJECT_MAX_CHAR })];
    }

    if (message && message.length > EMAIL_CONTENT_MAX_CHAR) {
        errors.message = [t("reports.jobs.errors.emailContentLength", { maxLength: EMAIL_CONTENT_MAX_CHAR })];
    }

    if (!(usersIDs?.length)) {
        errors.usersIDs = [t("reports.jobs.errors.notEnoughUsers")];
    }

    if (!FileType[fileType]) {
        errors.fileType = [t("reports.jobs.errors.fileTypeInvalid")];
    }

    return errors;
};

export const validateJob = (job: IReportJob, customValidators: ICustomValidators<typeof job>): IReportJobErrors<IReportJob> => {
    // can't validate
    if (!job) return {};

    // validate common fields (stuff present in all report jobs)
    const { jobTitle, reportIDs } = job;
    const errors: IReportJobErrors<typeof job> = {};

    if (!(jobTitle && jobTitle.length > 0 && jobTitle.length <= JOB_TITLE_MAX_CHAR)) {
        errors.jobTitle = [t("reports.jobs.errors.jobTitleLength", { maxLength: JOB_TITLE_MAX_CHAR })];
    }
    if (!(reportIDs && reportIDs.length > 0)) {
        errors.reportIDs = [t("reports.jobs.errors.notEnoughReports")];
    }

    // call custom validators
    _.forEach(customValidators, (validator, key) => {
        const customErrors = validator?.();
        if (!_.isEmpty(customErrors)) errors[key] = customErrors;
    });

    // validate custom fields and combine with errors from common fields
    switch (job.type) {
        case ReportJobType.MAIL_JOB_TYPE:
            return { ...errors, ...validateMailJob(job as IMailReportJob) };
        case ReportJobType.PRINT_JOB_TYPE:
            // There are currently no fields specific to print jobs so there is nothing to validate
            return errors;
        default:
            return errors;
    }
};