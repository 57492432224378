import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export type IPoint = { x: number; y: number; };
export type IPointServer = [number, number];

export interface IZoneConfigurationServer {
    active: boolean;
    template: string; // name of the template
    fields: IField[];
}

export interface IZoneConfigurationActivationStatus {
    status: "activated" | "unactivated" | "error" | "activating";
    error?: string;
}

export const ACTIVATION_DISABLED_STATUSES: IZoneConfigurationActivationStatus["status"][] = ["activated", "activating"];

export interface IZoneConfigurationFields {
    [fieldId: string]: string | number | boolean;
}

export interface IZoneConfiguration extends Omit<IZoneConfigurationServer, "fields"> {
    readonly nodeId: string;
    fields: IFields;
}

export interface IZoneConfigurationAdvanced {
    config: string;
}

export type IZoneConfigurations = { [zoneConfigurationId: string]: IZoneConfiguration; };
export type IZoneConfigurationsAdvanced = { [zoneConfigurationId: string]: IZoneConfigurationAdvanced; };
export type IZoneConfigurationsActivationStatus = { [zoneConfigurationId: string]: IZoneConfigurationActivationStatus; };

export interface IZoneTemplate {
    id: string;
    label: ISmartVisionLocalizedString;
    desc: ISmartVisionLocalizedString;
}

export type IZoneTemplates = { [templateId: string]: IZoneTemplate; };

export interface ISmartVisionZoneAddress {
    host: string;
    port: number;
}

export interface ISmartVisionLocalizedString {
    en: string;
    fr?: string;
    pt?: string;
    es?: string;
}

export const fieldTypes = ["str", "bool", "float", "int", "warp", "aoi", "ipaddress", "choice"] as const;

export type IField = IFieldString | IFieldBool | IFieldFloat | IFieldInt | IFieldWarp | IFieldAOI | IFieldChoice | IFieldOther;

export type IFields = { [fieldId: string]: IField; };

export const isOtherField = (field: IField): field is IFieldOther => !_.includes(fieldTypes, field.type);

interface IFieldAbstract {
    id: string;
    type: unknown;
    label: ISmartVisionLocalizedString;
    desc: ISmartVisionLocalizedString;
    value: unknown;
    choices?: string[];
}

interface IFieldString extends IFieldAbstract {
    type: "str" | "ipaddress";
    value: string;
}

interface IFieldBool extends IFieldAbstract {
    type: "bool";
    value: boolean;
}

interface IFieldFloat extends IFieldAbstract {
    type: "float";
    value: number;
}

export interface IFieldWarp extends IFieldAbstract {
    type: "warp";
    value: IPointServer[];
    stream: string;
}

export interface IFieldAOI extends IFieldAbstract {
    type: "aoi";
    value: [number, number, number, number];
    stream: string;
}

interface IFieldInt extends IFieldAbstract {
    type: "int";
    value: number;
}

interface IFieldChoice extends IFieldAbstract {
    type: "choice";
    value: string;
    choices: string[];
}

interface IFieldOther extends IFieldAbstract {
    type: "other";
    value: string;
}

export const smartVisionStringToLocalizedString = (localizedString: ISmartVisionLocalizedString): ILocalizedString => ({ values: { ...localizedString } });