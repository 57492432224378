import { ILineSpeed, ILineSpeeds } from "./model";

const SPEED_CACHE: ILineSpeeds = {
    1: {
        scannerId: "1",
        speed: 100,
        encoderTach: 10,
        name: "Test 1",
        unit: {
            id: 32,
            type: "Linear Speed",
            system: "Imperial",
            name: "foot per minute",
            symbol: "ft/min",
        },
    },
    2: {
        scannerId: "2",
        speed: 200,
        encoderTach: 20,
        name: "Test 2",
        unit: {
            id: 32,
            type: "Linear Speed",
            system: "Imperial",
            name: "foot per minute",
            symbol: "ft/min",
        },
    },
    3: {
        scannerId: "3",
        speed: 300,
        encoderTach: 30,
        name: "Test 3",
        unit: {
            id: 32,
            type: "Linear Speed",
            system: "Imperial",
            name: "foot per minute",
            symbol: "ft/min",
        },
    },
    4: {
        scannerId: "4",
        speed: 400,
        encoderTach: 30,
        name: "Test 4",
        unit: {
            id: 32,
            type: "Linear Speed",
            system: "Imperial",
            name: "foot per minute",
            symbol: "ft/min",
        },
    },
};

export const mockLineSpeed = (scannerId: string): ILineSpeed => SPEED_CACHE[scannerId];