import { ILocalizedString } from "@comact/crc";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ILabelTypes, ILabelTypesServer, LABELS_TYPE, LABELS_TYPE_NAMES } from "../labels/model";

export interface ILabelsGroup {
    readonly id: string;
    sortingPriority: number;
    name: ILocalizedString;
    type: ILabelTypes;
}

export interface ILabelsGroupServer extends Omit<ILabelsGroup, | "type"> {
    type: ILabelTypesServer;
}
export type ILabelGroups = { [id: string]: ILabelsGroup; };

export const convertLabelGroupsToServer = (labelGroups: ILabelsGroup[]): ILabelsGroupServer[] => (
    _.map(labelGroups, (group) => ({ ...group, type: LABELS_TYPE[group.type] }))
);

export const convertLabelGroupsFromServer = (labelGroups: ILabelsGroupServer[]): ILabelGroups => {
    const groupsWithKey: ILabelsGroup[] = _.map(labelGroups, (group) => ({
        ...group,
        type: LABELS_TYPE_NAMES[group.type],
    }));
    return _.keyBy(groupsWithKey, ({ id }) => id);
};

export const createNewGroup = (labelGroup: Partial<ILabelsGroup> & Pick<ILabelsGroup, "type">): ILabelsGroup => {
    const randomId = uuidv4(); // only for frontend, recreated in backend
    return ({
        id: "new_" + randomId,
        sortingPriority: 0,
        name: {
            values: {
                fr: "",
                en: "",
            },
        },
        ...labelGroup,
    });
};
