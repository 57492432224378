import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../node";
import { ICloudAgentStatItem, ICloudAgentStatItemExtra, ICloudAgentStats } from "./model";

export const getAll = memoize(({ cloudAgentStats }: IStoreState) => cloudAgentStats);

export const getAllFilesPending = memoize(({ cloudAgentFilesPending }: IStoreState) => cloudAgentFilesPending);

export const makeGetAllWithExtraByHour = () => memoize(
    ({ state, selectedHour }: { state: IStoreState; selectedHour?: number; }): ICloudAgentStatItemExtra[] => {
        const nodes = NodesModule.selectors.getAllNodes(state);
        const allStats = getAll(state);

        const allStatsFiltered = selectedHour
            ? _.reduce(allStats, (acc, stat, key) => {
                const filteredData = _.filter(stat.data, (d) => d.timeStampLong == selectedHour);
                if (_.isEmpty(filteredData)) {
                    return acc;
                }
                acc[key] = {
                    ...stat,
                    data: filteredData,
                };

                return acc;
            }, {} as ICloudAgentStats)
            : allStats;

        return _.map(allStatsFiltered, (stats) => ({
            ...stats,
            data: _.orderBy(stats.data, ({ timeStampLong }) => timeStampLong, "desc"),
            nodeName: nodes[stats.nodeId]?.name,
            totals: {
                messagesSent: _.sumBy(stats.data, (d) => d.messagesSent),
                messagesReceived: _.sumBy(stats.data, (d) => d.messagesReceived),
                reportedPropertiesUpdated: _.sumBy(stats.data, (d) => d.reportedPropertiesUpdated),
                desiredPropertiesUpdated: _.sumBy(stats.data, (d) => d.desiredPropertiesUpdated),
                filesUploaded: _.sumBy(stats.data, (d) => d.filesUploaded),
                reconnections: _.sumBy(stats.data, (d) => d.reconnections),
                messagesDropped: _.sumBy(stats.data, (d) => d.messagesDropped),
            },
        }));
    }
);

export const makeGetAllGlobalByHour = () => {
    const getAllWithExtraByHour = makeGetAllWithExtraByHour();
    return memoize(
        ({ state, selectedHour }: { state: IStoreState; selectedHour?: number; }): ICloudAgentStatItemExtra => {
            const cloudAgentStatsExtra = getAllWithExtraByHour({ state, selectedHour });
            const globalData: Record<string, ICloudAgentStatItem["data"][number]> = {};

            _.forEach(cloudAgentStatsExtra, (stats) => {
                _.forEach(stats.data, (d) => {
                    if (!globalData[d.timeStampLong]) {
                        globalData[d.timeStampLong] = {
                            desiredPropertiesUpdated: 0,
                            deviceId: null,
                            filesUploaded: 0,
                            messagesReceived: 0,
                            messagesSent: 0,
                            reportedPropertiesUpdated: 0,
                            reconnections: 0,
                            messagesDropped: 0,
                            timeStampLong: d.timeStampLong,
                        };
                    }
                    globalData[d.timeStampLong].messagesSent += d.messagesSent;
                    globalData[d.timeStampLong].messagesReceived += d.messagesReceived;
                    globalData[d.timeStampLong].reportedPropertiesUpdated += d.reportedPropertiesUpdated;
                    globalData[d.timeStampLong].desiredPropertiesUpdated += d.desiredPropertiesUpdated;
                    globalData[d.timeStampLong].filesUploaded += d.filesUploaded;
                    globalData[d.timeStampLong].reconnections += d.reconnections;
                    globalData[d.timeStampLong].messagesDropped += d.messagesDropped;
                });
            });
            const data = _.orderBy(globalData, ({ timeStampLong }) => timeStampLong, "desc");
            return {
                deviceId: null,
                nodeId: null,
                nodeName: null,
                data,
                totals: {
                    messagesSent: _.sumBy(data, (d) => d.messagesSent),
                    messagesReceived: _.sumBy(data, (d) => d.messagesReceived),
                    reportedPropertiesUpdated: _.sumBy(data, (d) => d.reportedPropertiesUpdated),
                    desiredPropertiesUpdated: _.sumBy(data, (d) => d.desiredPropertiesUpdated),
                    filesUploaded: _.sumBy(data, (d) => d.filesUploaded),
                    reconnections: _.sumBy(data, (d) => d.reconnections),
                    messagesDropped: _.sumBy(data, (d) => d.messagesDropped),
                },
            };
        }
    );
};

export const getAvailableHours = memoize(
    (state: IStoreState) => {
        const cloudAgentStatsExtra = getAll(state);
        const globalData: Record<string, number> = {};

        _.forEach(cloudAgentStatsExtra, (stats) => {
            _.forEach(stats.data, (d) => {
                if (!globalData[d.timeStampLong]) {
                    globalData[d.timeStampLong] = d.timeStampLong;
                }
            });
        });
        return _.orderBy(globalData, (data) => data).reverse();
    }
);