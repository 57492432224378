import { createModule } from "@comact/crc";
import * as requests from "./requests";
import * as selectors from "./selectors";
import reducers, { actionsCreators } from "./slices";

export * from "./model";

export const ELearningModule = createModule({
    reducers, actionsCreators, requests, selectors,
});
