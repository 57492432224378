export interface IMUnit {
    id: string;
    index: number;
    nbDecimal: number;
    unitType: string;
    conversionFactor: number;
    unitName: string;
    unitLabel: string;
    unitSymbol: string;
}

export type IMUnits = { [id: string]: IMUnit; };

export type IMUnitTypes = string[];

export interface IMUnitsState {
    types: IMUnitTypes;
    units: IMUnits;
}

export const CUSTOM_MUNIT = "CUSTOM";
export const PERCENTAGE_MUNIT = "PERCENTAGE";