import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IIntervalCategories, IIntervalCategory, sortIntervals } from "./model";

declare global{
    interface IStoreState {
        intervalCategories: IIntervalCategories;
    }
}

const useStats = "intervalCategories";

const intervalCategories = createSlice({
    name: "intervalCategories",
    initialState: {} as IIntervalCategories,
    reducers: {
        set: (state, { payload }: PayloadAction<IIntervalCategories>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.chain(payload)
                    .map((category) => ({ ...category, intervals: sortIntervals(category.intervals) }))
                    .keyBy(({ name }) => name)
                    .value(),
                useStats,
            })
        ),
        setByType: (state, { payload }: PayloadAction<Pick<IIntervalCategory, "name" | "intervals">>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: { [payload.name]: { ...state[payload.name], intervals: payload.intervals } }, useStats })
        ),
    },
});

export const actionsCreators = {
    ...intervalCategories.actions,
};

export default {
    [intervalCategories.name]: intervalCategories.reducer,
};