import _ from "lodash";
import { IInterval, IIntervalCategories, IIntervalCategory } from "./model";

export const getAllCategories = (): IIntervalCategories => ({
    RawWidth: getIntervalCategory("RawWidth", 0, 12, 0.125, 0, " in", "en"),
    RawThickness: getIntervalCategory("RawThickness", 1, 12, 0.125, 0, " in", "en"),
    RawLength: getIntervalCategory("RawLength", 2, 12, 1, 8, " ft", "en"),
    StemLength: getIntervalCategory("StemLength", 13, 9, 8, 0, " ft", "en"),
});

export const getIntervalCategory =
    (name: string, type: number, size: number, step: number, initValue: number, unit: string, langKey: string): IIntervalCategory => ({
        name,
        type,
        unit,
        min: 0,
        max: 9999,
        intervals: getIntervals(size, step, initValue, unit, langKey),
    });

export const getIntervals = (size: number, step: number, initValue: number, unit: string, languageKey: string): IInterval[] => (
    _.times(size, (index) => {
        const minimum = initValue + step * index;
        const maximum = initValue + step * (index + 1);

        const values = {
            fr: (minimum.toString() + "-" + maximum.toString() + "fr"),
            en: (minimum.toString() + "-" + maximum.toString() + "en"),
            pt: (minimum.toString() + "-" + maximum.toString() + "pt"),
            fi: (minimum.toString() + "-" + maximum.toString() + "fi"),
        };
        return ({
            minimum,
            maximum,
            unit,
            id: index.toString(),
            type: 0,
            name: {
                display: values[languageKey],
                values,
            },
        });
    })
);