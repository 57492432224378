import { useMapState, usePromisedState } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import { authDigest } from "js/cameraLiveStream/model";
import { getNonce } from "js/cameraLiveStream/requests";
import { VideoJS } from "js/components/VideoJs";
import { API_PREFIX_HLS } from "js/constants";
import { ICameraNode, ICameraServerNode, NodesModule } from "js/node";
import * as React from "react";

const CameraVideoStyled = styled.div`
    display: flex;
    position: relative;
    min-height: 50vh;
    margin: ${CSS.gutter * -1}px ${CSS.gutter * -2}px ${CSS.gutter * -2}px ${CSS.gutter * -2}px; // to counter the SimpleModal padding, see _simpleModal.scss to understand
    > .cameraContainer {
        background-color: ${CSS.colors.black};
        display: flex;
        position: absolute;
        inset: 0;
        > img, > video {
            object-fit: contain;
        }
    }
`;

// Get info from the camera node and it's parent, the camera server to be able to create an url to access a video or an image.
const useCameraInfo = (cameraNodeId: string) => (
    useMapState((state) => {
        const cameraNode = NodesModule.selectors.getNodeById<ICameraNode>(state, cameraNodeId);
        const cameraServerNode = NodesModule.selectors.getNodeById<ICameraServerNode>(state, cameraNode.parentId);
        return {
            internalCameraId: cameraNode.internalCameraId,
            username: cameraServerNode.username,
            password: cameraServerNode.password,
            host: cameraServerNode.host,
        };
    }, [cameraNodeId])
);

interface ICameraVideoProps {
    nodeId: string;
    resolution?: "480p" | "720p" | "1080p";
}

const CameraVideo = React.memo(({ nodeId, resolution = "480p" }: ICameraVideoProps) => {
    const { internalCameraId, username, password, host } = useCameraInfo(nodeId);

    const source = usePromisedState(async () => {
        const auth = authDigest({ username, password, nonce: await getNonce(host) });
        return `http://${host}/media/${internalCameraId}.mp4?auth=${auth}&resolution=${resolution}`;
    }, [host, resolution]);

    return (
        <CameraVideoStyled>
            <div className="cameraContainer">
                <video src={source} autoPlay={true} onContextMenu={(e) => e.preventDefault()} controls={false} muted>
                    <source src={source} type="video/mp4" />
                </video>
            </div>
        </CameraVideoStyled>
    );
});

export const HlsVideo = React.memo(({ nodeId }: { nodeId: string; }) => (
    <CameraVideoStyled>
        <div className="cameraContainer">
            <VideoJS source={API_PREFIX_HLS + nodeId + "/output.m3u8"} type="application/x-mpegURL" />
        </div>
    </CameraVideoStyled>
));

export default CameraVideo;

interface ICameraImageProps {
    nodeId: string;
    height?: number; //
    // width?: number; // Does not seems to work...
    refreshInterval?: number;
}
export const CameraImage = React.memo(({ nodeId, height, refreshInterval = 1000 * 60 }: ICameraImageProps) => {
    const { internalCameraId, username, password, host } = useCameraInfo(nodeId);

    const source = usePromisedState(async () => {
        const auth = authDigest({ username, password, nonce: await getNonce(host) });
        let url = `http://${host}/ec2/cameraThumbnail/?cameraId=${internalCameraId}&auth=${auth}&imageFormat=jpg`;
        if (height) url += `&height=${height}`;
        return url;
    }, [host, height]);

    const imageRef = React.useRef<HTMLImageElement>();

    const [refreshCount, setRefreshCount] = React.useState(0);

    // Img reload at refresh interval
    React.useEffect(() => {
        const img = imageRef.current;
        if (!img) return null;
        let timeout: number;

        const onDone = () => {
            timeout = window.setTimeout(() => { setRefreshCount((c) => c + 1); }, refreshInterval);
        };
        img.addEventListener("load", onDone);
        return () => {
            img.removeEventListener("load", onDone);
            window.clearTimeout(timeout);
        };
    }, [imageRef, refreshInterval]);

    return (
        <CameraVideoStyled>
            <div className="cameraContainer">
                <img ref={imageRef} src={`${source}&refreshCount=${refreshCount}`} />
            </div>
        </CameraVideoStyled>
    );
});