import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ICategories, ICategory, ISample, ISamples } from "./model";

declare global {
    interface IStoreState {
        samples: ISamples;
        categories: ICategories;
    }
}

const useStats = "samples";

const samples = createSlice({
    name: "samples",
    initialState: null as ISamples,
    reducers: {
        set: (state, { payload }: PayloadAction<ISample[]>) => (
            // on utilise pas le modificationStamp, car pour l'instant c'est la même que la creationDate dans la BD. Et cela crée un problème lors d'un import.
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats,
            })
        ),
    },
});

const useStatsCategories = "categories";

const categories = createSlice({
    name: "categories",
    initialState: null as ICategories,
    reducers: {
        setCategories: (state, { payload }: PayloadAction<ICategory[]>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats: useStatsCategories,
            })
        ),
    },
});

export const actionsCreators = {
    ...samples.actions,
    ...categories.actions,
};

export default {
    [samples.name]: samples.reducer,
    [categories.name]: categories.reducer,
};