import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export interface IReportConfiguration {
    readonly id: string;
    label: ILocalizedString;
    templateId: string;
    nodeIds: string[]; // only many in case of multi node report config
    displayPriority: number;
    system: boolean;
    enabled: boolean;
    tags: string[];
    parameters: IReportConfigurationParameter[];
}

export const reportIsMultiNode = (reportConfig: Pick<IReportConfiguration, "nodeIds">) => _.size(reportConfig?.nodeIds) > 1;

export interface IReportConfigurationParameters { [name: string]: IReportConfigurationParameter; }

export type IReportConfigurationParameter = IReportConfigurationParameterString | IReportConfigurationParameterInteger
| IReportConfigurationParameterBoolean | IReportConfigurationParameterDouble | IReportConfigurationParameterDateAndTime;
export interface IReportConfigurationParameterAbstract {
    group: string;
    label: ILocalizedString;
    name: string;
    type: "Boolean" | "String" | "Integer" | "Double" | "Date_Time";
    valueClass: string;
    value: string | string[] | boolean | boolean[] | number | number[];
    choices: IParameterChoice[];
    cascading: boolean;
    multiSelect: boolean;
    isDynamic: boolean;
    visible: boolean;
    required: boolean;
    parent: string;
}

export interface IReportConfigurationParameterString extends IReportConfigurationParameterAbstract {
    type: "String";
    value: string | string[];
}

export interface IReportConfigurationParameterInteger extends IReportConfigurationParameterAbstract {
    type: "Integer";
    value: number | number[];
}

export interface IReportConfigurationParameterDouble extends IReportConfigurationParameterAbstract {
    type: "Double";
    value: number | number[];
}
export interface IReportConfigurationParameterBoolean extends IReportConfigurationParameterAbstract {
    type: "Boolean";
    value: boolean | boolean[];
}

export interface IReportConfigurationParameterDateAndTime extends IReportConfigurationParameterAbstract {
    type: "Date_Time";
    value: number | number[];
}

export interface IReportConfigurationParameterChoices {
    [parameterName: string]: IParameterChoice[];

}

export interface IParameterChoice {
    label: ILocalizedString;
    value: number | string;
    children?: IParameterChoice[];
}

export type IReportConfigurations = { [reportConfigurationId: string]: IReportConfiguration; };

export type IReportConfigurationValidationError = { [key in keyof IReportConfiguration]?: string[] };
export type IReportConfigurationValidationErrorList = { [machineNodeId: string]: IReportConfigurationValidationError; };

export const generateDefaultReportConfiguration = (id?: string): IReportConfiguration => ({
    id: id ?? `new_${Date.now()}`,
    nodeIds: [],
    enabled: true,
    system: false,
    label: {
        values: {
            en: "",
            fr: "",
            fi: "",
            pt: "",
        },
    },
    templateId: undefined,
    displayPriority: 0,
    tags: [],
    parameters: [],
});