import { ILocalizedString } from "@comact/crc";
import _ from "lodash";
import { v4 as uuid } from "uuid";

export const timberTypeList = ["TRIMBLOCK", "PRODUCT", "BOARD", "LUMBER", "CANT", "LOG", "STEM"] as const;

export interface ITagStrategyServer {
    id?: string;
    dataFieldName: string; // take the name (uglified) on creation
    defaultValue: string;
    name: ILocalizedString;
    rules: {
        predicate: string;
        value: string;
    }[];
    timberType: typeof timberTypeList[number];
}

export interface ITagStrategy extends Omit<ITagStrategyServer, "rules"> {
    id: string;
    rules: Record<string, ITagStrategyServer["rules"][number] & { id: string; }>; // add an id
}

export type ITagStrategies = Record<string, ITagStrategy>;

export const createNewForServer = (partialData: Partial<ITagStrategyServer> = {}): ITagStrategyServer => {
    const id = partialData.id || uuid();
    return {
        id,
        dataFieldName: _.camelCase(id),
        defaultValue: "",
        name: { values: { en: "", fr: "", pt: "", fi: "" } },
        rules: [],
        timberType: null,
        ...partialData,
    };
};

export const createNew = (partialData: Partial<ITagStrategy> = {}): ITagStrategy => ({
    ...convertFromServer(createNewForServer()),
    ...partialData,
});

export const convertToServer = (tagStrategy: ITagStrategy): ITagStrategyServer => ({
    ...tagStrategy,
    rules: _.map(tagStrategy.rules, ({ id, ...rest }) => rest),
});

export const convertFromServer = (tagStrategyServer: ITagStrategyServer): ITagStrategy => ({
    id: null,
    ...tagStrategyServer,
    rules: _(tagStrategyServer.rules)
        .map((rule) => ({ ...rule, id: uuid() }))
        .keyBy(({ id }) => id)
        .value(),
});