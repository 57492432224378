import { AjaxLoadingModule, IAjaxRequestError, ModalModule, ajaxRequest, t } from "@comact/crc";
import _ from "lodash";
import normalizeUrl from "normalize-url";
import * as mocks from "./mocks";
import { IFields, IZoneConfiguration, IZoneConfigurationActivationStatus, IZoneConfigurationAdvanced, IZoneConfigurationFields, IZoneConfigurationServer, IZoneTemplate, isOtherField } from "./model";
import { getSmartVisionZoneAddress } from "./selectors";
import { actionsCreators } from "./slices";

export const getBasicZoneConfiguration = (nodeId: string, showError = true) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });

    return (
        ajaxRequest({
            serverLessResolve: () => getState().zoneConfigurationsBasic?.[nodeId] ? getState().zoneConfigurationsBasic[nodeId] : mocks.getZoneConfigurationBasic(),
            url: normalizeUrl(`${host}:${port}/config-basic-fields`),
            error: showError && { popupOpened: true },
            onSuccess: (zoneConfiguration: IZoneConfigurationServer) => {
                dispatch(actionsCreators.patchBasic(convertFromServer(zoneConfiguration, nodeId)));
            },
            onError: () => dispatch(actionsCreators.patchBasic(convertFromServer({ active: false, fields: [], template: "unavailable" }, nodeId))),
        })
    );
};

export const getAdvancedZoneConfiguration = (nodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });
    return (
        ajaxRequest({
            serverLessResolve: () => mocks.getZoneConfigurationAdvanced(),
            // id parameter is only to make sure request is done again if templateId changes
            url: normalizeUrl(`${host}:${port}/config?id=${getState().zoneConfigurationsBasic?.[nodeId].template}`),
            error: { popupOpened: true },
            rawResponse: true,
            onSuccess: (config: string) => {
                dispatch(actionsCreators.patchAdvanced({ advancedConfig: { config }, nodeId }));
            },
        })
    );
};

export const getConfigTemplates = (nodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });

    return (
        ajaxRequest({
            serverLessResolve: () => mocks.getConfigTemplates(),
            url: normalizeUrl(`${host}:${port}/config-templates/all`),
            onSuccess: (configTemplates: IZoneTemplate[]) => {
                dispatch(actionsCreators.setTemplates(configTemplates));
            },
        })
    );
};

export const applyConfigTemplate = (nodeId: string, templateId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });

    return (
        ajaxRequest({
            url: normalizeUrl(`${host}:${port}/config/apply-config-templates/${templateId}`),
            method: "POST",
            rawResponse: true,
            error: { popupOpened: true },
            showAjaxLoading: true,
            onSuccess: (config: string) => {
                dispatch(actionsCreators.patchAdvanced({ advancedConfig: { config }, nodeId }));
            },
            onError: (error: IAjaxRequestError) => { throw error; },
        }).promise
    );
};

export const saveZoneConfigurationBasic = (zoneConfiguration: IZoneConfiguration) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId: zoneConfiguration.nodeId });

    return (
        ajaxRequest({
            url: normalizeUrl(`${host}:${port}/config-basic-fields`),
            method: "PATCH",
            data: convertToServer(zoneConfiguration),
            onSuccess: () => {
                dispatch(actionsCreators.patchBasic(zoneConfiguration));
            },
        })
    );
};

export const saveZoneConfigurationAdvanced = (advancedConfig: IZoneConfigurationAdvanced, nodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });
    const formData = new FormData();
    formData.append("file", new Blob([advancedConfig.config], { type: "text/plain" }));
    return (
        ajaxRequest({
            url: normalizeUrl(`${host}:${port}/config`),
            method: "PUT",
            data: formData,
            contentType: "",
            onSuccess: () => {
                dispatch(actionsCreators.patchAdvanced({ advancedConfig, nodeId }));
            },
        })
    );
};

export const activateConfiguration = (nodeId: string) => (_dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });
    return (
        ajaxRequest({
            url: normalizeUrl(`${host}:${port}/config/activate`),
            method: "POST",
            showAjaxLoading: true,
            timeout: 15 * 1000,
        })
    );
};

// When saving or activating a configuration, the status returned by the Smart Vision is not immediately the expected one, so this utility function locks the page
// until the expected status has been retrieved or until the configured timeout delay expires.
export const waitForStatusChange = (nodeId: string, expectedStatus: IZoneConfigurationActivationStatus["status"], timeoutMs = 20000) => async (dispatch: IStoreDispatch) => {
    const { showAjaxLoading, hideAjaxLoading } = AjaxLoadingModule.getActions(dispatch);
    const id = `waitForStatusChange_${nodeId}_${expectedStatus}`;

    const start = Date.now();

    const timeout = new Promise((resolve) => {
        const resolver = () => dispatch(getConfigStatus(nodeId)).promise.then(({ status }) => {
            if (status == expectedStatus) resolve(false);
            else if (Date.now() - start > timeoutMs) resolve(true);
            else setTimeout(resolver, 1000);
        });

        resolver();
    });

    showAjaxLoading({ id });

    const hasTimedOut = await timeout;
    hideAjaxLoading(id);
    if (hasTimedOut) ModalModule.alert({ title: t("smartVision.waitingForStatus.error.title"), description: t("smartVision.waitingForStatus.error.description") });
};

export const getConfigStatus = (nodeId: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const { host, port } = getSmartVisionZoneAddress({ state: getState(), nodeId });

    return (
        ajaxRequest({
            url: normalizeUrl(`${host}:${port}/config/status`),
            method: "GET",
            timeout: 15 * 1000,
            onSuccess: (status: IZoneConfigurationActivationStatus) => {
                dispatch(actionsCreators.patchStatus({ status, nodeId }));
                return status;
            },
        })
    );
};

const convertFromServer = (serverZoneConfiguration: IZoneConfigurationServer, nodeId: string): IZoneConfiguration => ({
    ...serverZoneConfiguration,
    nodeId,
    fields: _.chain(serverZoneConfiguration.fields)
        .map((field) => {
            if (!_.isEmpty(field.choices)) {
                return ({
                    ...field,
                    type: "choice",
                    value: _.includes(field.choices, field.value) ? field.value : null,
                });
            }
            if (isOtherField(field)) {
                return ({
                    ...field,
                    type: "other",
                    value: JSON.stringify(field.value), // we stringify the value to be able to use it in the form
                });
            }
            return field;
        })
        .keyBy(({ id }) => id)
        .value() as IFields,
});

const convertToServer = (zoneConfiguration: IZoneConfiguration): IZoneConfigurationFields => (
    _.mapValues(zoneConfiguration.fields, (field) => isOtherField(field)
        ? JSON.parse(field.value)
        : field.type == "bool"
            ? field.value
                ? 1
                : 0
            : field.value)
);