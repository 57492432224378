import { IQueryResults } from "js/common";
import { IMachineCodecNode, INode, IScannerCodecNode } from "js/node/model";

interface IKpiQueryRecipesNodeAbstract {
    id: INode["id"];
    templateName: INode["templateName"];
}

export interface IKpiQueryRecipesNodeScanner extends IKpiQueryRecipesNodeAbstract {
    templateName: "scannerCodec";
    machine: IScannerCodecNode["machine"];
    scannerIndex: IScannerCodecNode["scannerIndex"];
}
export interface IKpiQueryRecipesNodeMachine extends IKpiQueryRecipesNodeAbstract {
    templateName: "machineCodec";
    machine: IMachineCodecNode["machine"];
}

export interface IKpiQueryRecipesNodeOthers extends IKpiQueryRecipesNodeAbstract {
    templateName: Exclude<INode["templateName"], "scannerCodec" | "machineCodec">;
}

export const convertNodeToKpiQueryRecipesNode = (node: INode): IKpiQueryRecipesNode => {
    if (node.templateName == "scannerCodec") {
        return {
            id: node.id,
            templateName: node.templateName,
            machine: node.machine,
            scannerIndex: node.scannerIndex,
        };
    } else if (node.templateName == "machineCodec") {
        return {
            id: node.id,
            templateName: node.templateName,
            machine: node.machine,
        };
    } else {
        return {
            id: node.id,
            templateName: node.templateName,
        };
    }
};

export type IKpiQueryRecipesNode = IKpiQueryRecipesNodeScanner | IKpiQueryRecipesNodeMachine | IKpiQueryRecipesNodeOthers; // Mettre les autres types eventuellement ici
export interface IKpiQueryRecipesContext {
    id: string;
    type: "context";
    key: string;
}

export interface IKpiQueryRecipes {
    contexts: IKpiQueryRecipesContext[];
    nodes: IKpiQueryRecipesNode[];
    kpis: { type: "kpiDefinition" | "kpiGroup"; patternKey: string; definitionId: string | null; }[];
}

export interface IKpiQuery {
    nodeId: string | null;
    contextId: string | null;
    patternKey: string | null;
    definitionId: string | null;
}

export interface IKpiQueryResultsResponse {
    results: IQueryResults[];
    ajaxError: boolean;
    errorMessage: string;
    loading: boolean;
}