import { t } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { FormulaType, IKpiPatternEditorFormulaErrors } from "./model";

declare global {
    interface IStoreState {
        kpiPatternEditorFormulaErrors: IKpiPatternEditorFormulaErrors;
    }
}

const initialState = { [FormulaType.QUERY]: { local: [], remote: [] }, [FormulaType.FILTER]: { local: [], remote: [] } } as IKpiPatternEditorFormulaErrors;

const kpiPatternEditorFormulaErrors = createSlice({
    name: "kpiPatternEditorFormulaErrors",
    initialState,
    reducers: {
        setLocal: (state, { payload }: PayloadAction<{ type: FormulaType; errors: string[]; }>) => {
            state[payload.type].local = payload.errors;
        },
        setRemote: (state, { payload }: PayloadAction<{ type: FormulaType; errors: string[]; }>) => {
            state[payload.type].remote = _.map(payload.errors, (error) => t(error));
        },
        deleteLocal: (state, { payload }: PayloadAction<FormulaType>) => {
            state[payload].local = [];
        },
        deleteRemote: (state, { payload }: PayloadAction<FormulaType>) => {
            state[payload].remote = [];
        },
        deleteAll: () => initialState,
    },
});

export const actionsCreators = {
    ...kpiPatternEditorFormulaErrors.actions,
};

export default {
    [kpiPatternEditorFormulaErrors.name]: kpiPatternEditorFormulaErrors.reducer,
};
