import { store } from "@comact/crc";
import { selectors as reportsSelectors } from "js/reports/configurations";
import { FileType } from "js/utils";
import _ from "lodash";
import { IMailReportJob, IPrintReportJob, ReportJobType } from "./model";

export const buildMockReportJobs = (): (IPrintReportJob | IMailReportJob)[] => ([
    {
        id: "1",
        delay: 0,
        type: ReportJobType.PRINT_JOB_TYPE,
        jobTitle: "End of shift #1",
        reportIDs: _.sampleSize(reportsSelectors.getEnabledConfigurations(store.getState()), 3).map(({ id }) => id),
        fileType: FileType.pdf,
        useAsDefault: true,
    },
    {
        id: "2",
        delay: 0,
        type: ReportJobType.MAIL_JOB_TYPE,
        jobTitle: "End of shift #2",
        reportIDs: _.sampleSize(reportsSelectors.getEnabledConfigurations(store.getState()), 3).map((report) => report.id),
        usersIDs: ["1", "2", "3"],
        subject: "Production reports for $date-short $shift-name",
        message: "Here are the reports, have a good day",
        fileNameTemplate: "$machine-name $report-name $date-medium $shift-name",
        fileType: FileType.pdf,
        useAsDefault: true,
    },
]);