import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILabelGroups } from ".";

declare global {
    interface IStoreState {
        labelGroups: ILabelGroups;
    }
}

const useStats = "labelGroups";

const labelGroups = createSlice({
    name: "labelGroups",
    initialState: null as ILabelGroups,
    reducers: {
        set: (state, { payload }: PayloadAction<ILabelGroups>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        patch: (state, { payload }: PayloadAction<ILabelGroups>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...labelGroups.actions,
};

export default {
    [labelGroups.name]: labelGroups.reducer,
};