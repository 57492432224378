import { NodesModule } from "../../node";
import { getNodeMachineSamplesByType } from "../../samples/selectors";
import { isReportContextPeriod, isReportContextSample } from "./model";

export const getReportCurrentPageId = (state: IStoreState) => {
    const node = NodesModule.selectors.getNodeById(state, state.currentNodeId);
    if (!node) return null;
    return `pageReport_${node.id}`;
};

export const getUserReportContext = (state: IStoreState) => {
    const { selectedReportConfigurationId, reportContextData, selectedReportContextType: type } = state.userPrefs;
    const contextData = reportContextData[type];

    // the samples are different for each machine, we need to reset the sampleId if the machine has changed
    if (isReportContextSample(contextData) && (!selectedReportConfigurationId || !getNodeMachineSamplesByType(state, type)[contextData.sampleId])) {
        return { ...contextData, sampleId: null };
    }
    return contextData;
};

export const isPeriodTooLong = (state: IStoreState) => {
    const context = getUserReportContext(state);
    return isReportContextPeriod(context) && context.tooLong;
};

export const getReportBrowserConfiguration = ({ reportBrowserConfiguration }: IStoreState) => reportBrowserConfiguration;