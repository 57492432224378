import { Kit } from "@comact/crc";
import tinycolor from "tinycolor2";

export const mechanicColors: { [key in IMechanic["type"]]: string } = {
    canter: Kit.CSS.colors.blueVibrant, // 'rgb(0, 0, 255)',
    saw: Kit.CSS.colors.red, // 'rgb(255, 0, 0)',
    refSaw: tinycolor(Kit.CSS.colors.green).saturate(20).toRgbString(), // 'rgb(0, 255, 0)',
    edgingTool: Kit.CSS.colors.yellow, // 'rgb(255, 255, 0)',
    edgingToolSecondSet: tinycolor(Kit.CSS.colors.green).desaturate(20).toRgbString(), // 'rgb(34, 139, 34)',
};

export const UNKNOWN_COLOR = "rgb(192, 192, 192)";

export type ITimber = IStem | ILog | ICant | ILumber | IBoard | IProduct;

export enum TimberType {
    STEM = "stem",
    LOG = "log",
    CANT = "cant",
    LUMBER = "lumber",
    BOARD = "board",
    PRODUCT = "product"
}

export type ITimberScanner = {
    scannerId: string;
    solutionId: number | string;
    configId: string;
    timber: ITimber;
};

export type ITimberByScanners = { [scannerId: string]: ITimberScanner; };

interface ITimberAbstract {
    type: TimberType;
    shape: IShape;
    mechanics?: IMechanic[];
}
interface IStem extends ITimberAbstract {
    type: TimberType.STEM;
    logs: ILog[];
}

interface ILog extends ITimberAbstract {
    type: TimberType.LOG;
    angle: number;
    deltaAngle: number;

    boards?: IBoard[];
    cants?: ICant[];
    lumbers?: ILumber[];
}

interface ICant extends ITimberAbstract {
    type: TimberType.CANT;
    boards?: IBoard[];
    lumbers?: ILumber[];
}

interface ILumber extends ITimberAbstract {
    type: TimberType.LUMBER;
    boards?: IBoard[];
}

interface IBoard extends ITimberAbstract {
    type: TimberType.BOARD;
    products?: IProduct[];
}

interface IProduct extends ITimberAbstract {
    type: TimberType.PRODUCT;
    productId: number;
}

interface IShape {
    deltaX: number;
    deltaY: number;
    deltaZ: number;
    slices: ISlice[];
    precision: number;
}

interface IMechanic {
    type: "canter" | "saw" | "refSaw" | "edgingTool" | "edgingToolSecondSet";
    slices: ISlice[];
}

export interface ISlice {
    x: number[];
    y: number[];
    z: number;
    centerX: number;
    centerY: number;
    inflexionPoints: number[];
    phantom: boolean;
}