import { UserModule, ajaxRequest, getAction, getThrottleAjax } from "@comact/crc/";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../../constants";
import { fetchUnitDefinitionTranslationsThrottled } from "../../mUnits/requests";
import { getAllContextDefinitions } from "../contextDefinitions/requests";
import { getKpiPatternsThrottled } from "../kpiPatterns/requests";
import { IKpiAlert } from "./model";
import { actionsCreators } from "./slices";

export const getKpiAlerts = getThrottleAjax((millId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().kpiAlerts ? _.values(getState().kpiAlerts) : import("./mocks").then((m) => m.getKpiAlerts()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-alerts`,
        onSuccess: (kpiAlerts: IKpiAlert[]) => (
            Promise.all([
                dispatch(UserModule.requests.getOrganizationPartialUsers()).promise,
                dispatch(getKpiPatternsThrottled(millId)).promise,
                dispatch(getAllContextDefinitions(millId)).promise,
                dispatch(fetchUnitDefinitionTranslationsThrottled()).promise,
            ]).then(() => {
                dispatch(actionsCreators.set(_.keyBy(kpiAlerts, (alert) => alert.id)));
            })
        ),
    })
), 10 * 1000);

export const saveKpiAlert = getAction((alert: IKpiAlert) => (dispatch) => {
    const isNew = !alert.id;
    return (
        ajaxRequest({
            serverLessResolve: () => ({ ...alert, id: isNew ? _.uniqueId() : alert.id }),
            method: isNew ? "POST" : "PUT",
            url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-alerts${isNew ? "" : "/" + alert.id}`,
            data: alert,
            onSuccess: ((kpiAlert: IKpiAlert) => {
                dispatch(actionsCreators.patch([kpiAlert]));
            }),
        }).promise
    );
});

export const deleteKpiAlert = getAction((alertId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => null,
        method: "DELETE",
        url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-alerts/${alertId}`,
        onSuccess: (() => {
            dispatch(actionsCreators.delete([alertId]));
        }),
    }).promise
));