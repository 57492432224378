import { FileType } from "js/utils";
import { ReportJobType } from "../model";
import { actionsCreators } from "./slices";

// Action creators
export const editJobCreators = {
    selectType: (type: ReportJobType) => actionsCreators.selectType(type), // Temporary fix: fixes the type issue in ReportJobEdit ->  const InputType
    changeJobTitle: (jobTitle: string) => actionsCreators.patch({ jobTitle }),
    changeFileType: (fileType: FileType) => actionsCreators.patch({ fileType }),
    changeUseAsDefault: (useAsDefault: boolean) => actionsCreators.patch({ useAsDefault }),
};

export const editPrintJobCreators = editJobCreators; // Pour l'instant

export const editMailJobCreators = {
    ...editJobCreators,
    changeUsers: (usersIDs: string[]) => actionsCreators.patch({ usersIDs }),
    changeSubject: (subject: string) => actionsCreators.patch({ subject }),
    changeMessage: (message: string) => actionsCreators.patch({ message }),
    changeFileNameTemplate: (fileNameTemplate: string) => actionsCreators.patch({ fileNameTemplate }),
};

export type IReportJobCreators = typeof editJobCreators;
export type IPrintReportJobCreators = typeof editPrintJobCreators;
export type IMailReportJobCreators = typeof editMailJobCreators;