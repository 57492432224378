import { Cache } from "@comact/crc";
import _ from "lodash";
import { createNewDashboard, IDashboard } from "./model";

export const getDashboards = (millNodeId: IDashboard["millNodeId"]) => (
    Cache.remember("allDashboards_", () => (
        _.keyBy(
            _.times(5, (index) => createNewDashboard({
                id: "dashboard_" + (index + 1),
                title: { values: { en: "dashboard_" + (index + 1) } },
                millNodeId,
            }))
            , ({ id }) => id
        )
    ))
);