import { ILocalizedString } from "@comact/crc";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ILabelsGroupServer } from "../labelGroups";

export const DEFAULT_NODE_ID = "DEFAULT";
export const NO_GROUP = "0";

export const LABELS_TYPE_NAMES = {
    0: "downgrade" as ILabelTypes,
    1: "downtime" as ILabelTypes,
    2: "bin" as ILabelTypes,
    3: "correction-error" as ILabelTypes,
    4: "species" as ILabelTypes,
    5: "color-mark" as ILabelTypes,
};

export type ILabelTypesServer = 0 | 1 | 2 | 3 | 4 | 5;

export type ILabelTypes = "downgrade" | "downtime" | "bin" | "correction-error" | "species" | "color-mark";

export type IOriginTypes = "default-edit" | "default-inherited" | "override" | "custom";

export const LABELS_TYPE: Record<ILabelTypes, ILabelTypesServer> = {
    downgrade: 0,
    downtime: 1,
    bin: 2,
    "correction-error": 3,
    species: 4,
    "color-mark": 5,
};

export interface ILabel {
    readonly id: string;
    nodeId: string;
    groupId: string;
    code: number;
    name: ILocalizedString;
    type: ILabelTypes;
    readonly origin?: IOriginTypes; // used in front-end only to display correctly labels
    readonly initialCode?: number; // used in front-end only to order by code but not change order when editing
}

export interface ILabelServer extends Omit<ILabel, | "groupId" | "type"> {
    groupId: number;
    type: ILabelTypesServer;
}

export type ILabels = { [id: string]: ILabel; };

export interface ILabelExportationServer {
    machines: IMachineExportation[];
    groups: ILabelsGroupServer[];
    labels: ILabelServer[];
}

export interface ILabelExportation {
    machines: IMachineExportation[];
    groups: ILabelsGroupServer[];
    labels: ILabels;
}

export interface IMachineExportation {
    nodeId: string;
    name: string;
    model: string;
}

export const convertLabelsToServer = (labels: ILabel[]): ILabelServer[] => (
    _.map(labels, (label) => ({ ...label, groupId: parseInt(label.groupId, 10), type: LABELS_TYPE[label.type] }))
);

export const convertLabelsFromServer = (labels: ILabelServer[]): ILabels => {
    const labelsWithKey: ILabel[] = _.map(labels, (label) => ({
        ...label,
        nodeId: label.nodeId,
        groupId: label.groupId.toString(),
        type: LABELS_TYPE_NAMES[label.type],
    }));
    return _.keyBy(labelsWithKey, ({ id }) => id);
};

export const createNewLabel = (label: Partial<ILabel> & Pick<ILabel, "type">): ILabel => {
    const randomId = uuidv4(); // only for frontend, recreated in backend
    return ({
        id: "new_" + randomId,
        code: null,
        groupId: "0",
        nodeId: DEFAULT_NODE_ID,
        name: {
            values: {
                fr: "",
                en: "",
            },
        },
        ...label,
    });
};
