import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../constants";
import { NodesModule } from "../node";
import * as mocks from "./mocks";
import { IShift, IShiftDefinition, IShiftDefinitionsNotifications } from "./model";
import * as selectors from "./selectors";
import { actionsCreators } from "./slices";

/**
 * Check if a shift definition modification date has changed.
 * This is not an action creator, only an api call.
 */
export const isShiftDefinitionOutdated = getThrottleAjax((shifDefinitiontId: string) => (_dispatch, getState) => {
    const shiftDefinitionToCompare: IShiftDefinition = selectors.getShiftDefinitionById(getState(), shifDefinitiontId);

    const ajax = ajaxRequest({
        serverLessResolve: () => Promise.resolve(shiftDefinitionToCompare),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions/${shiftDefinitionToCompare.id}`,
    });

    const promise = new Promise<boolean>((resolve, reject) => {
        ajax.promise
            .then((newShiftDefinition: IShift) => resolve(shiftDefinitionToCompare.modificationStamp != newShiftDefinition.modificationStamp))
            .catch(() => reject(false));
    });
    return { ...ajax, promise };
}, 5000, false);

/**
 * Update all shifts definitions
 */
export const getShiftDefinitions = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            const { shiftDefinitions } = getState();
            if (!_.isEmpty(shiftDefinitions)) return Promise.resolve(shiftDefinitions);
            return Promise.resolve(mocks.getShiftDefinitions());
        },
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions`,
        onSuccess: (shiftDefinitions: IShiftDefinition[]) => {
            dispatch(actionsCreators.setDefinitions(_.keyBy(shiftDefinitions, (shiftDefinition) => shiftDefinition.id)));
        },
    })
), 10 * 1000);

/**
 * Get one shift definition by it's id
 */
export const getShiftDefinitionById = getThrottleAjax((id: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            const shiftDefinition = _.find(getState().shiftDefinitions, (s) => s.id == id);
            if (shiftDefinition) return Promise.resolve(shiftDefinition);
            return Promise.resolve(_.find(mocks.getShiftDefinitions(), (s) => s.id == id));
        },
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions/${id}`,
        onSuccess: ((shiftDefinition: IShiftDefinition) => {
            dispatch(actionsCreators.patchDefinitions([shiftDefinition]));
        }),
    })
), 10 * 1000);

/**
 * Patch one shift definition by it's id
 */
export const editShiftDefinition = getAction((shiftDefinition: IShiftDefinition) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => {
            shiftDefinition = { ...shiftDefinition, modificationStamp: Date.now(), shiftTemplate: { ...shiftDefinition.shiftTemplate, modificationStamp: Date.now() } };
            return Promise.resolve(shiftDefinition);
        },
        method: "PATCH",
        data: shiftDefinition,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions/${shiftDefinition.id}`,
        showAjaxLoading: true,
        onSuccess: ((editedShiftDefinition: IShiftDefinition) => {
            dispatch(actionsCreators.patchDefinitions([editedShiftDefinition]));
            dispatch(getShiftsByCurrentMonth());
        }),
    }).promise
));

/**
 * Create one new shift definition.
 */
export const createShiftDefinition = getAction((shiftDefinition: IShiftDefinition) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => Promise.resolve(shiftDefinition), // just return the new shift definition
        method: "POST",
        data: shiftDefinition,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions`,
        showAjaxLoading: true,
        onSuccess: ((newShiftDefinition: IShiftDefinition) => {
            dispatch(actionsCreators.patchDefinitions([newShiftDefinition]));
        }),
    }).promise
));

/**
 * Get one shift by it's id
 */
export const getShiftById = getThrottleAjax((id: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            const shift = getState().shifts[id];
            if (!shift) {
                console.warn(`getShiftById() can't find the shift by the id: "${id}", we will generate a random shift`);
                return Promise.resolve(mocks.getShiftById(id));
            }
            return Promise.resolve(shift);
        },
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${id}`,
        onSuccess: ((shift: IShift) => { dispatch(actionsCreators.patch([shift])); }),
    })
), 10 * 1000);

/**
 * Get the current shift
 */
export const getShiftCurrent = getThrottleAjax(() => (dispatch) => (
    process.env.EXEC_MODE != "icp" && // not for icp
    ajaxRequest({
        serverLessResolve: () => Promise.resolve(mocks.getCurrentShift()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/current`,
        onSuccess: (shift: IShift) => {
            if (!shift) return;
            dispatch(actionsCreators.patch([shift]));
            dispatch(actionsCreators.setCurrent(shift.id));
        },
    })
), 10 * 1000);

/**
 * Get the last shift
 */
export const getShiftLast = getThrottleAjax(() => (dispatch) => (
    process.env.EXEC_MODE != "icp" && // not for icp
    ajaxRequest({
        serverLessResolve: () => Promise.resolve(mocks.getLastShift()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/last`,
        onSuccess: (shift: IShift) => {
            if (!shift) return;
            dispatch(actionsCreators.patch([shift]));
            dispatch(actionsCreators.setLast(shift.id));
        },
    })
), 10 * 1000);

/**
 * Check if a shift modification date has changed.
 * This is not an action creator, only an api call.
 */
export const isShiftOutdated = getThrottleAjax((shiftId: string) => (_dispatch, getState) => {
    const shiftToCompare: IShift = selectors.getShiftById(getState(), shiftId);

    const ajax = ajaxRequest({
        serverLessResolve: () => Promise.resolve(shiftToCompare),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${shiftToCompare.id}`,
    });

    const promise = new Promise<boolean>((resolve, reject) => {
        ajax.promise
            .then((newShift: IShift) => resolve(shiftToCompare.modificationStamp != newShift.modificationStamp))
            .catch(() => reject(false));
    });
    return { ...ajax, promise };
}, 5000, false);

/**
 * Get all shifts definitions between the startTime and endTime
 */
export const getShiftsByTimeRange = (startTime: Date, endTime: Date) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: () => {
            const { shiftDefinitions } = getState();
            return Promise.resolve(mocks.getShiftsByTimeRange(startTime, endTime, _.values(shiftDefinitions)));
        },
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/?begin=${startTime.getTime()}&end=${endTime.getTime()}`,
        onSuccess: (shifts: IShift[]) => {
            dispatch(actionsCreators.set({ startTime, endTime, shifts }));
        },
    })
);

/**
 * Get all shifts definitions for the current month, with padding
 */
export const getShiftsByCurrentMonth = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const d = new Date(getState().shiftsStartDate);
    const startTime = new Date(d.getFullYear(), d.getMonth() - 1); // get previous month
    const endTime = new Date(d.getFullYear(), d.getMonth() + 2); // to next month
    return dispatch(getShiftsByTimeRange(startTime, endTime));
};

/**
 * Create one shift.
 */
export const createShift = getAction((shift: IShift) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => ({ ...shift, id: Date.now().toString() }),
        method: "POST",
        data: shift,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts`,
        showAjaxLoading: true,
        onSuccess: ((createdShift: IShift) => {
            dispatch(actionsCreators.patch([createdShift]));
        }),
    }).promise
));

/**
 * Edit one shift.
 */
export const editShift = getAction((shift: IShift) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => Promise.resolve(shift),
        method: "PATCH",
        data: shift,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${shift.id}`,
        showAjaxLoading: true,
        onSuccess: ((editedShift: IShift) => {
            if (shift.id != editedShift.id) {
                // When editing a shiftDefinition as a shift, the server create a real shift and the id change, so we need to delete the old shift.
                dispatch(actionsCreators.delete([editedShift.id]));
            }
            dispatch(actionsCreators.patch([editedShift]));
        }),
    }).promise
));

/**
 * Delete one shift.
 */
export const deleteShift = getAction((shift: IShift) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => null, // does not work in serverless because generated from shiftDefinitions
        method: "DELETE",
        data: shift,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${shift.id}`,
        showAjaxLoading: true,
        onSuccess: (() => {
            dispatch(actionsCreators.delete([shift.id]));
        }),
    }).promise
));

/**
 * Delete one shift definition
 */
export const deleteShiftDefinition = getAction((shiftDefinition: IShiftDefinition) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => null,
        method: "DELETE",
        data: shiftDefinition,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shift-definitions/${shiftDefinition.id}`,
        showAjaxLoading: true,
        onSuccess: (() => {
            dispatch(actionsCreators.deleteDefinitions([shiftDefinition.id]));
        }),
    }).promise
));

/**
 * Execute end of shift report jobs.
 */
export const simulateEndOfShift = (shift: IShift) => (
    ajaxRequest({
        serverLessResolve: () => { console.info(`Serverless request for simulate end of shift is executed for shift id ${shift.id}`); return Promise.resolve(); },
        method: "POST",
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${shift.id}/simulate-report-job`,
    })
);

/**
 * GET end of shift simulation status.
 */
export const getEndOfShiftStatus = (id: string) => (
    ajaxRequest({
        serverLessResolve: () => { console.info(`Serverless request to get end of shift simulation status for shift id ${id}`); },
        method: "GET",
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/shifts/${id}/status`,
    })
);

/**
 * GET shift definitions by site for Notification Groups shift assignment.
 */
// FIXME: When O8D-123 is done, we will set the shift definitions in the state for only the current site node id.
export const getNotificationsShiftDefinitionsForAllSites = getAction((siteNodeIds: string[]) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<IShiftDefinitionsNotifications>[] = _.map(siteNodeIds, (id) => dispatch(getNotificationsShiftDefinitionsBySite(id)));
        await Promise.all(ajaxPromises)
            .then((shiftDefinitions) => {
                dispatch(actionsCreators.setShiftDefinitionsNotifications(shiftDefinitions.reduce((obj, item) => Object.assign(obj, item), {})));
            });
    }
));

const getNotificationsShiftDefinitionsBySite = (siteNodeId: string) => (_dispatch: IStoreDispatch, getState: () => IStoreState) => (
    ajaxRequest({
        // FIXME: the pickBy will be removed when O8D-123 is done, since there will only be 1 site
        serverLessResolve: () => (
            getState().shiftDefinitionsNotifications
                ? _.pickBy(getState().shiftDefinitionsNotifications, (value) => value.locationName == NodesModule.selectors.getNodeById(getState(), siteNodeId).name)
                : import("./mocks").then((m) => m.getNotificationsShiftDefinitionsBySite(siteNodeId))
        ),
        url: `/api/v1/schedule/shift-definitions/partial/${siteNodeId}`,
        onSuccess: (shiftDefinitionsNotifications: IShiftDefinitionsNotifications) => shiftDefinitionsNotifications,
    }).promise
);