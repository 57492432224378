import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ICustomValidator } from "../../../components/codeEditor";
import { createNew, IMailReportJob, IPrintReportJob, IReportJob, IReportJobEdit, ReportJobType, validateJob } from "../model";

declare global {
    interface IStoreState {
        reportJobEdit: IReportJobEdit;
    }
}

const castJob = (job: IReportJob) => {
    switch (job.type) {
        case ReportJobType.MAIL_JOB_TYPE:
            return job as IMailReportJob;
        case ReportJobType.PRINT_JOB_TYPE:
            return job as IPrintReportJob;
    }
};

const reportJobEdit = createSlice({
    name: "reportJobEdit",
    initialState: null as IReportJobEdit,
    reducers: {
        create: (_state, { payload }: PayloadAction<IReportJob>) => {
            const job = castJob(payload);
            return { job, errors: validateJob(job, null), customValidators: {}, isModified: false };
        },
        delete: () => null, // so that it's empty when we leave edition
        patch: (state, { payload }: PayloadAction<Partial<IReportJob>>) => {
            _.forEach(payload, (value, field) => {
                state.job[field] = value;
            });
            state.errors = validateJob(state.job, state.customValidators);
            state.isModified = true;
        },
        patchValidator: (state, { payload }: PayloadAction<{ field: string; validator: ICustomValidator; }>) => {
            if (state == null) return null;
            const { field, validator } = payload;
            state.customValidators[field] = validator;
            state.errors = validateJob(state.job, state.customValidators);
        },
        selectType: (state, { payload }: PayloadAction<ReportJobType>) => {
            const { job: { type } } = state;
            if (type == payload) return;
            // S'il s'agit d'un changement de type, on doit créer une nouvelle instance qu'on va populer avec les propriétés communes de l'ancienne instance.
            const newJob = createNew(payload);
            // On combine ici le nouvel objet sur lequel on applique les valeurs par défaut des nouveaux champs,
            // puis on ajuste l'objet pour écraser l'ancien type
            state.job = castJob({ ...newJob, ..._.pick(state.job, _.keys(newJob)), type: payload });
            state.errors = validateJob(state.job, state.customValidators);
            state.isModified = true;
        },
    },
});

export const actionsCreators = {
    ...reportJobEdit.actions,
};

export default {
    [reportJobEdit.name]: reportJobEdit.reducer,
};
