import { createModule } from "@comact/crc";
import * as requests from "./requests";
import * as selectors from "./selectors";
import reducers, { actions as actionsCreators } from "./slices";

export * from "./model";

export const TimberViewerModule = createModule({
    actionsCreators, reducers, requests, selectors,
});
