import { getLocalizedText } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { DETECTIONS, IDataflow, IGroupDataflow, IModelVersionsByNames } from "./model";

export const getAllModels = (state: IStoreState) => state.models;

export const getModelById = (state: IStoreState, id: string) => getAllModels(state)?.[id];

export const getModelsByName = (state: IStoreState, name: string) => state.models
    ? _.filter(getAllModels(state), (m) => m.name == name)
    : null;

export const getGroupModelVersions = memoize((state: IStoreState) => (
    _.reduce(getAllModels(state), (acc, model) => {
        if (!acc[model.name]) {
            acc[model.name] = {
                name: model.name,
                detections: [],
                models: { [model.id]: model },
            };
        }
        acc[model.name].models[model.id] = model;
        if (!_.isEmpty(model.detections)) {
            acc[model.name].detections = _.uniq([...acc[model.name].detections, ...model.detections]);
        }

        return acc;
    }, {} as IModelVersionsByNames)
));

export const getGroupModelVersionsByName = (state: IStoreState, name: string) => getGroupModelVersions(state)?.[name];

export const getGroupDataflow = memoize(({ dataflowLive, dataflowSimulation }: IStoreState) => {
    if (!dataflowLive || !dataflowSimulation) return null;
    const datagroup: Record<string, IGroupDataflow> = {};

    const insert = (dataflow: IDataflow, where: "live" | "simulation") => {
        if (!datagroup[dataflow.linkId]) datagroup[dataflow.linkId] = { live: null, simulation: null };
        datagroup[dataflow.linkId][where] = dataflow;
    };
    _.forEach(dataflowLive, (d) => insert(d, "live"));
    _.forEach(dataflowSimulation, (d) => insert(d, "simulation"));
    return _.orderBy(datagroup, (d) => { // order by detections, then recipes and then species
        const dataflow = d.live || d.simulation; // one of the two might be null
        const { detections, recipes, species } = dataflow;
        const detectionsName = _.map(detections, (detectionId) => getLocalizedText(DETECTIONS[detectionId]?.title)).join(", ");
        const recipesName = recipes.join(",");
        const speciesName = species.join(",");
        return `${detectionsName}_${recipesName}_${speciesName}`;
    }, ["asc"]);
});

export const getDataflowById = (state: IStoreState, id: number | string, type: "live" | "simulation") => (
    type == "live"
        ? state.dataflowLive[id]
        : state.dataflowSimulation[id]
);