import { TextBox } from "@comact/crc";
import { Span } from "@comact/crc/modules/kit";
import { IQueryResults } from "js/common";
import { LinearGauge, LinearGaugeSimple } from "js/components/LinearGauges";
import { RadialGauge } from "js/components/RadialGauge";
import { SingleValue } from "js/components/SingleValue";
import { TextBoxWithGraph } from "js/components/TextBoxWithGraph";
import { convertForLinearGauge, convertForTextBox } from "js/kpis/kpiQueries";
import _ from "lodash";
import * as React from "react";
import { IViewType } from "./ViewSelect";

interface IViewSingleRenderer {
    result: IQueryResults;
    viewType: IViewType;
    alternateNullView?: string;
    showTitle?: boolean;
    showUnit?: boolean;
}

export const ViewSingleRenderer = React.memo<IViewSingleRenderer>(({ result, viewType, alternateNullView, showTitle = true, showUnit = true }) => {
    if (!result) return null;
    if (_.isString(alternateNullView) && !_.isFinite(_.last(result.data)?.value)) return <>{alternateNullView}</>;
    switch (viewType) {
        case "Gauge":
            return <RadialGauge results={result} showTitle={showTitle} showUnit={showUnit} />;
        case "LinearGauge":
            return <LinearGauge {...convertForLinearGauge(result)} showTitle={showTitle} showUnit={showUnit} />;
        case "LinearGaugeSimple":
            return <LinearGaugeSimple {...convertForLinearGauge(result)} showTitle={showTitle} showUnit={showUnit} />;
        case "SingleValue":
            return <SingleValue {...convertForLinearGauge(result)} showTitle={showTitle} showUnit={showUnit} />;
        case "SingleValueInline":
            return <SingleValue {...convertForLinearGauge(result)} showTitle={showTitle} showUnit={showUnit} inline />;
        case "TextBox":
            return <TextBox {...convertForTextBox(result)} showLabel={showTitle} showUnit={showUnit} />;
        case "TextBoxInline":
            return <TextBox {...convertForTextBox(result)} showLabel={showTitle} showUnit={showUnit} inline />;
        case "TextBoxWithGraph":
            return <TextBoxWithGraph results={result} showTitle={showTitle} showUnit={showUnit} />;
        default:
            return <Span kColor="error">{viewType}</Span>; // should not happen
    }
});