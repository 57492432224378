// Redux
import { createModule } from "@comact/crc";
import * as mocks from "./mocks";
import * as requests from "./requests";
import * as selectors from "./selectors";
import reducers, { actionsCreators } from "./slices";

// Model (type, interface)
export * from "./model";
export { mocks, requests, selectors };

export const KpiDataIOModule = createModule({
    reducers, actionsCreators, requests, selectors,
});
