import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IReportConfiguration, IReportConfigurations } from "./model";

declare global {
    interface IStoreState {
        reportConfigurations: IReportConfigurations;
    }
}

const useStats = "reportConfigurations";

const reportConfigurations = createSlice({
    name: "reportConfigurations",
    initialState: null as IReportConfigurations,
    reducers: {
        set: (state, { payload }: PayloadAction<IReportConfiguration[]>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        patch: (state, { payload }: PayloadAction<IReportConfiguration[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...reportConfigurations.actions,
};

export default {
    [reportConfigurations.name]: reportConfigurations.reducer,
};