import { ajaxRequest, getAction, getActionAjax, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_DOWNTIMES } from "../constants";
import { IDowntime, IDowntimeComment } from "./model";
import { getAllDowntimeComments } from "./selectors";
import { actionsCreators } from "./slices";

export const getDowntimesInRange = getActionAjax((startDate: number, endDate: number, includeComments: boolean = false) => (dispatch) => {
    // we don’t make the request because that could result in way too many downtimes returned if no startDate is specified
    if (!startDate) throw new Error("Start date is missing to be able to call getDowntimesInRange");

    const start = process.env.EXEC_MODE == "icp" ? new Date(startDate).toISOString() : startDate;
    const end = process.env.EXEC_MODE == "icp" ? new Date(endDate || Date.now()).toISOString() : endDate || Date.now();
    return (
        ajaxRequest({
            serverLessResolve: () => import("./mocks").then((m) => m.getDowntimesInRange(startDate, endDate, includeComments)),
            // icp takes dates in string and iep in number
            url: `${API_PREFIX_DOWNTIMES}?comments=${includeComments}&from=${start}&to=${end}`,
            onSuccess: (downtimes: IDowntime[]) => {
                dispatch(actionsCreators.set(downtimes));
                return downtimes;
            },
        })
    );
});

export const getDowntimeById = getThrottleAjax((id: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().downtimes?.[id] || import("./mocks").then((m) => m.getDowntimeById(id)),
        url: `${API_PREFIX_DOWNTIMES}/${id}`,
        onSuccess: (downtime: IDowntime) => {
            dispatch(actionsCreators.patch([downtime]));
        },
    })
), 10 * 1000);

export const getNodeIdsWithDowntimeSupport = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().nodeIdsWithDowntimeSupport || import("./mocks").then((m) => m.getNodeIdsWithDowntimeSupport()),
        url: `${API_PREFIX_DOWNTIMES}/nodes`,
        onSuccess: (nodeIds: string[]) => {
            dispatch(actionsCreators.setNodeIdsWithDowntimeSupport(nodeIds));
        },
    })
), 10 * 1000);

const editDowntimes = getActionAjax((downtimes: IDowntime[]) => (dispatch) => (
    ajaxRequest({
        method: "PUT",
        data: downtimes,
        serverLessResolve: () => _.map(downtimes, (downtime) => ({ ...downtime, modificationDate: Date.now() })),
        url: `${API_PREFIX_DOWNTIMES}?batch=true`,
        showAjaxLoading: true,
        onSuccess: ((downtimesServer: IDowntime[]) => {
            dispatch(actionsCreators.patch(downtimesServer));
        }),
    })
));

export const editDowntime = getActionAjax((downtime: IDowntime) => (dispatch) => {
    const isNew = !downtime.id;
    return ajaxRequest({
        serverLessResolve: () => ({ ...downtime, id: isNew ? _.uniqueId() : downtime.id, modificationDate: Date.now() }),
        method: isNew ? "POST" : "PUT",
        data: downtime,
        showAjaxLoading: true,
        serverLessTimeout: 1000,
        url: isNew ? API_PREFIX_DOWNTIMES : `${API_PREFIX_DOWNTIMES}/${downtime.id}`,
        onSuccess: ((downtimeServer: IDowntime) => {
            dispatch(actionsCreators.patch([downtimeServer]));
            return downtimeServer;
        }),
    });
});

export const getDowntimeCommentsByCode = getThrottleAjax((code: number) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getAllDowntimeComments(getState())
            ? _.filter(getAllDowntimeComments(getState()), ({ downtimeCode }) => downtimeCode == code)
            : import("./mocks").then((m) => m.getDowntimeCommentsByCode(code)),
        url: `${API_PREFIX_DOWNTIMES}/comments/${code}`,
        onSuccess: (downtimeComments: IDowntimeComment[]) => {
            dispatch(actionsCreators.patchDowntimeComments(downtimeComments));
        },
    })
), 10 * 1000);

export const saveCommentAndDowntimes = getAction((downtimeComment: IDowntimeComment, downtimes: IDowntime[]) => (
    async (dispatch) => {
        if (_.startsWith(downtimeComment?.id, "new_")) {
            const createdComment = await dispatch(addDowntimeComment(downtimeComment)).promise;
            await dispatch(editDowntimes(_.map(downtimes, (downtime) => ({ ...downtime, commentId: createdComment.id }))));
        } else {
            await dispatch(editDowntimes(downtimes));
        }
    }
));

const addDowntimeComment = getActionAjax((downtimeComment: IDowntimeComment) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => ({ ...downtimeComment, id: _.uniqueId(), modificationDate: Date.now(), creationDate: Date.now() }),
        method: "POST",
        data: { ...downtimeComment, id: null },
        url: `${API_PREFIX_DOWNTIMES}/comments`,
        onSuccess: (async (downtimeCommentServer: IDowntimeComment) => {
            dispatch(actionsCreators.patchDowntimeComments([downtimeCommentServer]));
            return downtimeCommentServer;
        }),
    })
));