import { ajaxRequest, getAction, getThrottleAction } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../constants";
import { ITagStrategy, ITagStrategyServer, convertFromServer, convertToServer } from "./model";
import { actions } from "./slices";

export const getAllTagStrategies = getThrottleAction(() => async (dispatch, getState) => (
    await ajaxRequest({
        serverLessResolve: () => _.isEmpty(getState().tagStrategies)
            ? import("./mocks").then((m) => m.getAllTagStrategies())
            : getState().tagStrategies,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/tag-strategies`,
        onSuccess: ((data: ITagStrategyServer[]) => {
            dispatch(actions.set(_.keyBy(data.map(convertFromServer), ({ id }) => id)));
        }),
    }).promise
), 10 * 1000);

export const removeTagStrategy = getAction((id: string) => async (dispatch) => (
    await ajaxRequest({
        serverLessResolve: () => null,
        method: "DELETE",
        url: `${API_PREFIX_PRODUCTION_MANAGER}/tag-strategies/${id}`,
        onSuccess: (() => {
            dispatch(actions.delete([id]));
        }),
    }).promise
));

export const editTagStrategy = getAction((tagStrategy: ITagStrategy) => async (dispatch) => {
    const { id, ...data } = convertToServer(tagStrategy);
    const isNew = id == "new";
    return await ajaxRequest({
        serverLessResolve: () => ({ ...data, id: isNew ? (new Date()).toISOString() : id }),
        method: isNew ? "POST" : "PUT",
        data: isNew ? data : { id, ...data }, // remove id when new
        showAjaxLoading: true,
        serverLessTimeout: 1000,
        url: isNew
            ? `${API_PREFIX_PRODUCTION_MANAGER}/tag-strategies`
            : `${API_PREFIX_PRODUCTION_MANAGER}/tag-strategies/${id}`,
        onSuccess: ((tagStrategyServer: ITagStrategyServer) => {
            dispatch(actions.patch([convertFromServer(tagStrategyServer)]));
        }),
    }).promise;
});