import _ from "lodash";

export interface IKpiDataIO {
    patternKey: string;
    definitionId: string;
    contextId: string;
    nodeId: string;
    machineNodeId: string;
    factor: number;
    index: number;
    readonly id: string;
}

export type IKpiDataIOs = { [id: string]: IKpiDataIO; };

export const createNewKpiDataIO = (partialData: Partial<IKpiDataIO> = {}): IKpiDataIO => ({
    id: _.uniqueId("new_"),
    patternKey: "",
    definitionId: "",
    contextId: "",
    nodeId: "",
    factor: 1,
    index: 1,
    machineNodeId: "",
    ...partialData,
});