import { ajaxRequest, getActionAjax } from "@comact/crc";
import { API_PREFIX_FEATURE_FLAGS } from "js/constants";
import { IFeatureFlag } from "./model";
import { actionsCreators } from "./slices";

export const getFeatureFlags = getActionAjax((nodeId: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getFeatureFlags()),
        url: `${API_PREFIX_FEATURE_FLAGS}/${nodeId}`,
        onSuccess: (flags: IFeatureFlag[]) => {
            dispatch(actionsCreators.set(flags));
        },
    })
));