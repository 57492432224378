import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { actionsCreators as slicesActionsCreators } from "../node/slices";
import { IDashboard, IDashboards, widgetHasKpiQueryRecipes, widgetHasQueryRecipe } from "./model";
declare global {
    interface IStoreState {
        dashboards: IDashboards;
    }
}

/**
 * Get all the node ids used in one system dashboard
 */
const getNodesStats = (systemDashboard: IDashboard) => {
    const nodeIds: string[] = [];
    _.forEach(systemDashboard?.widgetsByBreakpoints, (widgetsByBreakpoint) => {
        _.forEach(widgetsByBreakpoint, (widget) => {
            if (widgetHasKpiQueryRecipes(widget)) {
                _.forEach(widget.props.kpiQueryRecipes.nodes, ({ id }) => nodeIds.push(id));
            } else if (widgetHasQueryRecipe(widget)) {
                nodeIds.push(widget.props.queryRecipe.id);
            }
        });
    });
    return _(nodeIds).uniq().orderBy((nodeId) => nodeId).value();
};

const useStats = "dashboards";

const isEqual = (a: IDashboard, b: IDashboard) => (
    a?.modificationDate == b?.modificationDate &&
    a?.millNodeId == b?.millNodeId &&
    (!a?.isSystem || !b?.isSystem || (_.isEqual(getNodesStats(a), getNodesStats(b)))) // If it's a system dashboard we need to check if any nodes has changed (ICP-783)
);

const dashboards = createSlice({
    name: "dashboards",
    initialState: null as IDashboards,
    reducers: {
        set: (state, { payload }: PayloadAction<IDashboards>) => (
            Utils.slices.set({ state, nextState: payload, isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IDashboard[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        delete: (state, { payload }: PayloadAction<IDashboard["id"][]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
        deleteAll: (state) => (
            Utils.slices.deleteAll({ state, useStats })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(slicesActionsCreators.setCurrentMillNodeId.type, () => null); // when changing mill, we remove the dashboards
    },
});

export const actionsCreators = {
    ...dashboards.actions,
};

export default {
    [dashboards.name]: dashboards.reducer,
};