import { FileType } from "js/utils";
import _ from "lodash";
import { ISample, sampleTypes } from "../../samples";

export type IReportContext = IReportContextShift | IReportContextBatch | IReportContextPeriod | IReportContextSample;
export type IReportContextType = "SHIFT" | "BATCH" | "PERIOD" | "SAMPLING" | "SIMULATION";

interface IReportContextAbstact {
    type: IReportContextType;
    reportConfigurationId?: string;
    fileType?: FileType;
}

export interface IReportContextShift extends IReportContextAbstact {
    type: "SHIFT";
    shiftId: string;
}

export interface IReportContextBatch extends IReportContextAbstact {
    type: "BATCH";
    batchIds: string[];
    begin: number;
    end: number;
}

export interface IReportContextPeriod extends IReportContextAbstact {
    type: "PERIOD";
    begin: number;
    end: number;
    tooLong?: boolean;
}

export interface IReportContextSample extends IReportContextAbstact {
    type: ISample["type"];
    sampleId: number;
}

export const isReportContextSample = (context: IReportContext): context is IReportContextSample => _.includes(sampleTypes, context.type);

export const isReportContextPeriod = (context: IReportContext): context is IReportContextPeriod => context.type == "PERIOD";

export interface IFileDownloadInfo {
    fileName: string;
    fileType: FileType;
}

export interface IReportBrowserConfiguration {
    status: IReportExecutionStatus;
    token: number;
    url: string;
    downloadInfo?: IFileDownloadInfo;
}

export type IReportExecutionStatus = "NOT_READY" | "READY" | "WAITING" | "DONE" | "ERROR" | "CANCELED";
export const isStatusReady = (status: IReportExecutionStatus) => !["NOT_READY", "WAITING"].includes(status);

// ajax response data
export type IReportExecutionTokenResponse = number;
export interface IReportExecutionStatusResponse { done: boolean; }

/**
 * Initialize an empty "default" report context for a given type.
 * We can define some what will be the default choice for a person who has
 * never changed any of the context settings.
 */
export const buildDefaultReportContext = (type: IReportContextType): IReportContext => {
    switch (type) {
        case "SHIFT": {
            return {
                type,
                shiftId: null,
            };
        }
        case "BATCH": {
            return {
                type,
                batchIds: null,
                begin: null,
                end: null,
            };
        }
        case "PERIOD": {
            return {
                type,
                begin: new Date().setMilliseconds(0) - 3600 * 1000,
                end: new Date().setMilliseconds(0),
                tooLong: false,
            };
        }
        case "SIMULATION":
        case "SAMPLING": {
            return {
                type,
                sampleId: null,
            };
        }
    }
};

export const autoRefreshId: string = "reports-browser-auto-refresh";