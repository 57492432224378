import { app } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import { IKpiZone } from "js/kpis/kpiObjectives";
import _ from "lodash";
import * as React from "react";
import tinycolor from "tinycolor2";

interface ISingleValueProps {
    title: string;
    value: number;
    min: number;
    max: number;
    unit: string;
    zones: IKpiZone[];
    showTitle?: boolean;
    showUnit?: boolean;
    inline?: boolean;
}

/**
 * Single Value Styled
 */
const SingleValueStyled = styled("div")`

    &.block{
        display: grid;
        border-bottom: 1px solid ${tinycolor(CSS.colors.grey).brighten(5).toRgbString()};
        .dark &{
            border-bottom: 1px solid ${tinycolor(CSS.colors.greyDark).brighten(10).toRgbString()};
        }
        .darker &{
            border-bottom: 1px solid ${tinycolor(CSS.colors.greyDarker).brighten(5).toRgbString()};
        }
    }
    &.inline{
        display: inline-grid;
        max-width: max-content;
        margin: 0 auto;
    }

    grid-template-columns: 1fr max-content;
    height: 100%;
    text-align: left;
    align-items: center;
    > .title{
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: .3em;
        &:empty{
            display: none;
        }
    }
    > .value{
        text-shadow: 1px 1px 3px ${CSS.colors.black};
        text-align: right;
        font-weight: ${CSS.fontWeight.bold};
        font-size: 1.2em;
        > .unit{
            font-weight: ${CSS.fontWeight.thin};
            font-size: .7em;
            font-weight: 300;
            opacity: .7;
            margin-left: .3em;
        }
    }
`;
export const SingleValue = React.memo<ISingleValueProps>(({ title, value, min, max, unit, zones, inline = false, showTitle = true, showUnit = true }) => {
    const CURRENT_COLORS = React.useMemo(() => ({
        empty: tinycolor(CSS.colors.white).toRgbString(),
        normal: tinycolor(CSS.colors.green).toRgbString(),
        warn: tinycolor(CSS.colors.yellow).toRgbString(),
        error: tinycolor(CSS.colors.red).toRgbString(),
    }), []);

    const format = React.useMemo(() => (new Intl.NumberFormat(app.language).format), []);

    const clampedValue = _.clamp(value, min, max);
    const currentZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const color = currentZone ? CURRENT_COLORS[currentZone.value] : CURRENT_COLORS.empty;

    return (
        <SingleValueStyled className={"singleValue " + (inline ? "inline" : "block")}>
            <div className="title">
                {showTitle && title ? title : ""}
            </div>
            <div className="value">
                <span style={{ color }}>{format(value)}</span>
                {showUnit && <span className="unit">{unit}</span>}
            </div>
        </SingleValueStyled>
    );
});