import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IContextDefinition, IContextDefinitions } from "./model";

declare global {
    interface IStoreState {
        contextDefinitions: IContextDefinitions;
    }
}

const useStats = "contextDefinitions";

const isEqual = (a: IContextDefinition, b: IContextDefinition) => (
    a.modificationStamp == b.modificationStamp && a.uniqueReferenceName == b.uniqueReferenceName && a.millNodeId == b.millNodeId
);

const contextDefinitions = createSlice({
    name: "contextDefinitions",
    initialState: null as IContextDefinitions,
    reducers: {
        set: (state, { payload }: PayloadAction<IContextDefinition[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IContextDefinition[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...contextDefinitions.actions,
};

export default {
    [contextDefinitions.name]: contextDefinitions.reducer,
};