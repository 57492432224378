import _ from "lodash";

export interface IDowntime {
    id: string;
    startDate: number;
    endDate: number;
    duration: number;
    code: number;
    closed: boolean;
    commentId: string;
    comment?: IDowntimeComment; // used to avoid fetching all the downtime comments in the downtimes listing page
    readonly lastModificationUserId: string;
    nodeId: string;
    readonly creationDate: number;
    readonly modificationDate: number;
}

export interface IDowntimeComment {
    readonly id: string;
    nodeId: string;
    downtimeCode: number;
    content: string;
    occurences: number;
    readonly creationDate: number;
    readonly modificationDate: number;
}

export type IDowntimes = Record<string, IDowntime>;
export type IDowntimeComments = Record<string, IDowntimeComment>;

export const createNewDowntime = (partialData: Omit<Partial<IDowntime>, "id" | "lastModificationUserId" | "creationDate" | "modificationDate"> = {}): IDowntime => ({
    id: null, // the back will set it
    startDate: null,
    endDate: null,
    duration: 0,
    code: null,
    commentId: null,
    lastModificationUserId: null, // the back will set it
    nodeId: null,
    closed: false,
    creationDate: null, // the back will set it
    modificationDate: null, // the back will set it
    ...partialData,
});

export const createNewDowntimeComment = (partialData: Omit<Partial<IDowntimeComment>, "id" | "modificationDate" | "creationDate"> = {}): IDowntimeComment => ({
    id: _.uniqueId("new_"), // the back will set it, but we use "new_" to easily know whether a comment needs to be created or not
    nodeId: null,
    downtimeCode: null,
    content: "",
    occurences: 0,
    creationDate: null, // the back will set it
    modificationDate: null, // the back will set it
    ...partialData,
});
