import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export interface IInterval {
    readonly id: string;
    minimum: number;
    maximum: number;
    type: number;
    name: ILocalizedString;
}

export interface IIntervalCategory {
    readonly name: string; // Used as uid
    readonly type: number;
    readonly unit: string;
    readonly min: number;
    readonly max: number;
    intervals: IInterval[];
}

export interface IIntervalCategories { [name: string]: IIntervalCategory; }

export const createInterval = (partialData: (Partial<Omit<IInterval, "id">> & Pick<IInterval, "type">)): IInterval => (
    {
        id: _.uniqueId("new_"),
        minimum: 0.0,
        maximum: 0.0,
        name: {
            values: {},
        },
        ...partialData,
    }
);

export const sortIntervals = (intervals: IInterval[]) => _.sortBy(intervals, [({ minimum }: IInterval) => minimum || 0]);