import _ from "lodash";
import moment from "moment";
import { create as createSeeder } from "random-seed";
import { fetchAllNodes } from "../node/mocks";
import { ICategory, ISample } from "./model";

const rand = createSeeder();

export const getSamples = (): ISample[] => {
    rand.seed("getSamples");
    const categories = getCategories();
    return (
        _.times(rand.intBetween(5, 20), (i) => {
            rand.seed("getSamples" + i);
            return {
                id: i.toString(),
                optimizerSampleId: i,
                name: `Sample ${i}`,
                type: "SAMPLING",
                nodeId: fetchAllNodes()[3].id,
                solutionCount: rand.intBetween(0, 100),
                solutionNotProcessedCount: rand.intBetween(0, 1),
                solutionsAllProcessed: rand.intBetween(0, 1) == 1,
                creationDate: rand.intBetween(moment().subtract(10, "days").valueOf(), Date.now()),
                username: i % 2 == 0 ? "Laura B" : "Jacob T",
                modificationStamp: Date.now(),
                categoryId: i % 2 == 0 ? categories[rand.intBetween(0, _.size(categories) - 1)].id : null,
            };
        })
    );
};

export const getCategories = (): ICategory[] => {
    rand.seed("getCategories");
    return (
        _.times(rand.intBetween(2, 3), (i) => {
            rand.seed("getCategories" + i);
            return ({
                id: ((i + 1) * 1000).toString(),
                name: `Category ${i}`,
                parentId: i == 1 ? "0" : null,
                nodeId: fetchAllNodes()[3].id,
                type: "SAMPLING",
            });
        })
    );
};
