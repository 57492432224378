import { store } from "@comact/crc";
import { IReportConfiguration } from "js/reports/configurations";
import _ from "lodash";
import { getNodeMachineSamplesByType } from "../../samples/selectors";
import { IReportContext, IReportContextBatch, IReportContextPeriod, IReportContextSample, IReportContextShift } from "./model";

type ReportConfigurationValidationResult = { ok: boolean; errors: { [key in keyof IReportConfiguration]?: string }; };
type SampleValidationResult = { ok: boolean; errors: { [key in keyof IReportContextSample]?: string }; };
type PeriodValidationResult = { ok: boolean; errors: { [key in keyof IReportContextPeriod]?: string }; };
type ShiftValidationResult = { ok: boolean; errors: { [key in keyof IReportContextShift]?: string }; };
type BatchValidationResult = { ok: boolean; errors: { [key in keyof IReportContextBatch]?: string }; };
type ContextValidationResult = SampleValidationResult | PeriodValidationResult | ShiftValidationResult | BatchValidationResult;

export const validateContext = (context: IReportContext): ContextValidationResult => {
    switch (context.type) {
        case "SAMPLING":
        case "SIMULATION":
            return validateSampleContext(context);
        case "PERIOD":
            return validatePeriodContext(context);
        case "SHIFT":
            return validateShiftContext(context);
        case "BATCH":
            return validateBatchContext(context);
    }
};

export const validateConfiguration = (configuration: IReportConfiguration): ReportConfigurationValidationResult => {
    const result: ReportConfigurationValidationResult = { ok: false, errors: {} };
    if (configuration) {
        if (configuration.templateId == null) {
            result.errors.templateId = "reports.validation.invalidTemplate";
        }
        if (!_.isArray(configuration.nodeIds) || _.isEmpty(configuration.nodeIds)) {
            result.errors.nodeIds = "reports.validation.invalidNode";
        }
        result.ok = _.isEmpty(result.errors);
    }
    return result;
};

export const validateSampleContext = (context: IReportContextSample): SampleValidationResult => {
    const result: SampleValidationResult = { ok: false, errors: {} };
    const machineSamples = getNodeMachineSamplesByType(store.getState(), context.type);
    if (_.isEmpty(machineSamples) || !machineSamples[context.sampleId] || context.sampleId == null) {
        result.errors.sampleId = `reports.validation.invalid${context.type.toLowerCase()}`;
    }
    return { ...result, ok: _.isEmpty(result.errors) };
};

export const validatePeriodContext = (context: IReportContextPeriod): PeriodValidationResult => {
    const result: PeriodValidationResult = { ok: false, errors: {} };
    if (result.errors.type == "PERIOD" && (!Number.isInteger(context.begin) || context.begin <= 0)) {
        result.errors.begin = "reports.validation.invalidStartTime";
    }
    if (!Number.isInteger(context.end) || context.end <= 0) {
        result.errors.end = "reports.validation.invalidEndTime";
    }
    if (context.end <= context.begin) {
        result.errors.begin = "reports.validation.endMustBeAfterStart";
        result.errors.end = "reports.validation.endMustBeAfterStart";
    }
    return { ...result, ok: _.isEmpty(result.errors) };
};

export const validateShiftContext = (context: IReportContextShift): ShiftValidationResult => {
    const result: ShiftValidationResult = { ok: false, errors: {} };
    if (!context.shiftId) {
        result.errors.shiftId = "reports.validation.invalidShift";
    }
    return { ...result, ok: _.isEmpty(result.errors) };
};

export const validateBatchContext = (context: IReportContextBatch): BatchValidationResult => {
    const result: BatchValidationResult = { ok: false, errors: {} };
    if (_.isEmpty(context.batchIds)) {
        result.errors.batchIds = "reports.validation.invalidBatch";
    }
    return { ...result, ok: _.isEmpty(result.errors) };
};