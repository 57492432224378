import { t } from "@comact/crc";
import _, { throttle as _throttle } from "lodash";
import * as React from "react";
import { createSelector } from "reselect";
import { CodeMirrorAdapter } from "./CodeMirrorAdapter";
import { IBeforeTextChange, ICustomValidator, ITextMarkup } from "./model";

export interface IFormulaEditorProps {
    oneLine?: boolean; // restrict editor to a single line of text
    initialValue: string;
    setValidator?: (validator: ICustomValidator) => void;
    onChange: (content: string) => void;
    onBlur?: (e: Event) => void;
    autoValidate?: boolean;
    variables: string[];
}

export abstract class AbstractCodeEditor<P extends IFormulaEditorProps = IFormulaEditorProps> extends React.PureComponent<P> {
    protected static readonly REGEX_VARIABLE = /\$[a-zA-Z0-9.-]+/;
    protected static readonly TYPE_VARIABLE = "variable";
    protected static readonly TYPE_ERROR = "error";

    protected textEditorContainer: HTMLTextAreaElement;
    protected textEditor: CodeMirrorAdapter;

    // automatically optimized to only validate if the content has changed
    private validateContent = createSelector((content: string) => content, (_content: string) => {
        // analyze content of the editor
        const analysis = this.analyzeText();
        // highlight errors in the editor
        this.textEditor.markAll(analysis);
        // return appropriate error messages
        const markedUpErrors = analysis.filter((val) => val.detail == AbstractCodeEditor.TYPE_ERROR);
        return (markedUpErrors.length > 0) ? [t(this.ERROR_KEY_INVALID)] : [];
    });

    protected analyzeText(): ITextMarkup<string>[] {
        // validate variable names
        const { variables } = this.props;
        return this.textEditor.analyzeText(AbstractCodeEditor.REGEX_VARIABLE, (variable: string): string => {
            if (variables.some((field) => variable == field)) return AbstractCodeEditor.TYPE_VARIABLE;
            return AbstractCodeEditor.TYPE_ERROR;
        });
    }

    private onBeforeChange = (changeObj: IBeforeTextChange) => {
        // if there is only one item in the text array, just ignore. also ingnore undo/redo events
        if (this.props.oneLine && !changeObj.canceled && changeObj.text && changeObj.text.length > 1 && !["undo", "redo"].includes(changeObj.origin)) {
            // handle the case when the user just pasted a bunch of newlines (or pressed "Enter" on the keyboard)
            // changeObj.text will looks like this: ["", "", ""]
            const filteredText = changeObj.text.filter((text) => text != "");

            // if there is no content left, cancel the change
            if (filteredText.length == 0) {
                changeObj.cancel();
                return;
            }

            // if there are multiple lines left, combine multiple lines into one, which to the user looks like replacing newline characters with spaces
            const text = filteredText.length == 1 ? filteredText[0] : filteredText.join(" ");
            changeObj.update(changeObj.from, changeObj.to, [text]);
        }
    };

    private onChange = _throttle(() => {
        this.props.onChange(this.textEditor.getContent());
    }, 100);

    componentDidMount() {
        this.textEditor = new CodeMirrorAdapter(this.textEditorContainer, {
            mode: this.FORMULA_MODE,
            onChange: this.onChange,
            onBeforeChange: this.onBeforeChange,
            onBlur: this.props.onBlur,
            wordList: this.props.variables,
        });
        if (this.props.setValidator) this.props.setValidator(() => this.validateContent(this.textEditor.getContent()));
        this.onChange();
    }

    // dynamic update of variables in the code editor
    componentDidUpdate(prevProps: Readonly<P>): void {
        const { variables } = this.props;
        if (!_.isEqual(variables, prevProps.variables)) {
            this.textEditor.setWordList(variables);
        }
    }

    componentWillUnmount() {
        if (this.props.setValidator) this.props.setValidator(null);
    }

    render() {
        if (this.props.autoValidate && this.textEditor) {
            this.validateContent(this.textEditor.getContent());
        }
        return <textarea ref={(input) => this.textEditorContainer = input} defaultValue={this.props.initialValue} />;
    }

    /**
     * Anyone wanting to implement this editor must extends AbstractCodeEditor and override the following with their own values in their implementation
     */
    protected abstract readonly FORMULA_MODE: string; // validation formula used to validate the content
    protected abstract readonly ERROR_KEY_INVALID: string; // error message key for error message when the formula is not valid
}
