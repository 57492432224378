import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IDowntime, IDowntimeComment, IDowntimeComments, IDowntimes } from "./model";

declare global {
    interface IStoreState {
        downtimes: IDowntimes;
        nodeIdsWithDowntimeSupport: string[];
        downtimeComments: IDowntimeComments;
    }
}

const useStats = "downtimes";
const isEqual = (prev: IDowntime | IDowntimeComment, next: IDowntime | IDowntimeComment) => prev.modificationDate == next.modificationDate;

const downtimes = createSlice({
    name: "downtimes",
    initialState: null as IDowntimes,
    reducers: {
        set: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IDowntime[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
    },
});

const nodeIdsWithDowntimeSupport = createSlice({
    name: "nodeIdsWithDowntimeSupport",
    initialState: null as string[],
    reducers: {
        setNodeIdsWithDowntimeSupport: (_state, { payload }: PayloadAction<string[]>) => payload,
    },
});

const downtimeComments = createSlice({
    name: "downtimeComments",
    initialState: null as IDowntimeComments,
    reducers: {
        setDowntimeComments: (state, { payload }: PayloadAction<IDowntimeComment[]>) => (
            Utils.slices.set({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        patchDowntimeComments: (state, { payload }: PayloadAction<IDowntimeComment[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
    },
    extraReducers: (builder) => {
        builder.addCase(downtimes.actions.set, (state, { payload }) => {
            const comments = _.chain(payload)
                .map(({ comment }) => comment)
                .uniq()
                .compact()
                .keyBy(({ id }) => id)
                .value();
            return Utils.slices.patch({ state, nextState: comments, isEqual, useStats });
        });
    },
});

export const actionsCreators = {
    ...downtimes.actions,
    ...nodeIdsWithDowntimeSupport.actions,
    ...downtimeComments.actions,
};

export default {
    [downtimes.name]: downtimes.reducer,
    [nodeIdsWithDowntimeSupport.name]: nodeIdsWithDowntimeSupport.reducer,
    [downtimeComments.name]: downtimeComments.reducer,
};