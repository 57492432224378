import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IReportJob, IReportJobs } from "./model";

declare global {
    interface IStoreState {
        reportJobs: IReportJobs;
    }
}

const useStats = "reportJobs";

const reportJobs = createSlice({
    name: "reportJobs",
    initialState: null as IReportJobs,
    reducers: {
        set: (state, { payload }: PayloadAction<IReportJob[]>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        patch: (state, { payload }: PayloadAction<IReportJob[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...reportJobs.actions,
};

export default {
    [reportJobs.name]: reportJobs.reducer,
};