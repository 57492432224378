import { ajaxRequest, app, routing } from "@comact/crc";
import _ from "lodash";
import { IScannerCodecNode, NodesModule } from "../node";
import { ITimber } from "./model";
import { actions } from "./slices";

export const getLatestTimberByScanner = (scannerId: string, host: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const index = NodesModule.selectors.getNodeById<IScannerCodecNode>(getState(), scannerId).scannerIndex;
    if (app.serverLess) {
        const sampleId = _.sample([1, 2]);
        return ajaxRequest({ url: `${routing.baseUrl}/static/serverLess/timber/${sampleId}.json` }).promise
            .then((timber: ITimber) => {
                dispatch(actions.patch([{ scannerId, solutionId: sampleId, timber, configId: "same" }]));
            });
    } else {
        return ajaxRequest({
            method: "GET",
            url: `http://${host}/Optimizer/api/vault/v3/solutions?direction=DESC&limit=1&scannerId=${index}`, // scannerId is scannerIndex,
            data: undefined,
        }).promise
            .then((data: { uuid?: string; id: number; solutionUid: string; configId: string; }[]) => {
                if (data?.[0]) {
                    const { uuid, id, solutionUid, configId } = data[0];
                    const lastSolutionId = getState().timbers?.[scannerId]?.solutionId;
                    const currentId = uuid || id;
                    if ((solutionUid == lastSolutionId) || (currentId == lastSolutionId)) {
                        return Promise.resolve();
                    } else {
                        return ajaxRequest({
                            uniqueID: `${host}/Optimizer/api/vault/v1/solutions/display_shapes/timberViewer`,
                            url: `http://${host}//Optimizer/api/vault/v1/solutions/${currentId}/display_shapes`,
                            onSuccess: ((timber: ITimber) => {
                                dispatch(actions.patch([{ scannerId, solutionId: id, timber, configId }]));
                            }),
                        }).promise;
                    }
                } else {
                    return Promise.resolve(); // No data or missing last solution info.
                }
            });
    }
};