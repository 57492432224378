import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { convertDataflowsServer, IDataflows, IDataflowServer, IDataflowsServer, IModel, IModels } from "./model";

declare global {
    interface IStoreState {
        models: IModels;
        dataflowSimulation: IDataflows;
        dataflowLive: IDataflows;
    }
}

const useStatsModel = "models";

const models = createSlice({
    name: "models",
    initialState: null as IModels,
    reducers: {
        setModels: (state, { payload }: PayloadAction<IModels>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats: useStatsModel })
        ),
        patchModels: (state, { payload }: PayloadAction<IModel[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats: useStatsModel })
        ),
        deleteModels: (state, { payload }: PayloadAction<IModel["id"][]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsModel })
        ),
    },
});

const useStatsDataflowLive = "dataflowLive";

const dataflowLive = createSlice({
    name: "dataflowLive",
    initialState: null as IDataflows,
    reducers: {
        setDataflowsLive: (state, { payload }: PayloadAction<IDataflowsServer>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: convertDataflowsServer(payload), useStats: useStatsDataflowLive })
        ),
        patchDataflowsLive: (state, { payload }: PayloadAction<IDataflowServer[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: convertDataflowsServer(_.keyBy(payload, ({ dataflow }) => dataflow.uid)), useStats: useStatsDataflowLive })
        ),
    },
});

const useStatsDataflowSimulation = "dataflowSimulation";

const dataflowSimulation = createSlice({
    name: "dataflowSimulation",
    initialState: null as IDataflows,
    reducers: {
        setDataflowsSimulation: (state, { payload }: PayloadAction<IDataflowsServer>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: convertDataflowsServer(payload), useStats: useStatsDataflowSimulation })
        ),
        patchDataflowsSimulation: (state, { payload }: PayloadAction<IDataflowServer[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: convertDataflowsServer(_.keyBy(payload, ({ dataflow }) => dataflow.uid)), useStats: useStatsDataflowSimulation })
        ),
    },
});

export const actions = {
    ...models.actions,
    ...dataflowLive.actions,
    ...dataflowSimulation.actions,
};

export default {
    [models.name]: models.reducer,
    [dataflowLive.name]: dataflowLive.reducer,
    [dataflowSimulation.name]: dataflowSimulation.reducer,
};