import { TextFit } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import * as React from "react";

interface IWidgetTitleProps extends React.ComponentPropsWithRef<"div"> {
    color?: "empty" | "warn" | "error" | "normal";
}

const WidgetTitleStyle = styled.div`
    text-align: center;
    margin-bottom: ${CSS.gutter / 2}px;
    white-space: nowrap;
    > span{
        display: inline-block;
        padding: 1px ${CSS.gutter}px;
        border-radius: ${CSS.borderRadius}px;
        font-weight: ${CSS.fontWeight.bold};
        transition: background 1s ease-out, box-shadow 1s ease-out;
        text-shadow: .09em .04em .05em $color-shadow;
        &, &.empty{
            background-color: rgba($color-white, 0);
            box-shadow: 0em 0em 0em rgba($color-white, .02);
        }
        &.warn {
            background-color: ${CSS.colors.yellow};
            box-shadow: .05em .05em .1em ${CSS.colors.shadow};
        }
        &.error {
            background-color: ${CSS.colors.red};
            box-shadow: .05em .05em .1em ${CSS.colors.shadow};
        }
        &.normal {
            background-color: $color-green;
            box-shadow: .05em .05em .1em ${CSS.colors.shadow};
        }
    }
`;

const WidgetTitle = React.memo<IWidgetTitleProps>(({ children, color = "empty", ...props }) => {
    if (children == "") return <span />; // If empty string, return an empty span
    return (
        <WidgetTitleStyle {...props}>
            <TextFit><span className={color}>{children}</span></TextFit>
        </WidgetTitleStyle>
    );
});
export default WidgetTitle;