import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IProductsByMachine, IProductsServer } from "./model";

declare global {
    interface IStoreState {
        productsByMachines: IProductsByMachine;
    }
}

export default createSlice({
    name: "productsByMachines",
    initialState: {} as IStoreState["productsByMachines"],
    reducers: {
        setAllByMachineAndConfig: (state, { payload: { machineHost, configId, serverProducts } }: PayloadAction<{ machineHost: string; configId: string; serverProducts: IProductsServer; }>) => {
            const products = _(serverProducts)
                .map(({ id, name, color }) => ({ id, name, color }))
                .keyBy(({ id }) => id)
                .value();
            if (!state[machineHost]) state[machineHost] = {};
            if (!state[machineHost][configId]) state[machineHost][configId] = { configId: null, products: null, addedDate: null };

            Utils.slices.deepImmerUpdate(state[machineHost][configId], { configId, products, addedDate: Date.now() });

            const MAX_BEFORE_PURGE = 10; // purge limit
            if (_.size(state[machineHost]) > MAX_BEFORE_PURGE) {
                state[machineHost] = _(state[machineHost])
                    .orderBy(({ addedDate }) => addedDate, "desc")
                    .slice(0, MAX_BEFORE_PURGE)
                    .keyBy(({ configId: k }) => k)
                    .value();
            }
        },
    },
});