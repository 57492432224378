import { getLocalizedText, IMenuItem, routing, t, UserModule } from "@comact/crc";
import { hasPermission } from "@comact/crc/modules/user/selectors";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { FeatureFlagsModule } from "../../featureFlags";
import { getLinksForCurrentPlatform, IMillNode, INode, INodeHierarchicalWithMenu, INodeWithLinks } from "../model";
import { getLocationNode, getMillNode, getNodeById, getNodesHierarchical } from "../selectors";
import { allPerspectivesDefinitions, IPerspective, IPerspectiveDefinition, IPerspectiveInstance, IPerspectivePosition } from "./model";

/**
 * Gets the perspective instances of a specific node following a position and the connected user.
 */
export const makeGetPerspectiveInstancesForNode = (showDuplicates: boolean = true) => (
    memoize(({ state, positions, nodeId }: { state: IStoreState; positions: IPerspectivePosition[]; nodeId: string; }) => {
        const node = getNodeById(state, nodeId);
        const { system: { isMaster } } = state;

        const isPerspectiveAvailable = (def: IPerspectiveDefinition, templateName: INode["templateName"], millNodeId: IMillNode["id"]) => {
            if (!_.includes(def.validPlatform, process.env.EXEC_MODE)) return false;
            if (def.hidden?.()) return false;
            if (def.templates && !_.includes(def.templates, templateName)) return false;
            if (_.every(def.perspectivePosition, (pp) => !positions.includes(pp))) return false; // check position
            if (!hasPermission(state, def.permissions, millNodeId)) return false;
            if (!_.isEmpty(def.featureFlags) && !FeatureFlagsModule.selectors.hasRequiredFeatureFlags(state, def.featureFlags)) return false; // missing some feature flags
            if (!isMaster) return !!def.runWithoutNodes;
            return true;
        };

        const mill = getMillNode(state, nodeId);
        const location = getLocationNode(state, nodeId);

        const perspectiveDefinitions = _.reduce(allPerspectivesDefinitions, (prev, perspectiveDefinition) => {
            if (showDuplicates && mill && isPerspectiveAvailable(perspectiveDefinition, "millCodec", mill?.id)) {
                prev[perspectiveDefinition.id] = ({ def: perspectiveDefinition, node: mill });
            }

            if (process.env.EXEC_MODE == "icp" && showDuplicates && location && isPerspectiveAvailable(perspectiveDefinition, "location", null)) {
                prev[perspectiveDefinition.id] = ({ def: perspectiveDefinition, node: location });
            }

            if (isPerspectiveAvailable(perspectiveDefinition, node?.templateName, mill?.id)) {
                prev[perspectiveDefinition.id] = ({ def: perspectiveDefinition, node });
            }
            return prev;
        }, {} as Record<string, { def: IPerspectiveDefinition; node: INode; }>);

        const orderedList = _.chain(perspectiveDefinitions)
            .values()
            .orderBy((p) => p.def.priority, "asc")
            .map((p) => ({ def: { ...p.def, priority: p.def.priority || 0 }, node: p.node }))
            .value();

        // Convert a perspective definition into multiple instances (IPerspectiveInstance)
        return _.reduce(orderedList, (prev, { def: perspectiveDefinition, node: relatedNode }) => {
            const priority = (relatedNode?.templateName == "millCodec") ? perspectiveDefinition.priority - 1000 : perspectiveDefinition.priority;
            const isMill = relatedNode?.templateName == "millCodec";
            if (perspectiveDefinition.id == "link") { // Link perspectives
                if (!relatedNode) return prev; // can't have links on root...
                const links = getLinksForCurrentPlatform((relatedNode as INodeWithLinks).desired?.links || (node as INodeWithLinks)?.links || []);
                _.map(links, (link) => { // faudrait prendre un ou l'autre
                    const url = link.type == "externalLink"
                        ? link.url
                        : `/${perspectiveDefinition.id}/${relatedNode.id}/${link.id}`;
                    prev.push({
                        id: `${perspectiveDefinition.id}/${link.id}`,
                        perspectiveId: perspectiveDefinition.id,
                        perspectiveParentId: perspectiveDefinition.parent,
                        name: getLocalizedText(link.name, true),
                        active: () => routing.currentPath == url,
                        priority,
                        url,
                        icon: link.icon,
                        targetBlank: link.type == "externalLink",
                        isAlternate: !isMill || link.type == "externalLink",
                    });
                });
            } else { // Others perspectives
                const url = relatedNode?.id && !perspectiveDefinition.systemPerspective ? `/${perspectiveDefinition.id}/${relatedNode.id}` : `/${perspectiveDefinition.id}`;
                prev.push({
                    id: perspectiveDefinition.id,
                    perspectiveId: perspectiveDefinition.id,
                    perspectiveParentId: perspectiveDefinition.parent,
                    name: t(`pages.menu.perspective.${perspectiveDefinition.id}`),
                    priority,
                    url,
                    active: ((() => {
                        if (routing.currentPath == url) return true;
                        return routing.currentPath.startsWith(`/${perspectiveDefinition.id}/`);
                    })()),
                    icon: perspectiveDefinition.id as IPerspectiveInstance["icon"],
                    isAlternate: !isMill,
                    customSubMenuRenderer: perspectiveDefinition.customSubMenuRenderer,
                });
            }
            return prev;
        }, [] as IPerspectiveInstance[]);
    }));

const createNodePerpectivesMenu = (availablePerspectiveInstances: IPerspectiveInstance[]): IMenuItem[] => {
    const allParentPerspectiveInstances = _.filter(availablePerspectiveInstances, (p) => !p.perspectiveParentId);
    const allChildPerspectiveInstances = _.filter(availablePerspectiveInstances, (p) => !!p.perspectiveParentId);

    const menu = _.reduce(allParentPerspectiveInstances, (prev, perpectiveInstance) => {
        const perspectiveDefinition = allPerspectivesDefinitions[perpectiveInstance.perspectiveId];
        const { perspectiveId, perspectiveParentId, ...rest } = perpectiveInstance;
        // Add current parent
        prev[perpectiveInstance.id] = {
            ...rest,
            category: "perspective",
            isCore: _.includes(perspectiveDefinition.perspectivePosition, "menu"),
            subMenu: [],
        };

        // Perspective children
        const childPerspectives = _.filter(allChildPerspectiveInstances, (p) => perpectiveInstance.perspectiveId == p.perspectiveParentId);

        // Add the parent as a submenu if not marked as hidden
        if (!_.isEmpty(childPerspectives) && !perspectiveDefinition.hideInSubmenu) {
            childPerspectives.push({
                ...perpectiveInstance,
                active: () => routing.currentPath == perpectiveInstance.url,
                priority: -1, // be sure the priority is lower so it come first
            });
        }

        _.forEach(childPerspectives, (subPerspectiveInstance) => {
            const { perspectiveId: subPerspectiveId, perspectiveParentId: subPerspectiveParentId, ...subRest } = subPerspectiveInstance;

            // Perspective children
            const grandChildPerspectives = _.filter(allChildPerspectiveInstances, (p) => subPerspectiveId == p.perspectiveParentId);

            // Add the children as a submenu if not marked as hidden
            if (!_.isEmpty(grandChildPerspectives) && !perspectiveDefinition.hideInSubmenu) {
                grandChildPerspectives.push({
                    ...subPerspectiveInstance,
                    active: () => routing.currentPath == subPerspectiveInstance.url,
                    priority: -2, // be sure the priority is lower so it come first
                });
            }

            prev[perpectiveInstance.id].subMenu.push({
                ...subRest,
                category: "perspective",
                subMenu: _.map(grandChildPerspectives, ({ perspectiveId: subsubPerspectiveId, perspectiveParentId: subsubPerspectiveParentId, ...subSubRest }) => ({
                    ...subSubRest,
                    category: "perspective",
                    active: () => routing.currentPath == subSubRest.url,
                })),
            });
        });
        return prev;
    }, {} as Record<string, IMenuItem>);

    return _.values(menu);
};

// Get both menu (menu + header)
export const makeGetNodePerpectivesMenuAndHeader = () => {
    const getPerspectivesForNode = makeGetPerspectiveInstancesForNode();
    return memoize(({ state, nodeId }: { state: IStoreState; nodeId: string; }) => {
        const availablePerspectiveInstances = nodeId
            ? getPerspectivesForNode({ state, nodeId, positions: ["menu", "header"] })
            : getPerspectivesForNode({ state, nodeId, positions: ["menu"] }); // if current perspective is system (e.g. user-management, templates, ...) do not add the header menu.
        return createNodePerpectivesMenu(availablePerspectiveInstances);
    });
};

export const getSitemap = ((() => {
    const getPerspectivesForNode = makeGetPerspectiveInstancesForNode(false);

    const getNodePerpectivesHeader = (state: IStoreState, nodeId: string) => createNodePerpectivesMenu(getPerspectivesForNode({ state, nodeId, positions: ["header"] }));

    return memoize((state: IStoreState) => {
        const nodesHierarchical = getNodesHierarchical(state);

        const nodesHierarchicalWidthMenu = _.mapValues(nodesHierarchical, (n) => {
            const menu = getNodePerpectivesHeader(state, n.id);
            return { ...n, menu } as INodeHierarchicalWithMenu;
        });
        return nodesHierarchicalWidthMenu;
    });
})());

export const getSystemMenu = ((() => {
    const getPerspectivesForNode = makeGetPerspectiveInstancesForNode(false);

    return memoize((state: IStoreState) => (
        createNodePerpectivesMenu(getPerspectivesForNode({ state, nodeId: null, positions: ["menu"] }))
    ));
})());

export const getPerspectiveAccessState = (
    state: IStoreState, perspectiveName: IPerspective, templateName: INode["templateName"], millNodeId: string
): "denied" | "allowed" | "notFound" | "notReady" => {
    const perspectiveDefinition = allPerspectivesDefinitions[perspectiveName];
    if (!UserModule.selectors.isLoggedIn(state)) return "notReady"; // we don't need permission to navigate on cmoc but we need them for icp
    if (perspectiveDefinition?.hidden?.()) return "notFound";
    if (!_.isEmpty(perspectiveDefinition.featureFlags)) {
        if (!FeatureFlagsModule.selectors.isFeatureFlagsLoaded(state)) return "notReady"; // feature flags are not loaded yet we can't check if any are missing
        if (!FeatureFlagsModule.selectors.hasRequiredFeatureFlags(state, perspectiveDefinition.featureFlags)) return "notFound"; // missing some feature flags
    }
    if (_.includes(perspectiveDefinition?.conditions, "account") && !UserModule.selectors.getMyAccount(state)) return "denied";
    if (_.includes(perspectiveDefinition?.conditions, "user") && !UserModule.selectors.getCurrentUser(state)) return "denied";
    if (!perspectiveDefinition.validPlatform.includes(process.env.EXEC_MODE as ("cmoc" | "icp"))) return "notFound";
    if (templateName && perspectiveDefinition.templates != null && !_.includes(perspectiveDefinition.templates, templateName)) return "notFound";
    if (UserModule.selectors.hasPermission(state, perspectiveDefinition.permissions, millNodeId)) return "allowed";
    return "denied";
};