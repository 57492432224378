import { app, store } from "@comact/crc";
import _ from "lodash";
import { ITemplateServer } from "./model";

export const fetchTemplates = () => {
    const initialTemplates = app.serverLess ? templates : [];
    const currentTemplates = store.getState().templates;
    const mixedTemplates = { ..._.keyBy(initialTemplates, ({ id }) => id), ..._.keyBy(currentTemplates, ({ id }) => id) }; // override initial templates with modified current templates
    return _.values(mixedTemplates);
};

const templates: ITemplateServer[] = [
    {
        name: "cameraServer",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        id: "af0b19fc-c01e-4d36-8ded-b115ff3c6671",
        type: "CODEC",
        system: false,
        properties: {
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Host adress of the cameras server.",
                        fr: "Adresse de l'hôte du serveur de cameras.",
                        fi: "Host adress of the cameras server.",
                        pt: "Host adress of the cameras server.",
                    },
                },
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 1,
                category: null,
                min: null,
                max: null,
            },
            username: {
                name: "username",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The required username used to connect to the host.",
                        fr: "Le nom d'utilisateur requis pour se connecter à l'hôte.",
                        fi: "The required username used to connect to host.",
                        pt: "The required username used to connect to host.",
                    },
                },
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 2,
                category: null,
                min: null,
                max: null,
            },
            password: {
                name: "password",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The required password used to connect to the host.",
                        fr: "Le mot de passe requis pour se connecter à l'hôte.",
                        fi: "The required password used to connect to the host.",
                        pt: "The required password used to connect to the host.",
                    },
                },
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 3,
                category: null,
                min: null,
                max: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "camera",
        allowedParents: ["cameraServer"],
        version: "1.0.0",
        id: "e952e95e-cba1-4e3f-86f9-b2d0813ac63b",
        type: "CODEC",
        system: false,
        properties: {
            internalCameraId: {
                name: "internalCameraId",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The internal camera id used by the cameras server.",
                        fr: "L'id de camera interne utilisé par le serveur de caméras",
                        fi: "The internal camera id used by the cameras server.",
                        pt: "The internal camera id used by the cameras server.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 1,
                category: null,
                min: null,
                max: null,
            },
            status: {
                name: "status",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The current status of the camera.",
                        fr: "Le status actuel de la caméra.",
                        fi: "The current status of the camera.",
                        pt: "The current status of the camera.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 2,
                category: null,
                min: null,
                max: null,
            },
            audioEnabled: {
                name: "audioEnabled",
                type: "BOOLEAN",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The state of the audio of the camera.",
                        fr: "L'état de l'audio de la caméra.",
                        fi: "The state of the audio of the camera.",
                        pt: "The state of the audio of the camera.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 3,
                category: null,
                min: null,
                max: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "standaloneCamera",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        id: "9e3211c6-23a0-449e-8d40-83af9467020a",
        type: "CODEC",
        system: false,
        properties: {
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Host adress of the camera.",
                        fr: "Adresse de l'hôte de la caméra.",
                        fi: "Host adress of the camera.",
                        pt: "Host adress of the camera.",
                    },
                },
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 1,
                category: null,
                min: null,
                max: null,
            },
            username: {
                name: "username",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The username used to connect to the camera.",
                        fr: "Le nom d'utilisateur utilisé pour se connecter à la caméra.",
                        fi: "The username used to connect to the camera.",
                        pt: "The username used to connect to the camera.",
                    },
                },
                defaultValue: "root",
                mandatory: true,
                readOnly: false,
                order: 2,
                category: null,
                min: null,
                max: null,
            },
            password: {
                name: "password",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The password used to connect to the camera.",
                        fr: "Le mot de passe utilisé pour se connecter à la caméra.",
                        fi: "The password used to connect to the camera.",
                        pt: "The password used to connect to the camera.",
                    },
                },
                defaultValue: "root",
                mandatory: true,
                readOnly: false,
                order: 3,
                category: null,
                min: null,
                max: null,
            },
            model: {
                name: "model",
                type: "CHOICE",
                description: {
                    display: "en",
                    values: {
                        en: "The camera specific model.",
                        fr: "Le modèle spécifique de la caméra.",
                        fi: "The camera specific model.",
                        pt: "The camera specific model.",
                    },
                },
                choices: [
                    "UNKNOWN",
                    "CC04-IP3MV3",
                ],
                defaultValue: "UNKNOWN",
                mandatory: true,
                readOnly: false,
                order: 4,
                category: null,
                min: null,
                max: null,
            },
            captureActive: {
                name: "captureActive",
                type: "BOOLEAN",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Enable video stream capture",
                        fr: "Activer la capture du flux vidéo",
                        fi: "Enable video stream capture",
                        pt: "Enable video stream capture",
                    },
                },
                defaultValue: true,
                mandatory: true,
                readOnly: false,
                order: 5,
                category: null,
                min: null,
                max: null,
            },
            displayMode: {
                name: "displayMode",
                type: "CHOICE",
                description: {
                    display: "en",
                    values: {
                        en: "Choose how the camera's video stream is displayed.",
                        fr: "Choisir comment le flux vidéo de la caméra est affiché.",
                        fi: "Choose how the camera's video stream is displayed.",
                        pt: "Choose how the camera's video stream is displayed.",
                    },
                },
                choices: [
                    "Direct Stream",
                    "HLS Stream",
                ],
                defaultValue: "HLS Stream",
                mandatory: true,
                readOnly: false,
                order: 6,
                category: null,
                min: null,
                max: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "L5X",
        id: "1dd74562-40c9-49e6-b67d-d5ba6de6d9dd",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        type: "CODEC",
        system: false,
        properties: {},
        singleton: false,
        configMap: null,
    },
    {
        name: "genericDevice",
        id: "f644fdcd-cf29-4ad9-93a1-c462363e721f",
        allowedParents: [
            "genericDevice",
            "millCodec",
        ],
        version: "1.0.0",
        type: "DATA",
        system: false,
        properties: {
            links: {
                name: "links",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: false,
                order: 1,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            kpis: {
                name: "kpis",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 0,
                category: null,
                min: null,
                max: null,
                description: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "customer",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        system: false,
        id: "11367f04-58a8-4fc0-a505-a2a1d89d4d85",
        type: "SYSTEM",
        properties: {
            links: {
                name: "links",
                type: "OBJECT",
                description: {
                    values: {
                        en: "IFrame links that should be displayed on this node.",
                        fr: "Liens IFrame qui seront affichés sur cet élément",
                        fi: "IFrame links that should be displayed on this node.",
                        pt: "IFrame links that should be displayed on this node.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                order: 0,
            },
        },
    },
    {
        name: "location",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        system: false,
        id: "2d3ef439-6f65-4efd-88b1-22f06dc0b47f",
        type: "SYSTEM",
        properties: {
            links: {
                name: "links",
                type: "OBJECT",
                description: {
                    values: {
                        en: "IFrame links that should be displayed on this node.",
                        fr: "Liens IFrame qui seront affichés sur cet élément",
                        fi: "IFrame links that should be displayed on this node.",
                        pt: "IFrame links that should be displayed on this node.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                order: 0,
            },
        },
    },
    {
        name: "millCodec",
        allowedParents: [null],
        version: "1.0.0",
        id: "2977b4c1-447f-4234-9a91-c60e99f39eaa",
        type: "SYSTEM",
        system: true,
        properties: {
            shift: {
                name: "shift",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 0,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            customer: {
                name: "customer",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 1,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            site: {
                name: "site",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 2,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            links: {
                name: "links",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: false,
                order: 3,
                category: null,
                min: null,
                max: null,
                description: null,
            },
        },
        configMap: null,
        singleton: false,
    },
    {
        name: "machineCodec",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        id: "8e6a39ea-5b0c-48f3-8898-2ae432593d4c",
        type: "CODEC",
        properties: {
            machine: {
                name: "machine",
                type: "CHOICE",
                description: {
                    display: "en",
                    values: {
                        en: "The machine type of this node.",
                        fr: "Le type de machine présent sur cette node.",
                        fi: "The machine type of this node.",
                        pt: "The machine type of this node.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Description",
                        fr: "Description",
                        fi: "Description",
                        pt: "Description",
                    },
                },
                choices: [
                    "BUCKING",
                    "DDM",
                    "EDGER",
                    "EDGEXPERT",
                    "ENDDOGGING",
                    "GRADEXPERT",
                    "LINEAREDGER",
                    "LOGSORTER",
                    "OFFSET",
                    "OLI",
                    "REDUCER",
                    "SAMPLER",
                    "TBL",
                    "TRIMEXPERT",
                    "TRIMMER",
                    "VISIONALONE",
                    "SORTPRO",
                ],
                defaultValue: null,
                mandatory: true,
                order: 0,
                min: null,
                max: null,
            },
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "IP Address of the machine cmoc host.",
                        fr: "Adresse IP de la machine actuelle du cmoc.",
                        fi: "IP Address of the machine cmoc host.",
                        pt: "IP Address of the machine cmoc host.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                defaultValue: null,
                mandatory: true,
                order: 1,
                min: 5,
                max: 40,
            },
            productionStatus: {
                name: "productionStatus",
                type: "OBJECT",
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 2,
            },
            liveConfigs: {
                name: "liveConfigs",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Optimizer active configurations.",
                        fr: "Configuration active de l'optimiseur.",
                        fi: "Optimizer active configurations.",
                        pt: "Optimizer active configurations.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 3,
                category: null,
                min: null,
                max: null,
            },
            simulationConfigs: {
                name: "simulationConfigs",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Simulator active configurations.",
                        fr: "Configuration active du simulateur.",
                        fi: "Simulator active configurations.",
                        pt: "Simulator active configurations.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 4,
                category: null,
                min: null,
                max: null,
            },
            version: {
                name: "version",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Version of the current machine.",
                        fr: "Version de la machine.",
                        fi: "Version of the current machine.",
                        pt: "Version of the current machine.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 5,
                category: null,
                min: null,
                max: null,
            },
            productionReady: {
                name: "productionReady",
                type: "BOOLEAN",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "If the current machine is compatible with the production manager.",
                        fr: "Indique si la machine actuelle est compatible avec le production manager.",
                        fi: "If the current machine is compatible with the production manager.",
                        pt: "If the current machine is compatible with the production manager.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 6,
                category: null,
                min: null,
                max: null,
            },
            diagnosticEnabled: {
                name: "diagnosticEnabled",
                type: "BOOLEAN",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Indicates if the diagnostic web application is available.",
                        fr: "Indique si l'application web du diagnostique est disponible.",
                        fi: "Indicates if the diagnostic web application is available.",
                        pt: "Indicates if the diagnostic web application is available.",
                    },
                },
                defaultValue: false,
                mandatory: false,
                readOnly: true,
                order: 7,
                category: null,
                min: null,
                max: null,
            },
            sorterEnabled: {
                name: "sorterEnabled",
                type: "BOOLEAN",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Indicates if the sorter web application is available.",
                        fr: "Indique si l'application web du classeur est disponible.",
                        fi: "Indicates if the sorter web application is available.",
                        pt: "Indicates if the sorter web application is available.",
                    },
                },
                defaultValue: false,
                mandatory: false,
                readOnly: true,
                order: 8,
                category: null,
                min: null,
                max: null,
            },
            cmocIdentifier: {
                name: "cmocIdentifier",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Identifier of the curent machine's cmoc.",
                        fr: "Identifiant du cmoc de la machine actuelle.",
                        fi: "Identifier of the curent machine's cmoc.",
                        pt: "Identifier of the curent machine's cmoc.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 10,
                category: null,
                min: null,
                max: null,
            },
            links: {
                name: "links",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                order: 11,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            kpis: {
                name: "kpis",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 12,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            minGap: {
                name: "minGap",
                type: "DOUBLE",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Minimum gap between two logs (gap too short).",
                        fr: "Distance minimale entre deux bios de bois (Distance trop courte).",
                        fi: "Minimum gap between two logs (gap too short).",
                        pt: "Minimum gap between two logs (gap too short).",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Positioning",
                        fr: "Positionnement",
                        fi: "Positioning",
                        pt: "Positioning",
                    },
                },
                defaultValue: 0.0,
                mandatory: true,
                order: 20,
                min: 0.0,
                max: 999.99,
            },
            recommendedGap: {
                name: "recommendedGap",
                type: "DOUBLE",
                choices: [],
                category: {
                    display: "en",
                    values: {
                        en: "Positioning",
                        fr: "Positionnement",
                        fi: "Positioning",
                        pt: "Positioning",
                    },
                },
                defaultValue: 0.0,
                mandatory: true,
                order: 21,
                min: 0.0,
                max: 999.99,
                description: null,
            },
            maxGap: {
                name: "maxGap",
                type: "DOUBLE",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Maximum gap between two logs (gap too long).",
                        fr: "Distance maximale entre deux bios de bois (Distance trop longue).",
                        fi: "Maximum gap between two logs (gap too long).",
                        pt: "Maximum gap between two logs (gap too long).",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Positioning",
                        fr: "Positionnement",
                        fi: "Positioning",
                        pt: "Positioning",
                    },
                },
                defaultValue: 0.0,
                mandatory: true,
                order: 22,
                min: 0.0,
                max: 999.99,
            },
            acquisitionMinDate: {
                name: "acquisitionMinDate",
                type: "DATE_TIME",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Minimum date to start the acquisition.",
                        fr: "Date minimum pour débuter l'acquisition.",
                        fi: "Minimum date to start the acquisition.",
                        pt: "Minimum date to start the acquisition.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Other",
                        fr: "Autre",
                        fi: "Other",
                        pt: "Other",
                    },
                },
                defaultValue: 18000000,
                mandatory: true,
                order: 23,
                min: null,
                max: null,
            },
            machineInfo: {
                name: "machineInfo",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 100,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            downtimeDetectionTime: {
                name: "downtimeDetectionTime",
                type: "DOUBLE",
                category: {
                    display: "en",
                    values: {
                        en: "Downtime",
                        fr: "Temps d'arrêt",
                        fi: "Downtime",
                        pt: "Downtime",
                    },
                },
                choices: [],
                defaultValue: 60,
                description: {
                    display: "en",
                    values: {
                        en: "Seconds Without Scans After Which it Becomes a Downtime.",
                        fr: "Secondes sans scan après lequel cela devient un temps d'arrêt.",
                        fi: "Seconds Without Scans After Which it Becomes a Downtime.",
                        pt: "Seconds Without Scans After Which it Becomes a Downtime.",
                    },
                },
                mandatory: true,
                max: 999.99,
                min: 0,
                order: 24,
                readOnly: false,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "iep",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        id: "118a1b9c-c73b-4989-864b-a015e89326a4",
        type: "CODEC",
        system: true,
        properties: {
            host: {
                name: "host",
                type: "TEXT",
                description: {
                    display: "en",
                    values: {
                        en: "IP Address of the machine cmoc host.",
                        fr: "Adresse IP de la machine actuelle du cmoc.",
                        fi: "IP Address of the machine cmoc host.",
                        pt: "IP Address of the machine cmoc host.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                choices: [],
                defaultValue: "localhost",
                mandatory: true,
                readOnly: true,
                order: 0,
                min: 5,
                max: 40,
            },
            version: {
                name: "version",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Version of the current machine.",
                        fr: "Version de la machine.",
                        fi: "Version of the current machine.",
                        pt: "Version of the current machine.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 1,
                category: null,
                min: null,
                max: null,
            },
            cmocIdentifier: {
                name: "cmocIdentifier",
                type: "TEXT",
                description: {
                    display: "en",
                    values: {
                        en: "Identifier of the curent machine cmoc.",
                        fr: "Identifiant de la machine actuelle du cmoc.",
                        fi: "Identifier of the curent machine cmoc.",
                        pt: "Identifier of the curent machine cmoc.",
                    },
                },
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 2,
                category: null,
                min: null,
                max: null,
            },
            links: {
                name: "links",
                type: "OBJECT",
                defaultValue: null,
                mandatory: false,
                order: 3,
                choices: [],
                category: null,
                min: null,
                max: null,
                description: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "scannerCodec",
        allowedParents: ["machineCodec"],
        version: "1.0.0",
        id: "c90116d0-fbe4-47aa-bef1-f57197539dca",
        type: "CODEC",
        system: false,
        properties: {
            scannerIndex: {
                name: "scannerIndex",
                type: "CHOICE",
                description: {
                    display: "en",
                    values: {
                        en: "Index of the current scanner.",
                        fr: "Index du scanner actuel.",
                        fi: "Index of the current scanner.",
                        pt: "Index of the current scanner.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Description",
                        fr: "Description",
                        fi: "Description",
                        pt: "Description",
                    },
                },
                choices: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                ],
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 0,
                min: 0,
                max: 10,
            },
            machine: {
                name: "machine",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "The machine type of this node.",
                        fr: "Le type de machine présent sur cette node.",
                        fi: "The machine type of this node.",
                        pt: "The machine type of this node.",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Description",
                        fr: "Description",
                        fi: "Description",
                        pt: "Description",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 1,
                min: null,
                max: null,
            },
            links: {
                name: "links",
                type: "OBJECT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: false,
                order: 2,
                category: null,
                min: null,
                max: null,
                description: null,
            },
            kpis: {
                name: "kpis",
                type: "OBJECT",
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 3,
                category: null,
                min: null,
                max: null,
                description: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "container",
        allowedParents: [
            "machineCodec",
            "iep",
        ],
        version: "1.0.0",
        id: "6c8f62c2-88b0-48e7-98c9-b9d1cfa298b8",
        type: "SYSTEM",
        system: true,
        properties: {
            cmocId: {
                name: "cmocId",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Identifier of the component on its machine.",
                        fr: "Identifiant du composant sur sa machine.",
                        fi: "Identifier of the component on its machine.",
                        pt: "Identifier of the component on its machine.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 10,
                category: null,
                min: null,
                max: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "machineComponent",
        allowedParents: ["container"],
        version: "1.0.0",
        id: "57619441-7116-45e7-90ca-b61d1305caef",
        type: "APPLICATION",
        system: true,
        properties: {
            cmocId: {
                name: "cmocId",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Identifier of the component on its machine.",
                        fr: "Identifiant du composant sur sa machine.",
                        fi: "Identifier of the component on its machine.",
                        pt: "Identifier of the component on its machine.",
                    },
                },
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 10,
                category: null,
                min: null,
                max: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "smartVisionZone",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        id: "b7c0bd55-4af5-4a6f-8271-91dd3acd0e2b",
        type: "CODEC",
        system: false,
        properties: {
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "IP Address of the host",
                        fr: "Adresse IP de l'hôte",
                        fi: "IP Address of the host",
                        pt: "IP Address of the host",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 1,
                min: 5,
                max: 40,
            },
            port: {
                name: "port",
                type: "TEXT",
                choices: [],
                description: {
                    display: "en",
                    values: {
                        en: "Port of the host",
                        fr: "Port de l'hôte",
                        fi: "Port of the host",
                        pt: "Port of the host",
                    },
                },
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                defaultValue: "8080",
                mandatory: true,
                readOnly: false,
                order: 2,
                min: 1,
                max: 5,
            },
            configTemplate: {
                name: "configTemplate",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: false,
                readOnly: true,
                order: 0,
                category: null,
                min: null,
                max: null,
                description: null,
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "plcMonitoring",
        id: "e18968d6-5a8c-452c-ae81-b0586454db88",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        type: "CODEC",
        system: false,
        properties: {
            port: {
                name: "port",
                type: "TEXT",
                choices: [],
                defaultValue: "5000",
                mandatory: true,
                readOnly: false,
                order: 2,
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "Port of the PLC monitoring service.",
                        fr: "Port du service PLC monitoring.",
                        fi: "Port of the PLC monitoring service.",
                        pt: "Port of the PLC monitoring service.",
                    },
                },
            },
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 1,
                category: {
                    display: "en",
                    values: {
                        en: "Network",
                        fr: "Réseau",
                        fi: "Network",
                        pt: "Network",
                    },
                },
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "Host address of the PLC monitoring service.",
                        fr: "Adresse de l'hôte du service PLC monitoring.",
                        fi: "Host address of the PLC monitoring service.",
                        pt: "Host address of the PLC monitoring service.",
                    },
                },
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "plc",
        id: "5ebd99da-74af-4177-93af-bb6dede2c694",
        allowedParents: ["plcMonitoring"],
        version: "1.0.0",
        type: "DATA",
        system: true,
        properties: {
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: true,
                order: 2,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "Host address of the PLC.",
                        fr: "Adresse de l'hôte du PLC.",
                        fi: "Host address of the PLC.",
                        pt: "Host address of the PLC.",
                    },
                },
            },
            slotNumber: {
                name: "slotNumber",
                type: "LONG",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: true,
                order: 3,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "The backplane position (slot) containing the target PLC.",
                        fr: "La position (slot) dans le backplane contenant le PLC cible.",
                        fi: "The backplane position (slot) containing the target PLC.",
                        pt: "The backplane position (slot) containing the target PLC.",
                    },
                },
            },
            plcId: {
                name: "plcId",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: true,
                order: 1,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "The PLC unique identifier used by the PLC Monitoring service.",
                        fr: "L'identifiant unique du PLC utilisé par le service PLC Monitoring",
                        fi: "The PLC unique identifier used by the PLC Monitoring service.",
                        pt: "The PLC unique identifier used by the PLC Monitoring service.",
                    },
                },
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "plcDevice",
        id: "9040717f-d0ac-431b-b3e0-c237b8b75a10",
        allowedParents: ["plc"],
        version: "1.0.0",
        type: "DATA",
        system: true,
        properties: {
            deviceType: {
                name: "deviceType",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: true,
                order: 2,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "The PLC device type.",
                        fr: "Le type du composant PLC.",
                        fi: "The PLC device type.",
                        pt: "The PLC device type.",
                    },
                },
            },
            deviceId: {
                name: "deviceId",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: true,
                order: 1,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "The PLC device unique identifier.",
                        fr: "L'identifiant unique du composant PLC.",
                        fi: "The PLC device unique identifier.",
                        pt: "The PLC device unique identifier.",
                    },
                },
            },
        },
        singleton: false,
        configMap: null,
    },
    {
        name: "pickAndPlace",
        allowedParents: ["millCodec"],
        version: "1.0.0",
        system: false,
        id: "234d9b42-22e4-41c3-b02d-db8f88ae0467",
        type: "CODEC",
        properties: {
            port: {
                name: "port",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 2,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "Port of the host",
                        fr: "Port de l'hôte",
                        fi: "Port of the host",
                        pt: "Port of the host",
                    },
                },
            },
            host: {
                name: "host",
                type: "TEXT",
                choices: [],
                defaultValue: null,
                mandatory: true,
                readOnly: false,
                order: 1,
                category: null,
                min: null,
                max: null,
                description: {
                    display: "en",
                    values: {
                        en: "IP Address of the host",
                        fr: "Adresse IP de l'hôte",
                        fi: "IP Address of the host",
                        pt: "IP Address of the host",
                    },
                },
            },
        },
        singleton: false,
        configMap: null,
    },
] as ITemplateServer[];
