// eslint-disable-next-line import/no-unassigned-import
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
// eslint-disable-next-line import/no-unassigned-import
import "codemirror/keymap/sublime";
import "codemirror/lib/codemirror.css";
// eslint-disable-next-line import/no-unassigned-import
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/monokai.css";
/* eslint-disable import/no-unassigned-import */
import "./formula";
/* eslint-enable import/no-unassigned-import */
import "./_codeEditor.scss";
import { IBeforeTextChange, ITextChange, ITextHighlight, ITextMarkup, ITextSelector } from "./model";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CodeMirror = require("codemirror");

export interface ICodeEditorOptions {
    wordList: string[];
    mode: string;
    readOnly?: boolean;
    onBeforeChange?: (changeObj: IBeforeTextChange) => void;
    onChange?: (changeObj: ITextChange, content: string) => void;
    onFocus?: (e: Event) => void;
    onBlur?: (e: Event) => void;
}

export class CodeMirrorAdapter {
    private wordList: string[] = []; // Liste des mots pour la suggestion automatique
    private textEditor; // L'objet éditeur CodeMirror
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private markers: any[] = []; // Liste des marqueurs appliqués (variables)
    private eventHandlers: Pick<ICodeEditorOptions, "onBeforeChange" | "onChange" | "onFocus" | "onBlur"> = {};

    constructor(fromTextArea: HTMLTextAreaElement, options: ICodeEditorOptions) {
        // Initialisation de l'objet CodeMirror
        this.textEditor = CodeMirror.fromTextArea(fromTextArea, {
            theme: "monokai",
            keyMap: "sublime",
            lineWrapping: true,
            viewportMargin: Infinity,
            extraKeys: {
                /* eslint-disable @typescript-eslint/naming-convention */
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                "Ctrl-Space": (editor: any) => editor.showHint(),
            },
            screenReaderLabel: "data-test-CodeEditor", // Do not touch, it is for the tests
        });

        // Empêche le défilement automatique de la page vers le haut si un évènement dans la zone de texte se produit.
        // Cela empêche par exemple un scroll si l'utilisateur ajoute une variable provenant d'un dataset.
        this.textEditor.on("scrollCursorIntoView", (_instance: unknown, event: { preventDefault: () => void; }) => {
            event.preventDefault();
        });

        // Tous les "on" doivent être enregistrés une seule fois sinon on a une duplication des événements
        this.textEditor.on("beforeChange", (_instance: unknown, changeObj: IBeforeTextChange) => {
            if (this.eventHandlers.onBeforeChange) this.eventHandlers.onBeforeChange(changeObj);
        });
        this.textEditor.on("change", (instance: { getValue: () => string; }, changeObj: ITextChange) => {
            if (this.eventHandlers.onChange) this.eventHandlers.onChange(changeObj, instance.getValue());
        });
        this.textEditor.on("focus", (_instance: unknown, event: Event) => {
            if (this.eventHandlers.onFocus) this.eventHandlers.onFocus(event);
        });
        this.textEditor.on("blur", (_instance: unknown, event: Event) => {
            if (this.eventHandlers.onBlur) this.eventHandlers.onBlur(event);
        });

        this.setOptions(options);
    }

    setOptions = (options: ICodeEditorOptions) => {
        this.textEditor.setOption("mode", options.mode);
        this.textEditor.setOption("readOnly", options.readOnly);

        this.setWordList(options.wordList);

        // Enregistrement du système de complétion semi-automatique (remplace s'il existe)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        CodeMirror.registerHelper("hint", options.mode, (editor: { getCursor: () => any; getLine: (arg0: any) => any; _wordListProvider: () => any; }, helperOptions: { word: any; }) => {
            const word = helperOptions?.word || /[$.\-a-zA-Z0-9]+$/;
            const cur = editor.getCursor();
            const curLine = editor.getLine(cur.line);
            const end = cur.ch;
            let start = end;

            while (start > 0 && word.test(curLine.charAt(start - 1))) --start;
            const foundWord = curLine.substring(start, end);

            // can't use "this.getWordList()"
            const wordList = editor._wordListProvider();

            const list = start != end ? wordList.filter((suggestion: string) => suggestion.includes(foundWord)) : wordList;

            return { list, from: CodeMirror.Pos(cur.line, start), to: CodeMirror.Pos(cur.line, end) };
        });

        // On triche en utilisant l'instance editor comme conteneur pour passer une référence à la fonction de liste de mots.
        // Le helper du menu contextuel "hint" reçoit cette même instance d'editor comme paramètre.
        this.textEditor._wordListProvider = this.getWordList;

        // Update event handlers
        const { onBeforeChange, onChange, onFocus, onBlur } = options;
        this.eventHandlers = { onBeforeChange, onChange, onFocus, onBlur };
    };

    setOnChange = (onChange: ICodeEditorOptions["onChange"]) => {
        this.eventHandlers.onChange = onChange;
    };

    focus = () => this.textEditor.focus();

    // Marquage du texte selon la configuration du highlight
    markText = (highlight: ITextHighlight) => {
        const { range: { from, to }, detail } = highlight;
        return this.textEditor.markText(from, to, { className: "highlight " + detail });
    };

    // Réapplique les marquages enregistrés.
    markAll = (markers: ITextHighlight[]) => {
        this.markers.forEach((marker) => marker.clear());
        this.markers = markers.map((marker) => this.markText(marker));
    };

    // Effectue une analyse du texte pour un marquage futur, à partir d'une méthode d'analyse et d'un regexp
    analyzeText<T>(pattern: RegExp, analyze: (element: string) => T): ITextMarkup<T>[] {
        const lines = this.getContent().split("\n");
        const analysisResults: ITextMarkup<T>[] = [];
        lines.forEach((value, line) => analysisResults.push(...this.findElementsInLine(value, line, pattern, analyze)));
        return analysisResults;
    }

    // Voir méthode précédente
    findElementsInLine<T>(content: string, line: number, pattern: RegExp, analyze: (element: string) => T): ITextMarkup<T>[] {
        const ranges: ITextMarkup<T>[] = [];
        let from = 0;
        let lineLeftover = content;

        while (from >= 0 && from < content.length) {
            const subStringStart = lineLeftover.search(pattern);
            if (subStringStart < 0) return ranges;
            const subString = lineLeftover.match(pattern)[0];
            from += subStringStart;
            const to = from + subString.length;

            const result = analyze(subString);

            if (result) {
                ranges.push({ range: { from: { line, ch: from }, to: { line, ch: to } }, detail: result });
            }

            lineLeftover = content.substring(to);
            from = to;
        }
        return ranges;
    }

    setContent = (text: string) => this.textEditor.setValue(text);

    getContent = (): string => this.textEditor.getValue();

    getLineCount = (): number => this.textEditor.lineCount();

    getWordList = (): string[] => this.wordList;
    setWordList = (list: string[]): void => {
        this.wordList = list;
    };

    // Injection de texte là où le curseur textuel se trouve
    injectTextOnSelection = (content: string) => {
        if (!content) return;
        const crsPos: ITextSelector = this.textEditor.getCursor("from");
        const selected: string = this.textEditor.getSelection();
        if (crsPos.ch > 0 && selected.length == 0) {
            const before = this.textEditor.getRange({ line: crsPos.line, ch: crsPos.ch - 1 }, crsPos) != " " ? " " : "";
            const nextRange = this.textEditor.getRange(crsPos, { line: crsPos.line, ch: crsPos.ch + 1 });
            const after = nextRange && nextRange != " " ? " " : "";
            this.textEditor.replaceSelection(
                before + content + after
            );
        } else {
            this.textEditor.replaceSelection(content);
        }
    };
}
