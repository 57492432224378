import _ from "lodash";

export { default as DETECTIONS } from "../../../generated/detections";
export interface IModel {
    id: string;
    name: string;
    version: string;
    userId: string;
    detections: string[]; // detections ids
    fileName: string;

    uploadDate?: string;

    state?: "ON_PREM" | "DISTANT" | "SYNCING";
    inCloud?: boolean;
    inOpti?: boolean;
}

export type IModels = { [id: string]: IModel; };

export interface IModelVersionsByName {
    name: string;
    detections: string[];
    models: IModels;
}

export type IModelVersionsByNames = { [name: string]: IModelVersionsByName; };

export const dataflowStates = ["DEACTIVATED", "CLASSIC", "AI"] as const;

export interface IDataflowServer {
    dataflow: {
        uid: number;
        state: typeof dataflowStates[number];
        regleRecette: number[];
        species: number[];
        model: string;
        labels: string[];
    };
    models: Record<string, IModel>;
}
export type IDataflowsServer = Record<string, IDataflowServer>;

export interface IDataflow {
    uid: number;
    recipes: number[]; // regleRecette
    species: number[];
    detections: string[]; // labels
    models: Record<string, IModel>;
    modelCount: number; // size modelIds
    linkId: string;
    state: IDataflowServer["dataflow"]["state"];
    model: string; // Not used in front
}

export type IDataflows = Record<string, IDataflow>;

export interface IGroupDataflow {
    live: IDataflow;
    simulation: IDataflow;
}

export const convertDataflowServer = (d: IDataflowServer): IDataflow => ({
    uid: d.dataflow.uid,
    recipes: d.dataflow.regleRecette,
    species: d.dataflow.species,
    detections: d.dataflow.labels,
    models: d.models,
    modelCount: _.size(d.models),
    state: d.dataflow.state,
    model: d.dataflow.model,
    linkId: `${_.orderBy(d.dataflow.regleRecette).join("-")}_${_.orderBy(d.dataflow.species).join("-")}_${_.orderBy(d.dataflow.labels).join("-")}`,
});

export const convertDataflowsServer = (dataflowsServer: IDataflowsServer): IDataflows => (
    _.chain(dataflowsServer)
        .map(convertDataflowServer)
        .keyBy((d) => d.uid)
        .value()
);

export const convertDataflowToServer = (d: IDataflow): IDataflowServer => ({
    dataflow: {
        state: d.state,
        uid: d.uid,
        regleRecette: d.recipes,
        species: d.species,
        model: d.model,
        labels: d.detections,
    },
    models: d.models,
});

export const convertDataflowsToServer = (dataflows: IDataflows): IDataflowsServer => (
    _.chain(dataflows)
        .map((d) => convertDataflowToServer(d))
        .keyBy((d) => d.dataflow.uid)
        .value()
);