import { ITagStrategy } from "./model";

export const getTagStrategies = ({ tagStrategies }: IStoreState) => tagStrategies;

export const getTagStrategyById = ({ tagStrategies }: IStoreState, id: string) => tagStrategies?.[id];

export const getDatabaseDefinitionByTimberType = (state: IStoreState, timberType: ITagStrategy["timberType"]) => {
    switch (timberType) {
        case "BOARD": return state.databaseDefinitions.Board;
        case "CANT": return state.databaseDefinitions.Cant;
        case "LOG": return state.databaseDefinitions.Log;
        case "LUMBER": return state.databaseDefinitions.Lumber;
        case "PRODUCT": return state.databaseDefinitions.Product;
        case "STEM": return state.databaseDefinitions.Stem;
        case "TRIMBLOCK": return state.databaseDefinitions.Trimblock;
        default: return null;
    }
};