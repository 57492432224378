import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IMUnits, IMUnitsState } from "./model";

declare global {
    interface IStoreState {
        mUnits: IMUnitsState;
    }
}

const mUnits = createSlice({
    name: "mUnits",
    initialState: { units: null, types: null } as IMUnitsState,
    reducers: {
        set: (_state, { payload }: PayloadAction<IMUnits>) => ({
            types: _.chain(payload)
                .map(({ unitType }) => unitType)
                .uniq()
                .orderBy((type) => type.toLocaleLowerCase())
                .value(),
            units: payload,
        }),
    },
});

export const actionsCreators = {
    ...mUnits.actions,
};

export default {
    [mUnits.name]: mUnits.reducer,
};