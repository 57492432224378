import { CSS, styled } from "@comact/crc/modules/kit";
import { IKpiZoneValue } from "js/kpis/kpiObjectives";
import tinycolor from "tinycolor2";

export interface IZoneBackgroundProps {
    readonly zone: IKpiZoneValue;
}
export const ZoneBackground = styled.div<IZoneBackgroundProps>`
    position: relative;
    border-radius: ${CSS.borderRadius}px;
    height: 100%;
    background-image: ${(props) => {
        switch (props.zone) {
            case "error": return (
                `linear-gradient(
                    0deg,
                    ${tinycolor(CSS.colors.red).setAlpha(0.3).toRgbString()} 0%,
                    ${tinycolor(CSS.colors.red).setAlpha(0.05).toRgbString()} 50%,
                    ${tinycolor(CSS.colors.red).darken(20).setAlpha(0).toRgbString()} 90%
                )`
            );
            case "warn": return (
                `linear-gradient(
                    0deg,
                    ${tinycolor(CSS.colors.yellow).darken(5).setAlpha(0.2).toRgbString()} 0%,
                    ${tinycolor(CSS.colors.yellow).darken(10).setAlpha(0.05).toRgbString()} 50%,
                    ${tinycolor(CSS.colors.yellow).darken(20).setAlpha(0).toRgbString()} 90%
                )`
            );
            case "normal": return (
                `linear-gradient(
                    0deg,
                    ${tinycolor(CSS.colors.green).darken(5).setAlpha(0.3).toRgbString()} 0%,
                    ${tinycolor(CSS.colors.green).darken(10).setAlpha(0.05).toRgbString()} 50%,
                    ${tinycolor(CSS.colors.green).darken(20).setAlpha(0).toRgbString()} 90%
                )`
            );
            default:
                return "initial";
        }
    }}
`;