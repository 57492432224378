import _ from "lodash";

export interface IDensityBySpecies {
    speciesId: string;
    density: number;
}

export interface IDensityBySpeciesServer {
    densityBySpecies: { [speciesId: string]: number; };
}

export const convertFromServer = (data: IDensityBySpeciesServer): IDensityBySpecies[] => (
    _.chain(data.densityBySpecies)
        .map((density, speciesId) => ({ density, speciesId }))
        .orderBy(({ speciesId }) => parseInt(speciesId, 10))
        .value()
);

export const convertToServer = (data: IDensityBySpecies[]): IDensityBySpeciesServer => ({
    densityBySpecies: _.reduce(data, (acc, { speciesId, density }) => {
        acc[speciesId] = density;
        return acc;
    }, {}),
});

export const newDensity = (): IDensityBySpecies => ({
    speciesId: "0",
    density: 0,
});

export type IDensityBySpeciesErrors = {
    speciesDensity?: { [index: number]: string; };
    speciesDuplicates?: { [index: number]: string; };
};