import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IKpiObjective } from "../kpiObjectives";
import { IKpiPattern } from "../kpiPatterns";

declare global {
    interface IStoreState {
        kpiPatternEditor: IKpiPattern;
    }
}

const kpiPatternEditor = createSlice({
    name: "kpiPatternEditor",
    initialState: null as IKpiPattern,
    reducers: {
        set: (state, { payload }: PayloadAction<IKpiPattern>) => Utils.slices.deepImmerUpdate(state, payload),
        delete: (_state) => null,
        patchOne: (state, { payload }: PayloadAction<Partial<IKpiPattern>>) => ({ ...state, ...payload }),
        changePrecision: (state, { payload }: PayloadAction<number>) => {
            const precision = payload;
            if (payload < state.precision) { // Precision has been decrease, we need to round other values
                _.forEach(state.kpiDefinitions, (kpiDef) => {
                    _.forEach(kpiDef.objectives, (objective: IKpiObjective) => {
                        objective.min = _.round(objective.min, payload);
                        objective.max = _.round(objective.max, payload);
                        objective.target = _.round(objective.target, payload);
                        objective.zones.map((zone) => {
                            zone.min = _.round(zone.min, payload);
                            zone.max = _.round(zone.max, payload);
                        });
                    });
                });
            }
            state.precision = precision;
        },
    },
});

export const actionsCreators = {
    ...kpiPatternEditor.actions,
};

export default {
    [kpiPatternEditor.name]: kpiPatternEditor.reducer,
};