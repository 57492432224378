import { Cache } from "@comact/crc";
import { getDatabaseDefinitions } from "js/databaseDefinitions/mocks";
import { IKpiDefinition } from "js/kpis/kpiDefinitions";
import { IKpiObjective, IKpiZone, IKpiZoneValueList } from "js/kpis/kpiObjectives";
import { mockMUnits } from "js/mUnits/mocks";
import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { machineModels } from "../../node";
import { getAllNodes } from "../../node/mocks";
import { IKpiPattern } from "./model";

const rand = createSeeder();

const unitList = _.map(mockMUnits("en"), ({ id }) => id);

interface IGetKpiObjective extends Partial<IKpiObjective> { id: string; }

const getObjective = (partialData: IGetKpiObjective): IKpiObjective => {
    const min = partialData.min || rand.intBetween(-100, 0);
    const max = partialData.max || rand.intBetween(0, 100);
    const zonesCount = rand.intBetween(0, 5);
    return {
        min,
        max,
        title: partialData.id,
        zones: ((): IKpiZone[] => {
            const step = (max - min) / zonesCount;
            return _.times(zonesCount, (index) => ({
                min: _.round(min + index * step, 2),
                max: _.round(min + (index + 1) * step, 2),
                value: IKpiZoneValueList[rand.intBetween(0, IKpiZoneValueList.length - 1)],
            }));
        })(),
        ...partialData,
    };
};

const getObjectives = (count: number): { [id: string]: IKpiObjective; } => {
    const objectives = _.keyBy(
        _.times(count, (index) => getObjective({ id: "BATCH" + index }))
        , ({ id }) => id
    );
    objectives.default = getObjective({ id: "default" }); // FIXME: Probablement faire les "par défaut" dans le modèle
    return objectives;
};

interface IGetDefinition extends Partial<IKpiDefinition> { id: string; }

const getKpiDefinition = (partialData: IGetDefinition): IKpiDefinition => {
    const { id } = partialData;
    return {
        title: { values: { fr: id, en: id, fi: id, pt: id } },
        shortTitle: { values: { fr: id, en: id, fi: id, pt: id } },
        objectives: getObjectives(rand.intBetween(1, 4)),
        ...partialData,
    };
};

const getKpiDefinitions = (patternId: string, count: number) => (
    _.keyBy(
        _.times(count, (index) => getKpiDefinition({
            id: "Definition " + (index + 1) + "[" + patternId + "]",
        }))
        , ({ id }) => id
    )
);

export const getKpiPattern = (id: string, nodeId: string): IKpiPattern => {
    rand.seed("kpiPattern_" + id);
    const databases = getDatabaseDefinitions();
    const database = _.keys(databases)[rand.intBetween(0, _.size(databases) - 1)];
    const databaseDefinition = databases[database];
    const collection = !_.isEmpty(databaseDefinition.tags);
    const unit = unitList[rand.intBetween(0, unitList.length - 1)];
    return {
        id,
        formula: "Formula to fake for " + id,
        filterFormula: "Filter formula for " + id,
        filterRatio: false,
        order: null,
        modificationDate: rand.intBetween((new Date("2023-01-01")).getTime(), (new Date("2024-01-01")).getTime()),
        title: { values: { en: id + " En", fr: id + " Fr" } },
        uniqueName: formatIdURLFriendly(id),
        machinesModel: machineModels[rand.intBetween(0, _.size(machineModels) - 1)],
        machinesScanner: "0",
        groups: collection ? ["$" + database + "." + databaseDefinition.tags[0]] : [],
        groupRatio: false,
        precision: 3,
        unitType: unit,
        unitLabel: null,
        collection,
        tags: [],
        useNumberContext: false,
        database,
        objectiveTimeBased: false,
        objectiveTimeUnit: "HOURS",
        kpiDefinitions: {
            ["default"]: getKpiDefinition({ id: "default" }),
            ...getKpiDefinitions(id, rand.intBetween(0, 5)),
        },
        system: rand.intBetween(0, 3) == 3,
        nodeIds: [nodeId],
    };
};

const formatIdURLFriendly = (id: string) => _.kebabCase(id);

export const getKpiPatterns = () => (
    Cache.remember("allKpiPatterns_", () => {
        const nodes = getAllNodes();
        return _.map(nodes, (node, index) => getKpiPattern(`Pattern ${index + 1}`, node.id)); // We want all the nodes to have one KPI
    })
);