import * as CryptoJs from "crypto-js";
export interface INonceReply {
    nonce: string;
    realm: string;
}

/**
 * Voir la section "Génération d’un nonce pour calculer une clé d’authentification" de la documentation.
 * https://bidgroup.atlassian.net/wiki/spaces/TD/pages/1836875777/Liste+des+fonctionnalit+s+possibles+via+requ+tes+de+l+api+camera
 */
export const authDigest = ({ username, password, nonce: { nonce, realm } }: { username: string; password: string; nonce: INonceReply; }): string => {
    const digest = CryptoJs.MD5(`${username}:${realm}:${password}`);
    const partialHash = CryptoJs.MD5("GET:");
    const simplifiedHash = CryptoJs.MD5(`${digest}:${nonce}:${partialHash}`);
    return btoa(`${username}:${nonce}:${simplifiedHash}`);
};
