import { CatchErrors, DataConfigModule, MenuModule, UserModule, routing, t, triggerWindowResize, useCurrentPage, useIntervalAjaxRequest, useMapDispatch, useMapState } from "@comact/crc";
import { LoadingSpinner, PageForbidden, PageNotFound } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { IMachineCodecNode, NodesModule } from "../";
import { FeatureFlagsModule } from "../../featureFlags";
import { PageNodeContext } from "../hooks";
import { IPerspective } from "../perspectives/model";
import * as perspectivesSelectors from "../perspectives/selectors";
import { getPerspectiveAccessState } from "../perspectives/selectors";
import * as selectors from "../selectors";
import { HeaderBreadcrumb } from "./HeaderBreadcrumb";
export { usePageNodeContext } from "../hooks";

const PageLogin = React.lazy(() => import("@comact/crc/modules/user/components/PageLogin"));
const PageOrganizationSelector = React.lazy(() => import("@comact/crc/modules/user/components/PageOrganizationSelector"));

// eslint-disable-next-line complexity
export const PageNode = React.memo<{ perspective: IPerspective; children: React.ReactNode; }>(({ perspective, children }) => {
    const { nodeId } = useParams<{ nodeId: string | null; }>();

    const { fetchAllNodes, getNodeConnexionStatuses } = useMapDispatch((dispatch) => NodesModule.getRequests(dispatch), [nodeId]);
    const isLoggedIn = useMapState((state) => UserModule.selectors.isLoggedIn(state));
    useIntervalAjaxRequest(() => fetchAllNodes(), 10 * 1000);
    useIntervalAjaxRequest(() => isLoggedIn && getNodeConnexionStatuses(), 10 * 1000, [isLoggedIn]);

    const { needToChooseOrganization, organizationNodeId } = useMapState((state) => ({
        needToChooseOrganization: !UserModule.selectors.getUserOrganizationId(state),
        organizationNodeId: NodesModule.selectors.getCurrentOrganizationNodeId(state),
    }));

    useIntervalAjaxRequest((dispatch) => organizationNodeId && (
        FeatureFlagsModule.getRequests(dispatch).getFeatureFlags(organizationNodeId)
    ), 60 * 1000, [organizationNodeId]);

    const { isMaster, hasTwin } = useMapState((state) => DataConfigModule.selectors.getAllSystem(state));
    const node = useMapState((state) => selectors.getNodeById(state, nodeId), [nodeId]);
    const nodesLoaded = useMapState((state) => !_.isEmpty(NodesModule.selectors.getAllNodes(state)));

    const menuActions = useMapDispatch((dispatch) => MenuModule.getActions(dispatch));

    const [overridePageName, _setPageName] = React.useState<string>(null);

    const isMountRef = React.useRef<boolean>(false);
    React.useEffect(() => {
        isMountRef.current = true;
        return () => { isMountRef.current = false; };
    }, []);

    const nodeActions = useMapDispatch((dispatch) => NodesModule.getActions(dispatch));

    const currentNodeId = useMapState((state) => NodesModule.selectors.getCurrentNodeId(state)); // set in state
    const nodeMismatch = currentNodeId != nodeId;

    const currentMillNodeId = useMapState((state) => NodesModule.selectors.getCurrentMillNodeId(state)); // set in state

    const { millNode, millNodeId } = useMapState((state) => { // calculated from current node
        const n = selectors.getMillNode(state, nodeId);
        return { millNode: n, millNodeId: n?.id || null };
    }, [nodeId]);
    const millMismatch = currentMillNodeId != millNodeId; // when the mill change...

    const currentLocationNodeId = useMapState((state) => state.currentLocationNodeId); // set in state

    const locationNodeId = useMapState((state) => NodesModule.selectors.getLocationNode(state, nodeId)?.id || null, [nodeId]); // calculated from current node
    const locationMismatch = currentLocationNodeId != locationNodeId; // when the mill change...

    const { machineNode, machineNodeId } = useMapState((state) => { // calculated from current node
        const n = selectors.getNodeFirstParentOfType<IMachineCodecNode>(state, nodeId, "machineCodec", true); // when the machine change...
        return { machineNode: n, machineNodeId: n?.id || null };
    }, [nodeId]);

    // Set/unset currentNodeId
    React.useEffect(() => {
        if (nodeMismatch) { // node has change in redux state
            nodeActions.setCurrentNodeId(nodeId || null);
        }
        if (nodesLoaded) { // be sure at least one node has been fetch from the server or the next codes won't work on first page load
            if (millMismatch) { // mill node has change in redux state
                nodeActions.setCurrentMillNodeId(millNodeId);
            }
            if (locationMismatch) {
                nodeActions.setCurrentLocationNodeId(locationNodeId);
            }
        }
    }, [nodeActions, nodeId, nodesLoaded, currentMillNodeId, millNodeId, millMismatch, machineNodeId, nodeMismatch, locationMismatch, locationNodeId]);

    const hasMismatch = nodeMismatch || millMismatch;

    // Create perspective menu
    const getNodePerpectivesMenu = React.useMemo(() => perspectivesSelectors.makeGetNodePerpectivesMenuAndHeader(), []);
    const perspectivesMenu = useMapState((state) => getNodePerpectivesMenu({ state, nodeId }), [nodeId]);
    React.useEffect(() => {
        if (!perspective || !nodesLoaded) return undefined;
        menuActions.updateSome(perspectivesMenu);
        const trig = setTimeout(() => triggerWindowResize(), 1); // we need to trigger a resize after changing the menu
        return () => {
            clearTimeout(trig);
            menuActions.removeByCategory("perspective"); // remove menu when unmounting
        };
    }, [menuActions, nodeId, perspective, getNodePerpectivesMenu, perspectivesMenu, nodesLoaded]);

    const perspectivePermissionState = useMapState((state) => (
        getPerspectiveAccessState(state, perspective, node?.templateName, millNodeId)
    ), [perspective, node?.templateName, millNodeId]);

    const setPageName = React.useCallback((name: string) => {
        // We can't use React.useState (setState()) synchronously because it cause some console bugs..
        // The set timeout is here to prevent the following error : "Warning: Cannot update a component while rendering a different component". To locate the bad setState() call...
        setTimeout(() => {
            if (isMountRef.current) _setPageName(name);
        }, 0);
    }, []);

    const pageName = perspectivePermissionState == "allowed"
        ? overridePageName || t(`pages.menu.perspective.${perspective}`)
        : "";

    const pageWindowName = perspectivePermissionState == "allowed"
        ? pageName + " - " + (node?.name || t("node.root"))
        : "";

    useCurrentPage(pageName, pageWindowName);

    if (!isMaster) {
        return (
            <PageNodeContext.Provider value={{ nodeId: null, node: null, locationNodeId: null, millNode: null, millNodeId: null, machineNode: null, machineNodeId: null, perspective, setPageName }}>
                {children}
            </PageNodeContext.Provider>
        );
    }

    if (!routing.currentPath.startsWith("/login")) { // skip some tests if on login page
        if (nodeId && !node) return <LoadingSpinner.Absolute />;
        if (hasTwin && !nodesLoaded) return <LoadingSpinner.Absolute />;
    }
    if (hasMismatch) return <LoadingSpinner.Absolute />;
    if (perspectivePermissionState == "notReady") return <LoadingSpinner.Absolute />;
    if (perspectivePermissionState == "notFound") return <PageNotFound />;
    if (perspectivePermissionState == "denied") return <PageForbidden />;
    if (process.env.EXEC_MODE == "icp") {
        if (!isLoggedIn) return <PageLogin useRedirect={false} />;
        if (needToChooseOrganization) return <PageOrganizationSelector />;
    }
    return (
        <PageNodeContext.Provider value={{ nodeId, node, locationNodeId, millNode, millNodeId: millNode?.id, machineNode, machineNodeId: machineNode?.id, perspective, setPageName }}>
            {(process.env.EXEC_MODE == "icp" || millNode?.id != node?.id || perspective == "details") && (
                <HeaderBreadcrumb />
            )}
            <React.Fragment key={nodeId}>{/* Be sure that if the same Page component is loaded twice it will rerender */}
                <CatchErrors>
                    {children}
                </CatchErrors>
            </React.Fragment>
        </PageNodeContext.Provider>
    );
});