import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReportBrowserConfiguration } from "./model";

declare global {
    interface IStoreState {
        reportBrowserConfiguration: IReportBrowserConfiguration;
    }
}

const reportBrowserConfiguration = createSlice({
    name: "reportBrowserConfiguration",
    initialState: {
        status: "NOT_READY",
        token: null,
        url: "",
        downloadInfo: undefined,
    } as IReportBrowserConfiguration,
    reducers: {
        patchOne: (state, { payload }: PayloadAction<Partial<IReportBrowserConfiguration>>) => ({ ...state, ...payload }),
    },
});

export const actionsCreators = {
    ...reportBrowserConfiguration.actions,
};

export default {
    [reportBrowserConfiguration.name]: reportBrowserConfiguration.reducer,
};
