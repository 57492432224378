import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { contextTypes, IContextDefinition } from "./model";

const rand = createSeeder("mock-dataset-context-definitions");

export const getContextDefinitions = (): IContextDefinition[] => (
    _.times(20, (i) => {
        const type = contextTypes[rand.intBetween(0, contextTypes.length - 1)];
        return {
            id: i.toString(),
            name: {
                values: {
                    en: `Context ${i}`,
                    fr: `Contexte ${i}`,
                    fi: `Konteksti ${i}`,
                    pt: `Contexto ${i}`,
                },
            },
            type,
            length: 10,
            limit: type == "NUMBER" ? 100 : 0,
            unit: "MINUTES",
            refreshRate: 1,
            refreshRateUnit: "MINUTES",
            modificationStamp: Date.now() - rand.intBetween(99999, 999999),
            refreshStamp: Date.now() - rand.intBetween(1000, 99999),
            systemDashboardDefault: i == 0,
            uniqueReferenceName: rand.intBetween(99999, 999999).toString(),
            systemGenerated: i < 4,
            avgRefreshTime: 0,
        };
    })
);
