import { IMUnit } from "./model";

export const mockMUnits = (locale: string): IMUnit[] => mockMUnitsData[locale];

const mockMUnitsData = {
    fr: [{
        id: "NANOMETERS",
        index: 0,
        unitType: "Longueur",
        unitName: "nanomètre",
        unitLabel: "nanomètre",
        unitSymbol: "nm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MICROMETERS",
        index: 1,
        unitType: "Longueur",
        unitName: "micromètre",
        unitLabel: "micromètre",
        unitSymbol: "µm",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MILLIMETERS",
        index: 2,
        unitType: "Longueur",
        unitName: "millimètre",
        unitLabel: "millimètre",
        unitSymbol: "mm",
        conversionFactor: 1000.0,
        nbDecimal: 1,
    },
    {
        id: "CENTIMETERS",
        index: 3,
        unitType: "Longueur",
        unitName: "centimètre",
        unitLabel: "centimètre",
        unitSymbol: "cm",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "DECIMETERS",
        index: 4,
        unitType: "Longueur",
        unitName: "décimètre",
        unitLabel: "décimètre",
        unitSymbol: "dm",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "METERS",
        index: 5,
        unitType: "Longueur",
        unitName: "mètre",
        unitLabel: "mètre",
        unitSymbol: "m",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "HECTOMETERS",
        index: 6,
        unitType: "Longueur",
        unitName: "hectomètre",
        unitLabel: "hectomètre",
        unitSymbol: "hm",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "KILOMETERS",
        index: 7,
        unitType: "Longueur",
        unitName: "kilomètre",
        unitLabel: "kilomètre",
        unitSymbol: "km",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "MILLS",
        index: 0,
        unitType: "Longueur",
        unitName: "millième de pouce",
        unitLabel: "millième de pouce",
        unitSymbol: "1/1000 po",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "INCHES",
        index: 1,
        unitType: "Longueur",
        unitName: "pouce",
        unitLabel: "pouce",
        unitSymbol: "po",
        conversionFactor: 1000.0,
        nbDecimal: 3,
    },
    {
        id: "FEET",
        index: 2,
        unitType: "Longueur",
        unitName: "pied",
        unitLabel: "pied",
        unitSymbol: "pi",
        conversionFactor: 12.0,
        nbDecimal: 0,
    },
    {
        id: "YARDS",
        index: 3,
        unitType: "Longueur",
        unitName: "verge",
        unitLabel: "verge",
        unitSymbol: "vg",
        conversionFactor: 3.0,
        nbDecimal: 0,
    },
    {
        id: "MILES",
        index: 4,
        unitType: "Longueur",
        unitName: "mille",
        unitLabel: "mille",
        unitSymbol: "mi",
        conversionFactor: 1760.0,
        nbDecimal: 0,
    },
    {
        id: "NAUTICAL_MILES",
        index: 5,
        unitType: "Longueur",
        unitName: "mille nautique",
        unitLabel: "mille nautique",
        unitSymbol: "NM",
        conversionFactor: 1.1516,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILLIMETERS",
        index: 0,
        unitType: "Aire",
        unitName: "millimètre carré",
        unitLabel: "millimètre carré",
        unitSymbol: "mm2",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_CENTIMETERS",
        index: 1,
        unitType: "Aire",
        unitName: "centimètre carré",
        unitLabel: "centimètre carré",
        unitSymbol: "cm2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_DECIMETERS",
        index: 2,
        unitType: "Aire",
        unitName: "décimètre carré",
        unitLabel: "décimètre carré",
        unitSymbol: "dm2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_METERS",
        index: 3,
        unitType: "Aire",
        unitName: "mètre carré",
        unitLabel: "mètre carré",
        unitSymbol: "m2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_KILOMETERS",
        index: 4,
        unitType: "Aire",
        unitName: "kilomètre carré",
        unitLabel: "kilomètre carré",
        unitSymbol: "km2",
        conversionFactor: 1000000.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILS",
        index: 0,
        unitType: "Aire",
        unitName: "millième de pouce carré",
        unitLabel: "millième de pouce carré",
        unitSymbol: "1/1000 po2",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_INCHES",
        index: 1,
        unitType: "Aire",
        unitName: "pouce carré",
        unitLabel: "pouce carré",
        unitSymbol: "po2",
        conversionFactor: 1000000.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_FEET",
        index: 2,
        unitType: "Aire",
        unitName: "pied carré",
        unitLabel: "pied carré",
        unitSymbol: "pi2",
        conversionFactor: 144.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_YARDS",
        index: 3,
        unitType: "Aire",
        unitName: "verge carré",
        unitLabel: "verge carré",
        unitSymbol: "vg2",
        conversionFactor: 9.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILES",
        index: 4,
        unitType: "Aire",
        unitName: "mille carré",
        unitLabel: "mille carré",
        unitSymbol: "mi2",
        conversionFactor: 3097600.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_MILLIMETERS",
        index: 0,
        unitType: "Volume",
        unitName: "millimètre cube",
        unitLabel: "millimètre cube",
        unitSymbol: "mm3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_CENTIMETERS",
        index: 1,
        unitType: "Volume",
        unitName: "centimètre cube",
        unitLabel: "centimètre cube",
        unitSymbol: "cm3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_DECIMETERS",
        index: 2,
        unitType: "Volume",
        unitName: "décimètre cube",
        unitLabel: "décimètre cube",
        unitSymbol: "dm3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS",
        index: 3,
        unitType: "Volume",
        unitName: "mètre cube",
        unitLabel: "mètre cube",
        unitSymbol: "m3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_INCHES",
        index: 0,
        unitType: "Volume",
        unitName: "pouce cube",
        unitLabel: "pouce cube",
        unitSymbol: "po3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET",
        index: 1,
        unitType: "Volume",
        unitName: "pied cube",
        unitLabel: "pied cube",
        unitSymbol: "pi3",
        conversionFactor: 1728.0,
        nbDecimal: 0,
    },
    {
        id: "BOARD_FEET",
        index: 2,
        unitType: "Volume",
        unitName: "pied mesure de planche",
        unitLabel: "pied mesure de planche",
        unitSymbol: "pmp",
        conversionFactor: 0.08333333333333333,
        nbDecimal: 0,
    },
    {
        id: "MBF",
        index: 3,
        unitType: "Volume",
        unitName: "mpmp",
        unitLabel: "mpmp",
        unitSymbol: "mbf",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "SOLID_OUT_PERCENTAGE",
        index: 0,
        unitType: "Récupération",
        unitName: "récupération solide",
        unitLabel: "récupération solide",
        unitSymbol: "%",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FBM_PER_CUBIC_FEET",
        index: 0,
        unitType: "Récupération",
        unitName: "pmp par pied cube",
        unitLabel: "pmp par pied cube",
        unitSymbol: "pmp/pi3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FBM_PER_CUBIC_METER",
        index: 0,
        unitType: "Récupération",
        unitName: "pmp par mètre cube",
        unitLabel: "pmp par mètre cube",
        unitSymbol: "pmp/m3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET_PER_MBF",
        index: 0,
        unitType: "Récupération",
        unitName: "pied cube par mpmp",
        unitLabel: "pied cube par mpmp",
        unitSymbol: "pi3/mpmp",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_MBF",
        index: 0,
        unitType: "Récupération",
        unitName: "mètre cube par mpmp",
        unitLabel: "mètre cube par mpmp",
        unitSymbol: "m3/mpmp",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_CUBIC_FEET",
        index: 0,
        unitType: "Récupération",
        unitName: "mètre cube par pied cube",
        unitLabel: "mètre cube par pied cube",
        unitSymbol: "m3/pi3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET_PER_CUBIC_METERS",
        index: 0,
        unitType: "Récupération",
        unitName: "pied cube par mètre cube",
        unitLabel: "pied cube par mètre cube",
        unitSymbol: "pi3/m3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_INCHES_PER_HOUR",
        index: 0,
        unitType: "Volume",
        unitName: "pouce cube par heure",
        unitLabel: "pouce cube par heure",
        unitSymbol: "po3/h",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARD_FEET_PER_HOUR",
        index: 1,
        unitType: "Volume",
        unitName: "pied mesure de planche par heure",
        unitLabel: "pied mesure de planche par heure",
        unitSymbol: "pmp/h",
        conversionFactor: 144.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_MILLIMETERS_PER_HOUR",
        index: 0,
        unitType: "Volume",
        unitName: "millimètre cube par heure",
        unitLabel: "millimètre cube par heure",
        unitSymbol: "mm3/h",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_CENTIMETERS_PER_HOUR",
        index: 1,
        unitType: "Volume",
        unitName: "centimètre cube par heure",
        unitLabel: "centimètre cube par heure",
        unitSymbol: "cm3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_DECIMETERS_PER_HOUR",
        index: 2,
        unitType: "Volume",
        unitName: "décimètre cube par heure",
        unitLabel: "décimètre cube par heure",
        unitSymbol: "dm3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_HOUR",
        index: 3,
        unitType: "Volume",
        unitName: "mètre cube par heure",
        unitLabel: "mètre cube par heure",
        unitSymbol: "m3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_SECOND",
        index: 0,
        unitType: "Vitesse linéaire",
        unitName: "mètres par seconde",
        unitLabel: "mètres par seconde",
        unitSymbol: "m/s",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_MINUTE",
        index: 1,
        unitType: "Vitesse linéaire",
        unitName: "mètres par minute",
        unitLabel: "mètres par minute",
        unitSymbol: "m/min",
        conversionFactor: 0.01666666667,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_HOUR",
        index: 2,
        unitType: "Vitesse linéaire",
        unitName: "mètres par heure",
        unitLabel: "mètres par heure",
        unitSymbol: "m/h",
        conversionFactor: 0.01666666667,
        nbDecimal: 0,
    },
    {
        id: "KILOMETER_PER_HOUR",
        index: 3,
        unitType: "Vitesse linéaire",
        unitName: "kilomètres à l'heure",
        unitLabel: "kilomètres à l'heure",
        unitSymbol: "km/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_SECOND",
        index: 0,
        unitType: "Vitesse linéaire",
        unitName: "pieds par seconde",
        unitLabel: "pieds par seconde",
        unitSymbol: "pi/s",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_MINUTE",
        index: 1,
        unitType: "Vitesse linéaire",
        unitName: "pieds par minute",
        unitLabel: "pieds par minute",
        unitSymbol: "pi/min",
        conversionFactor: 0.016666666667,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_HOUR",
        index: 2,
        unitType: "Vitesse linéaire",
        unitName: "pieds à l'heure",
        unitLabel: "pieds à l'heure",
        unitSymbol: "pi/h",
        conversionFactor: 0.016666666667,
        nbDecimal: 0,
    },
    {
        id: "MILE_PER_HOUR",
        index: 3,
        unitType: "Vitesse linéaire",
        unitName: "miles à l'heure",
        unitLabel: "miles à l'heure",
        unitSymbol: "mi/h",
        conversionFactor: 5280.0,
        nbDecimal: 0,
    },
    {
        id: "LOGS_PER_MINUTE",
        index: 0,
        unitType: "Vitesse",
        unitName: "billes par minute",
        unitLabel: "billes par minute",
        unitSymbol: "billes/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARDS_PER_MINUTE",
        index: 0,
        unitType: "Vitesse",
        unitName: "planches par minute",
        unitLabel: "planches par minute",
        unitSymbol: "planches/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CANTS_PER_MINUTE",
        index: 0,
        unitType: "Vitesse",
        unitName: "équarris par minute",
        unitLabel: "équarris par minute",
        unitSymbol: "équarris/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "STEMS_PER_MINUTE",
        index: 0,
        unitType: "Vitesse",
        unitName: "arbres par minute",
        unitLabel: "arbres par minute",
        unitSymbol: "arbres/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUGS_PER_MINUTE",
        index: 0,
        unitType: "Vitesse",
        unitName: "taquets par minute",
        unitLabel: "taquets par minute",
        unitSymbol: "taquets/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARDS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "planches",
        unitLabel: "planches",
        unitSymbol: "planches",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOGS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "billes",
        unitLabel: "billes",
        unitSymbol: "billes",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CANTS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "équarris",
        unitLabel: "équarris",
        unitSymbol: "équarris",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "STEMS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "arbres",
        unitLabel: "arbres",
        unitSymbol: "arbres",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUGS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "taquets",
        unitLabel: "taquets",
        unitSymbol: "taquets",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUMBER",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "bois",
        unitLabel: "bois",
        unitSymbol: "bois",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PRODUCTS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "produits",
        unitLabel: "produits",
        unitSymbol: "produits",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ROTATION_PER_MINUTE",
        index: 0,
        unitType: "Vitesse de rotation",
        unitName: "rotations par minute",
        unitLabel: "rotations par minute",
        unitSymbol: "rpm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "NANOSECONDS",
        index: 0,
        unitType: "Temps",
        unitName: "nanoseconde",
        unitLabel: "nanoseconde",
        unitSymbol: "ns",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MICROSECONDS",
        index: 1,
        unitType: "Temps",
        unitName: "microseconde",
        unitLabel: "microseconde",
        unitSymbol: "us",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MILLISECONDS",
        index: 2,
        unitType: "Temps",
        unitName: "milliseconde",
        unitLabel: "milliseconde",
        unitSymbol: "ms",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "SECONDS",
        index: 3,
        unitType: "Temps",
        unitName: "seconde",
        unitLabel: "seconde",
        unitSymbol: "s",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MINUTES",
        index: 4,
        unitType: "Temps",
        unitName: "minute",
        unitLabel: "minute",
        unitSymbol: "min",
        conversionFactor: 60.0,
        nbDecimal: 0,
    },
    {
        id: "HOURS",
        index: 5,
        unitType: "Temps",
        unitName: "heure",
        unitLabel: "heure",
        unitSymbol: "h",
        conversionFactor: 60.0,
        nbDecimal: 0,
    },
    {
        id: "DAYS",
        index: 6,
        unitType: "Temps",
        unitName: "jour",
        unitLabel: "jour",
        unitSymbol: "j",
        conversionFactor: 24.0,
        nbDecimal: 0,
    },
    {
        id: "WEEKS",
        index: 7,
        unitType: "Temps",
        unitName: "semaine",
        unitLabel: "semaine",
        unitSymbol: "sem",
        conversionFactor: 7.0,
        nbDecimal: 0,
    },
    {
        id: "MONTHS",
        index: 0,
        unitType: "Temps",
        unitName: "mois",
        unitLabel: "mois",
        unitSymbol: "mois",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "YEARS",
        index: 0,
        unitType: "Temps",
        unitName: "année",
        unitLabel: "année",
        unitSymbol: "année",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "HORSEPOWER",
        index: 0,
        unitType: "Puissance",
        unitName: "chevaux-vapeur",
        unitLabel: "chevaux-vapeur",
        unitSymbol: "ch",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "INCH_PER_FOOT",
        index: 0,
        unitType: "Pente",
        unitName: "pouce/pied",
        unitLabel: "pouce/pied",
        unitSymbol: "po/pi",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MILLIMETER_PER_METER",
        index: 0,
        unitType: "Pente",
        unitName: "millimètre/mètre",
        unitLabel: "millimètre/mètre",
        unitSymbol: "mm/m",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PERCENTAGE",
        index: 0,
        unitType: "Proportion",
        unitName: "pourcentage",
        unitLabel: "pourcentage",
        unitSymbol: "%",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ABS",
        index: 0,
        unitType: "Proportion",
        unitName: "abs",
        unitLabel: "abs",
        unitSymbol: "",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "DOLLARS",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "dollars",
        unitLabel: "dollars",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "DEGREE",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "degré",
        unitLabel: "degré",
        unitSymbol: "°",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "EFFICIENCY",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "mètre cube par million de pied mesure de planche",
        unitLabel: "mètre cube par million de pied mesure de planche",
        unitSymbol: "m3/mpmp",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PIXEL",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "pixels",
        unitLabel: "pixels",
        unitSymbol: "px",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ENCODER_RATIO",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "Pouce/pulse",
        unitLabel: "Pouce/pulse",
        unitSymbol: "po/pul",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUTTING_UNIT",
        index: 0,
        unitType: "Aire",
        unitName: "unité débit",
        unitLabel: "unité débit",
        unitSymbol: "ud",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BYTES",
        index: 0,
        unitType: "Binaire",
        unitName: "Octet",
        unitLabel: "Octet",
        unitSymbol: "o",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "KILOBYTES",
        index: 1,
        unitType: "Binaire",
        unitName: "Kilooctet",
        unitLabel: "Kilooctet",
        unitSymbol: "Ko",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "MEGABYTES",
        index: 2,
        unitType: "Binaire",
        unitName: "Megaoctet",
        unitLabel: "Megaoctet",
        unitSymbol: "Mo",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "GIGABYTES",
        index: 3,
        unitType: "Binaire",
        unitName: "Gigaoctet",
        unitLabel: "Gigaoctet",
        unitSymbol: "Go",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "TERABYTES",
        index: 4,
        unitType: "Binaire",
        unitName: "Teraoctet",
        unitLabel: "Teraoctet",
        unitSymbol: "To",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "DOLLARS_IMPERIAL",
        index: 0,
        unitType: "Prix",
        unitName: "dollars imperial",
        unitLabel: "dollars imperial",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "DOLLARS_METRIC",
        index: 0,
        unitType: "Prix",
        unitName: "dollars metric",
        unitLabel: "dollars metric",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "VALUE_PER_CUBIC_INCHES",
        index: 0,
        unitType: "Prix par",
        unitName: "prix/po3",
        unitLabel: "prix/po3",
        unitSymbol: "/po3",
        conversionFactor: null,
        nbDecimal: 8,
    },
    {
        id: "VALUE_PER_CUBIC_FEET",
        index: 1,
        unitType: "Prix par",
        unitName: "prix/pi3",
        unitLabel: "prix/pi3",
        unitSymbol: "/pi3",
        conversionFactor: 5.787037037037037E-4,
        nbDecimal: 3,
    },
    {
        id: "VALUE_PER_CUBIC_METERS",
        index: 0,
        unitType: "Prix par",
        unitName: "prix/m3",
        unitLabel: "prix/m3",
        unitSymbol: "/m3",
        conversionFactor: null,
        nbDecimal: 3,
    },
    {
        id: "POUNDS_PER_CUBIC_FEET",
        index: 0,
        unitType: "Poid",
        unitName: "livres par pied cube",
        unitLabel: "livres par pied cube",
        unitSymbol: "lb/pi3",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "KG_PER_CUBIC_METER",
        index: 0,
        unitType: "Poid",
        unitName: "kilogrammes par mètre cube",
        unitLabel: "kilogrammes par mètre cube",
        unitSymbol: "kg/m3",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "POUNDS",
        index: 0,
        unitType: "Poid",
        unitName: "livres",
        unitLabel: "livres",
        unitSymbol: "lb",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "TONS",
        index: 1,
        unitType: "Poid",
        unitName: "tonnes",
        unitLabel: "tonnes",
        unitSymbol: "t",
        conversionFactor: 2000.0,
        nbDecimal: 0,
    },
    {
        id: "KILOS",
        index: 0,
        unitType: "Poid",
        unitName: "kilogrammes",
        unitLabel: "kilogrammes",
        unitSymbol: "kg",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "METRIC_TONS",
        index: 1,
        unitType: "Poid",
        unitName: "tonnes métriques",
        unitLabel: "tonnes métriques",
        unitSymbol: "t",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "PASCAL",
        index: 0,
        unitType: "Pression",
        unitName: "Pascal",
        unitLabel: "Pascal",
        unitSymbol: "Pa",
        conversionFactor: null,
        nbDecimal: 5,
    },
    {
        id: "HECTOPASCAL",
        index: 1,
        unitType: "Pression",
        unitName: "Hectopascal",
        unitLabel: "Hectopascal",
        unitSymbol: "hPa",
        conversionFactor: 100.0,
        nbDecimal: 5,
    },
    {
        id: "KILOPASCAL",
        index: 2,
        unitType: "Pression",
        unitName: "Kilopascal",
        unitLabel: "Kilopascal",
        unitSymbol: "kPa",
        conversionFactor: 10.0,
        nbDecimal: 5,
    },
    {
        id: "MEGAPASCAL",
        index: 3,
        unitType: "Pression",
        unitName: "Mégapascal",
        unitLabel: "Mégapascal",
        unitSymbol: "MPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "GIGAPASCAL",
        index: 4,
        unitType: "Pression",
        unitName: "Gigapascal",
        unitLabel: "Gigapascal",
        unitSymbol: "GPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "TERAPASCAL",
        index: 5,
        unitType: "Pression",
        unitName: "Térapascal",
        unitLabel: "Térapascal",
        unitSymbol: "TPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "POUND_PER_SQUARE_INCH",
        index: 0,
        unitType: "Pression",
        unitName: "Livres par pouce carré",
        unitLabel: "Livres par pouce carré",
        unitSymbol: "psi",
        conversionFactor: null,
        nbDecimal: 5,
    },
    {
        id: "KILOPOUND_PER_SQUARE_INCH",
        index: 1,
        unitType: "Pression",
        unitName: "kip par pouce carré",
        unitLabel: "kip par pouce carré",
        unitSymbol: "ksi",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "MEGAPOUND_PER_SQUARE_INCH",
        index: 2,
        unitType: "Pression",
        unitName: "Mégalivre par pouce carré",
        unitLabel: "Mégalivre par pouce carré",
        unitSymbol: "MPsi",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "CELSIUS",
        index: 0,
        unitType: "Température",
        unitName: "celsius",
        unitLabel: "celsius",
        unitSymbol: "°C",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FAHRENHEIT",
        index: 0,
        unitType: "Température",
        unitName: "fahrenheit",
        unitLabel: "fahrenheit",
        unitSymbol: "°F",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUSTOM",
        index: 0,
        unitType: "Non-spécifié",
        unitName: "Personnalisé",
        unitLabel: "Personnalisé",
        unitSymbol: null,
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_AREA",
        index: -1,
        unitType: "Aire",
        unitName: "Automatic Area",
        unitLabel: "pied carré",
        unitSymbol: "pi2",
        conversionFactor: 144.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_AREA_SMALL",
        index: -1,
        unitType: "Aire",
        unitName: "Automatic Area (small)",
        unitLabel: "pouce carré",
        unitSymbol: "po2",
        conversionFactor: 1000000.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_LENGTH",
        index: -1,
        unitType: "Longueur",
        unitName: "Automatic Length",
        unitLabel: "pied",
        unitSymbol: "pi",
        conversionFactor: 12.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_LENGTH_SMALL",
        index: -1,
        unitType: "Longueur",
        unitName: "Automatic Length (short)",
        unitLabel: "pouce",
        unitSymbol: "po",
        conversionFactor: 1000.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_WEIGHT",
        index: -1,
        unitType: "Poid",
        unitName: "Automatic Weight",
        unitLabel: "tonnes",
        unitSymbol: "t",
        conversionFactor: 2000.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_VOLUME",
        index: -1,
        unitType: "Volume",
        unitName: "Automatic Volume (short)",
        unitLabel: "pied cube",
        unitSymbol: "pi3",
        conversionFactor: 1728.0,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_VOLUME_SMALL",
        index: -1,
        unitType: "Volume",
        unitName: "Automatic Volume (small)",
        unitLabel: "pouce cube",
        unitSymbol: "po3",
        conversionFactor: null,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_RECOVERY",
        index: -1,
        unitType: "Récupération",
        unitName: "Automatic Recovery",
        unitLabel: "pmp par pied cube",
        unitSymbol: "pmp/pi3",
        conversionFactor: null,
        nbDecimal: 4,
    },
    {
        id: "LOCALIZED_RECOVERY_SMALL",
        index: -1,
        unitType: "Récupération",
        unitName: "Automatic Conversion",
        unitLabel: "pied cube par mpmp",
        unitSymbol: "pi3/mpmp",
        conversionFactor: null,
        nbDecimal: 4,
    }],
    en: [{
        id: "NANOMETERS",
        index: 0,
        unitType: "Length",
        unitName: "nanometer",
        unitLabel: "nanometer",
        unitSymbol: "nm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MICROMETERS",
        index: 1,
        unitType: "Length",
        unitName: "micrometer",
        unitLabel: "micrometer",
        unitSymbol: "µm",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MILLIMETERS",
        index: 2,
        unitType: "Length",
        unitName: "millimeter",
        unitLabel: "millimeter",
        unitSymbol: "mm",
        conversionFactor: 1000.0,
        nbDecimal: 1,
    },
    {
        id: "CENTIMETERS",
        index: 3,
        unitType: "Length",
        unitName: "centimeter",
        unitLabel: "centimeter",
        unitSymbol: "cm",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "DECIMETERS",
        index: 4,
        unitType: "Length",
        unitName: "decimeter",
        unitLabel: "decimeter",
        unitSymbol: "dm",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "METERS",
        index: 5,
        unitType: "Length",
        unitName: "meter",
        unitLabel: "meter",
        unitSymbol: "m",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "HECTOMETERS",
        index: 6,
        unitType: "Length",
        unitName: "hectometer",
        unitLabel: "hectometer",
        unitSymbol: "hm",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "KILOMETERS",
        index: 7,
        unitType: "Length",
        unitName: "kilometer",
        unitLabel: "kilometer",
        unitSymbol: "km",
        conversionFactor: 10.0,
        nbDecimal: 0,
    },
    {
        id: "MILLS",
        index: 0,
        unitType: "Length",
        unitName: "thousandth of an inch",
        unitLabel: "thousandth of an inch",
        unitSymbol: "mills",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "INCHES",
        index: 1,
        unitType: "Length",
        unitName: "inch",
        unitLabel: "inch",
        unitSymbol: "in",
        conversionFactor: 1000.0,
        nbDecimal: 3,
    },
    {
        id: "FEET",
        index: 2,
        unitType: "Length",
        unitName: "foot",
        unitLabel: "foot",
        unitSymbol: "ft",
        conversionFactor: 12.0,
        nbDecimal: 0,
    },
    {
        id: "YARDS",
        index: 3,
        unitType: "Length",
        unitName: "yard",
        unitLabel: "yard",
        unitSymbol: "yd",
        conversionFactor: 3.0,
        nbDecimal: 0,
    },
    {
        id: "MILES",
        index: 4,
        unitType: "Length",
        unitName: "mile",
        unitLabel: "mile",
        unitSymbol: "mi",
        conversionFactor: 1760.0,
        nbDecimal: 0,
    },
    {
        id: "NAUTICAL_MILES",
        index: 5,
        unitType: "Length",
        unitName: "nautical mile",
        unitLabel: "nautical mile",
        unitSymbol: "NM",
        conversionFactor: 1.1516,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILLIMETERS",
        index: 0,
        unitType: "Area",
        unitName: "square millimeter",
        unitLabel: "square millimeter",
        unitSymbol: "mm2",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_CENTIMETERS",
        index: 1,
        unitType: "Area",
        unitName: "square centimeter",
        unitLabel: "square centimeter",
        unitSymbol: "cm2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_DECIMETERS",
        index: 2,
        unitType: "Area",
        unitName: "square decimeter",
        unitLabel: "square decimeter",
        unitSymbol: "dm2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_METERS",
        index: 3,
        unitType: "Area",
        unitName: "square meter",
        unitLabel: "square meter",
        unitSymbol: "m2",
        conversionFactor: 100.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_KILOMETERS",
        index: 4,
        unitType: "Area",
        unitName: "square kilometer",
        unitLabel: "square kilometer",
        unitSymbol: "km2",
        conversionFactor: 1000000.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILS",
        index: 0,
        unitType: "Area",
        unitName: "square thousandth of an inch",
        unitLabel: "square thousandth of an inch",
        unitSymbol: "mils2",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_INCHES",
        index: 1,
        unitType: "Area",
        unitName: "square inch",
        unitLabel: "square inch",
        unitSymbol: "in2",
        conversionFactor: 1000000.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_FEET",
        index: 2,
        unitType: "Area",
        unitName: "square foot",
        unitLabel: "square foot",
        unitSymbol: "ft2",
        conversionFactor: 144.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_YARDS",
        index: 3,
        unitType: "Area",
        unitName: "square yard",
        unitLabel: "square yard",
        unitSymbol: "yd2",
        conversionFactor: 9.0,
        nbDecimal: 0,
    },
    {
        id: "SQUARE_MILES",
        index: 4,
        unitType: "Area",
        unitName: "square mile",
        unitLabel: "square mile",
        unitSymbol: "mi2",
        conversionFactor: 3097600.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_MILLIMETERS",
        index: 0,
        unitType: "Volume",
        unitName: "cubic millimeter",
        unitLabel: "cubic millimeter",
        unitSymbol: "mm3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_CENTIMETERS",
        index: 1,
        unitType: "Volume",
        unitName: "cubic centimeter",
        unitLabel: "cubic centimeter",
        unitSymbol: "cm3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_DECIMETERS",
        index: 2,
        unitType: "Volume",
        unitName: "cubic decimeter",
        unitLabel: "cubic decimeter",
        unitSymbol: "dm3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS",
        index: 3,
        unitType: "Volume",
        unitName: "cubic meter",
        unitLabel: "cubic meter",
        unitSymbol: "m3",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_INCHES",
        index: 0,
        unitType: "Volume",
        unitName: "cubic inch",
        unitLabel: "cubic inch",
        unitSymbol: "in3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET",
        index: 1,
        unitType: "Volume",
        unitName: "cubic foot",
        unitLabel: "cubic foot",
        unitSymbol: "ft3",
        conversionFactor: 1728.0,
        nbDecimal: 0,
    },
    {
        id: "BOARD_FEET",
        index: 2,
        unitType: "Volume",
        unitName: "foot-board-measure",
        unitLabel: "foot-board-measure",
        unitSymbol: "fbm",
        conversionFactor: 0.08333333333333333,
        nbDecimal: 0,
    },
    {
        id: "MBF",
        index: 3,
        unitType: "Volume",
        unitName: "mbf",
        unitLabel: "mbf",
        unitSymbol: "mpmp",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "SOLID_OUT_PERCENTAGE",
        index: 0,
        unitType: "Recovery",
        unitName: "solid out recovery",
        unitLabel: "solid out recovery",
        unitSymbol: "%",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FBM_PER_CUBIC_FEET",
        index: 0,
        unitType: "Recovery",
        unitName: "fbm per cubic feet",
        unitLabel: "fbm per cubic feet",
        unitSymbol: "fbm/ft3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FBM_PER_CUBIC_METER",
        index: 0,
        unitType: "Recovery",
        unitName: "fbm per cubic meter",
        unitLabel: "fbm per cubic meter",
        unitSymbol: "fbm/m3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET_PER_MBF",
        index: 0,
        unitType: "Recovery",
        unitName: "cubic feet per mbf",
        unitLabel: "cubic feet per mbf",
        unitSymbol: "ft3/mfbm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_MBF",
        index: 0,
        unitType: "Recovery",
        unitName: "cubic meter per mbf",
        unitLabel: "cubic meter per mbf",
        unitSymbol: "m3/mfbm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_CUBIC_FEET",
        index: 0,
        unitType: "Recovery",
        unitName: "cubic meter per cubic feet",
        unitLabel: "cubic meter per cubic feet",
        unitSymbol: "m3/ft3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_FEET_PER_CUBIC_METERS",
        index: 0,
        unitType: "Recovery",
        unitName: "cubic feet per mbf",
        unitLabel: "cubic feet per mbf",
        unitSymbol: "ft3/m3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_INCHES_PER_HOUR",
        index: 0,
        unitType: "Volume",
        unitName: "cubic inch per hour",
        unitLabel: "cubic inch per hour",
        unitSymbol: "in3/h",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARD_FEET_PER_HOUR",
        index: 1,
        unitType: "Volume",
        unitName: "foot board measure per hour",
        unitLabel: "foot board measure per hour",
        unitSymbol: "fbm/h",
        conversionFactor: 144.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_MILLIMETERS_PER_HOUR",
        index: 0,
        unitType: "Volume",
        unitName: "cubic millimeter per hour",
        unitLabel: "cubic millimeter per hour",
        unitSymbol: "mm3/h",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_CENTIMETERS_PER_HOUR",
        index: 1,
        unitType: "Volume",
        unitName: "cubic centimeter per hour",
        unitLabel: "cubic centimeter per hour",
        unitSymbol: "cm3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_DECIMETERS_PER_HOUR",
        index: 2,
        unitType: "Volume",
        unitName: "cubic decimeter per hour",
        unitLabel: "cubic decimeter per hour",
        unitSymbol: "dm3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "CUBIC_METERS_PER_HOUR",
        index: 3,
        unitType: "Volume",
        unitName: "cubic meter per hour",
        unitLabel: "cubic meter per hour",
        unitSymbol: "m3/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_SECOND",
        index: 0,
        unitType: "Linear Speed",
        unitName: "meter per second",
        unitLabel: "meter per second",
        unitSymbol: "m/s",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_MINUTE",
        index: 1,
        unitType: "Linear Speed",
        unitName: "meter per minute",
        unitLabel: "meter per minute",
        unitSymbol: "m/min",
        conversionFactor: 0.01666666667,
        nbDecimal: 0,
    },
    {
        id: "METER_PER_HOUR",
        index: 2,
        unitType: "Linear Speed",
        unitName: "meter per hour",
        unitLabel: "meter per hour",
        unitSymbol: "m/h",
        conversionFactor: 0.01666666667,
        nbDecimal: 0,
    },
    {
        id: "KILOMETER_PER_HOUR",
        index: 3,
        unitType: "Linear Speed",
        unitName: "kilometer per hour",
        unitLabel: "kilometer per hour",
        unitSymbol: "km/h",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_SECOND",
        index: 0,
        unitType: "Linear Speed",
        unitName: "foot per second",
        unitLabel: "foot per second",
        unitSymbol: "ft/s",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_MINUTE",
        index: 1,
        unitType: "Linear Speed",
        unitName: "foot per minute",
        unitLabel: "foot per minute",
        unitSymbol: "ft/min",
        conversionFactor: 0.016666666667,
        nbDecimal: 0,
    },
    {
        id: "FOOT_PER_HOUR",
        index: 2,
        unitType: "Linear Speed",
        unitName: "foot per hour",
        unitLabel: "foot per hour",
        unitSymbol: "ft/h",
        conversionFactor: 0.016666666667,
        nbDecimal: 0,
    },
    {
        id: "MILE_PER_HOUR",
        index: 3,
        unitType: "Linear Speed",
        unitName: "mile per hour",
        unitLabel: "mile per hour",
        unitSymbol: "mi/h",
        conversionFactor: 5280.0,
        nbDecimal: 0,
    },
    {
        id: "LOGS_PER_MINUTE",
        index: 0,
        unitType: "Speed",
        unitName: "logs per minute",
        unitLabel: "logs per minute",
        unitSymbol: "logs/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARDS_PER_MINUTE",
        index: 0,
        unitType: "Speed",
        unitName: "boards per minute",
        unitLabel: "boards per minute",
        unitSymbol: "boards/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CANTS_PER_MINUTE",
        index: 0,
        unitType: "Speed",
        unitName: "cants per minute",
        unitLabel: "cants per minute",
        unitSymbol: "cants/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "STEMS_PER_MINUTE",
        index: 0,
        unitType: "Speed",
        unitName: "stems per minute",
        unitLabel: "stems per minute",
        unitSymbol: "stems/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUGS_PER_MINUTE",
        index: 0,
        unitType: "Speed",
        unitName: "lugs per minute",
        unitLabel: "lugs per minute",
        unitSymbol: "lugs/min",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BOARDS",
        index: 0,
        unitType: "Unspecified",
        unitName: "boards",
        unitLabel: "boards",
        unitSymbol: "boards",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOGS",
        index: 0,
        unitType: "Unspecified",
        unitName: "logs",
        unitLabel: "logs",
        unitSymbol: "logs",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CANTS",
        index: 0,
        unitType: "Unspecified",
        unitName: "cants",
        unitLabel: "cants",
        unitSymbol: "cants",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "STEMS",
        index: 0,
        unitType: "Unspecified",
        unitName: "stems",
        unitLabel: "stems",
        unitSymbol: "stems",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUGS",
        index: 0,
        unitType: "Unspecified",
        unitName: "lugs",
        unitLabel: "lugs",
        unitSymbol: "lugs",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LUMBER",
        index: 0,
        unitType: "Unspecified",
        unitName: "lumber",
        unitLabel: "lumber",
        unitSymbol: "lumber",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PRODUCTS",
        index: 0,
        unitType: "Unspecified",
        unitName: "products",
        unitLabel: "products",
        unitSymbol: "products",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ROTATION_PER_MINUTE",
        index: 0,
        unitType: "Rotation Speed",
        unitName: "rotation per minute",
        unitLabel: "rotation per minute",
        unitSymbol: "rpm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "NANOSECONDS",
        index: 0,
        unitType: "Time",
        unitName: "nanosecond",
        unitLabel: "nanosecond",
        unitSymbol: "ns",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MICROSECONDS",
        index: 1,
        unitType: "Time",
        unitName: "microsecond",
        unitLabel: "microsecond",
        unitSymbol: "us",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MILLISECONDS",
        index: 2,
        unitType: "Time",
        unitName: "millisecond",
        unitLabel: "millisecond",
        unitSymbol: "ms",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "SECONDS",
        index: 3,
        unitType: "Time",
        unitName: "second",
        unitLabel: "second",
        unitSymbol: "s",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "MINUTES",
        index: 4,
        unitType: "Time",
        unitName: "minute",
        unitLabel: "minute",
        unitSymbol: "min",
        conversionFactor: 60.0,
        nbDecimal: 0,
    },
    {
        id: "HOURS",
        index: 5,
        unitType: "Time",
        unitName: "hour",
        unitLabel: "hour",
        unitSymbol: "h",
        conversionFactor: 60.0,
        nbDecimal: 0,
    },
    {
        id: "DAYS",
        index: 6,
        unitType: "Time",
        unitName: "day",
        unitLabel: "day",
        unitSymbol: "d",
        conversionFactor: 24.0,
        nbDecimal: 0,
    },
    {
        id: "WEEKS",
        index: 7,
        unitType: "Time",
        unitName: "week",
        unitLabel: "week",
        unitSymbol: "wk",
        conversionFactor: 7.0,
        nbDecimal: 0,
    },
    {
        id: "MONTHS",
        index: 0,
        unitType: "Time",
        unitName: "month",
        unitLabel: "month",
        unitSymbol: "mth",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "YEARS",
        index: 0,
        unitType: "Time",
        unitName: "year",
        unitLabel: "year",
        unitSymbol: "yr",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "HORSEPOWER",
        index: 0,
        unitType: "Power",
        unitName: "horsepower",
        unitLabel: "horsepower",
        unitSymbol: "hp",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "INCH_PER_FOOT",
        index: 0,
        unitType: "Slope",
        unitName: "inch/foot",
        unitLabel: "inch/foot",
        unitSymbol: "in/ft",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "MILLIMETER_PER_METER",
        index: 0,
        unitType: "Slope",
        unitName: "millimeter/meter",
        unitLabel: "millimeter/meter",
        unitSymbol: "mm/m",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PERCENTAGE",
        index: 0,
        unitType: "Proportion",
        unitName: "percentage",
        unitLabel: "percentage",
        unitSymbol: "%",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ABS",
        index: 0,
        unitType: "Proportion",
        unitName: "abs",
        unitLabel: "abs",
        unitSymbol: "",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "DOLLARS",
        index: 0,
        unitType: "Unspecified",
        unitName: "dollars",
        unitLabel: "dollars",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "DEGREE",
        index: 0,
        unitType: "Unspecified",
        unitName: "degree",
        unitLabel: "degree",
        unitSymbol: "°",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "EFFICIENCY",
        index: 0,
        unitType: "Unspecified",
        unitName: "cubic meter per million foot-board-measure",
        unitLabel: "cubic meter per million foot-board-measure",
        unitSymbol: "m3/mfbm",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "PIXEL",
        index: 0,
        unitType: "Unspecified",
        unitName: "pixels",
        unitLabel: "pixels",
        unitSymbol: "px",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "ENCODER_RATIO",
        index: 0,
        unitType: "Unspecified",
        unitName: "Inch/pulse",
        unitLabel: "Inch/pulse",
        unitSymbol: "in/pul",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUTTING_UNIT",
        index: 0,
        unitType: "Area",
        unitName: "cutting unit",
        unitLabel: "cutting unit",
        unitSymbol: "cu",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "BYTES",
        index: 0,
        unitType: "Binary",
        unitName: "Byte",
        unitLabel: "Byte",
        unitSymbol: "B",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "KILOBYTES",
        index: 1,
        unitType: "Binary",
        unitName: "Kilobyte",
        unitLabel: "Kilobyte",
        unitSymbol: "KB",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "MEGABYTES",
        index: 2,
        unitType: "Binary",
        unitName: "Megabyte",
        unitLabel: "Megabyte",
        unitSymbol: "MB",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "GIGABYTES",
        index: 3,
        unitType: "Binary",
        unitName: "Gigabyte",
        unitLabel: "Gigabyte",
        unitSymbol: "GB",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "TERABYTES",
        index: 4,
        unitType: "Binary",
        unitName: "Terabyte",
        unitLabel: "Terabyte",
        unitSymbol: "TB",
        conversionFactor: 1024.0,
        nbDecimal: 0,
    },
    {
        id: "DOLLARS_IMPERIAL",
        index: 0,
        unitType: "Price",
        unitName: "dollars imperial",
        unitLabel: "dollars imperial",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "DOLLARS_METRIC",
        index: 0,
        unitType: "Price",
        unitName: "dollars metric",
        unitLabel: "dollars metric",
        unitSymbol: "$",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "VALUE_PER_CUBIC_INCHES",
        index: 0,
        unitType: "Price per",
        unitName: "price/in3",
        unitLabel: "price/in3",
        unitSymbol: "/in3",
        conversionFactor: null,
        nbDecimal: 8,
    },
    {
        id: "VALUE_PER_CUBIC_FEET",
        index: 1,
        unitType: "Price per",
        unitName: "price/ft3",
        unitLabel: "price/ft3",
        unitSymbol: "/ft3",
        conversionFactor: 5.787037037037037E-4,
        nbDecimal: 3,
    },
    {
        id: "VALUE_PER_CUBIC_METERS",
        index: 0,
        unitType: "Price per",
        unitName: "price/m3",
        unitLabel: "price/m3",
        unitSymbol: "/m3",
        conversionFactor: null,
        nbDecimal: 3,
    },
    {
        id: "POUNDS_PER_CUBIC_FEET",
        index: 0,
        unitType: "Weight",
        unitName: "pounds per cubic feet",
        unitLabel: "pounds per cubic feet",
        unitSymbol: "lb/ft3",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "KG_PER_CUBIC_METER",
        index: 0,
        unitType: "Weight",
        unitName: "kilograms per cubic meter",
        unitLabel: "kilograms per cubic meter",
        unitSymbol: "kg/m3",
        conversionFactor: null,
        nbDecimal: 2,
    },
    {
        id: "POUNDS",
        index: 0,
        unitType: "Weight",
        unitName: "pounds",
        unitLabel: "pounds",
        unitSymbol: "lb",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "TONS",
        index: 1,
        unitType: "Weight",
        unitName: "tons",
        unitLabel: "tons",
        unitSymbol: "t",
        conversionFactor: 2000.0,
        nbDecimal: 0,
    },
    {
        id: "KILOS",
        index: 0,
        unitType: "Weight",
        unitName: "kilograms",
        unitLabel: "kilograms",
        unitSymbol: "kg",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "METRIC_TONS",
        index: 1,
        unitType: "Weight",
        unitName: "metric tons",
        unitLabel: "metric tons",
        unitSymbol: "t",
        conversionFactor: 1000.0,
        nbDecimal: 0,
    },
    {
        id: "PASCAL",
        index: 0,
        unitType: "Pressure",
        unitName: "Pascal",
        unitLabel: "Pascal",
        unitSymbol: "Pa",
        conversionFactor: null,
        nbDecimal: 5,
    },
    {
        id: "HECTOPASCAL",
        index: 1,
        unitType: "Pressure",
        unitName: "Hectopascal",
        unitLabel: "Hectopascal",
        unitSymbol: "hPa",
        conversionFactor: 100.0,
        nbDecimal: 5,
    },
    {
        id: "KILOPASCAL",
        index: 2,
        unitType: "Pressure",
        unitName: "Kilopascal",
        unitLabel: "Kilopascal",
        unitSymbol: "kPa",
        conversionFactor: 10.0,
        nbDecimal: 5,
    },
    {
        id: "MEGAPASCAL",
        index: 3,
        unitType: "Pressure",
        unitName: "Megapascals",
        unitLabel: "Megapascals",
        unitSymbol: "MPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "GIGAPASCAL",
        index: 4,
        unitType: "Pressure",
        unitName: "Gigapascal",
        unitLabel: "Gigapascal",
        unitSymbol: "GPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "TERAPASCAL",
        index: 5,
        unitType: "Pressure",
        unitName: "Terapascal",
        unitLabel: "Terapascal",
        unitSymbol: "TPa",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "POUND_PER_SQUARE_INCH",
        index: 0,
        unitType: "Pressure",
        unitName: "Pounds per square inch",
        unitLabel: "Pounds per square inch",
        unitSymbol: "psi",
        conversionFactor: null,
        nbDecimal: 5,
    },
    {
        id: "KILOPOUND_PER_SQUARE_INCH",
        index: 1,
        unitType: "Pressure",
        unitName: "Kilopound  per square inch",
        unitLabel: "Kilopound  per square inch",
        unitSymbol: "ksi",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "MEGAPOUND_PER_SQUARE_INCH",
        index: 2,
        unitType: "Pressure",
        unitName: "Megapound per square inch",
        unitLabel: "Megapound per square inch",
        unitSymbol: "MPsi",
        conversionFactor: 1000.0,
        nbDecimal: 5,
    },
    {
        id: "CELSIUS",
        index: 0,
        unitType: "Temperature",
        unitName: "celsius",
        unitLabel: "celsius",
        unitSymbol: "°C",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "FAHRENHEIT",
        index: 0,
        unitType: "Temperature",
        unitName: "fahrenheit",
        unitLabel: "fahrenheit",
        unitSymbol: "°F",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "CUSTOM",
        index: 0,
        unitType: "Unspecified",
        unitName: "Custom",
        unitLabel: "Custom",
        unitSymbol: null,
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_AREA",
        index: -1,
        unitType: "Area",
        unitName: "Aire automatique",
        unitLabel: "square foot",
        unitSymbol: "ft2",
        conversionFactor: 144.0,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_AREA_SMALL",
        index: -1,
        unitType: "Area",
        unitName: "Aire automatique (petite)",
        unitLabel: "square inch",
        unitSymbol: "in2",
        conversionFactor: 1000000.0,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_LENGTH",
        index: -1,
        unitType: "Length",
        unitName: "Longueur automatique",
        unitLabel: "foot",
        unitSymbol: "ft",
        conversionFactor: 12.0,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_LENGTH_SMALL",
        index: -1,
        unitType: "Length",
        unitName: "Longueur automatique (courte)",
        unitLabel: "inch",
        unitSymbol: "in",
        conversionFactor: 1000.0,
        nbDecimal: 3,
    },
    {
        id: "LOCALIZED_WEIGHT",
        index: -1,
        unitType: "Weight",
        unitName: "Poids automatique",
        unitLabel: "tons",
        unitSymbol: "t",
        conversionFactor: 2000.0,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_VOLUME",
        index: -1,
        unitType: "Volume",
        unitName: "Volume automatique (courte)",
        unitLabel: "cubic foot",
        unitSymbol: "ft3",
        conversionFactor: 1728.0,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_VOLUME_SMALL",
        index: -1,
        unitType: "Volume",
        unitName: "Volume automatique (petit)",
        unitLabel: "cubic inch",
        unitSymbol: "in3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_RECOVERY",
        index: -1,
        unitType: "Recovery",
        unitName: "Récupération automatique",
        unitLabel: "fbm per cubic feet",
        unitSymbol: "fbm/ft3",
        conversionFactor: null,
        nbDecimal: 0,
    },
    {
        id: "LOCALIZED_CONVERSION",
        index: -1,
        unitType: "Recovery",
        unitName: "Conversion automatique",
        unitLabel: "cubic feet per mbf",
        unitSymbol: "ft3/mfbm",
        conversionFactor: null,
        nbDecimal: 0,
    }],
};