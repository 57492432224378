export const sampleTypes = ["SAMPLING", "SIMULATION"] as const;

export interface ISample {
    id: string;
    optimizerSampleId: number;
    type: typeof sampleTypes[number];
    username: string;
    name: string;
    nodeId: string;
    solutionCount: number;
    solutionNotProcessedCount: number;
    solutionsAllProcessed: boolean;
    creationDate: number;
    modificationStamp: number;
    categoryId: string | null;
}

export interface ICategory {
    id: string;
    name: string;
    parentId: string | null;
    type: typeof sampleTypes[number];
    nodeId: string;
}

export type ISamples = { [id: string]: ISample; };
export type ICategories = { [id: string]: ICategory; };
