import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ITagStrategies, ITagStrategy } from "./model";

declare global {
    interface IStoreState {
        tagStrategies: ITagStrategies;
    }
}

const useStats = "tagStrategies";

const slice = createSlice({
    name: "tagStrategies",
    initialState: {} as ITagStrategies,
    reducers: {
        set: (state, { payload }: PayloadAction<ITagStrategies>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        patch: (state, { payload }: PayloadAction<ITagStrategy[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actions = {
    ...slice.actions,
};

export default {
    [slice.name]: slice.reducer,
};