import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ICloudAgentFilePending, ICloudAgentFilesPending, ICloudAgentStats, ICloudAgentStatsServer } from "./model";

declare global {
    interface IStoreState {
        cloudAgentStats: ICloudAgentStats;
        cloudAgentFilesPending: ICloudAgentFilesPending;
    }
}

const convertFromServer = (cloudAgentStats: ICloudAgentStatsServer): ICloudAgentStats => _.mapValues(cloudAgentStats, (data, key) => ({
    nodeId: key,
    deviceId: data?.[0].deviceId,
    data,
}));

const cloudAgentStats = createSlice({
    name: "cloudAgentStats",
    initialState: null as ICloudAgentStats,
    reducers: {
        setStats: (state, { payload }: PayloadAction<ICloudAgentStatsServer>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: convertFromServer(payload), useStats: "cloudAgentStats" })
        ),
    },
});

const cloudAgentFilesPending = createSlice({
    name: "cloudAgentFilesPending",
    initialState: null as ICloudAgentFilesPending,
    reducers: {
        setFilesPending: (state, { payload }: PayloadAction<ICloudAgentFilePending[]>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ filePath }) => filePath), useStats: "cloudAgentFilesPending" })
        ),
    },
});

export const actionsCreators = {
    ...cloudAgentStats.actions,
    ...cloudAgentFilesPending.actions,
};

export default {
    [cloudAgentStats.name]: cloudAgentStats.reducer,
    [cloudAgentFilesPending.name]: cloudAgentFilesPending.reducer,
};