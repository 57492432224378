/**
 * -------------------------------------------------------------------------------------------------------------------------
 * Warning : This page must also be run in gulp file: "dashboards.task.gulp.js" in the "migrateDashboards" function.
 * This mean that the codes in this page must not use any code from other pages (interfaces are ok).
 * You can't import without the "type" keyword.
 * -------------------------------------------------------------------------------------------------------------------------
 */

import { produce } from "immer";
import _ from "lodash";
import type { IKpiQueryRecipesNodeMachine, IKpiQueryRecipesNodeScanner } from "../../kpis/kpiQueries/model";
import type { IDashboard } from "../model";
import type { IWidgetDashboard } from "../widgets";
import type { IDashboardV1, IDashboardV2, IDashboardV3, IDashboardVersions } from "./model";

export const needToMigrate = (dashboard: IDashboardVersions): boolean => dashboard?.version != "v4"; // function needed for gulp "dashboards.task.gulp.js"

export const migrateDashboard = (anyVersionDashboard: IDashboardVersions, millNodeId: string = null): IDashboard => {
    let dashboard: IDashboardVersions = anyVersionDashboard;

    try {
        if (dashboard?.version == undefined) { // V1 is undefined, because version numbers didn't exist then...
            dashboard = migrateDashboardToV2(dashboard as IDashboardV1);
        }
        if (dashboard.version == "v2") {
            dashboard = migrateDashboardToV3(dashboard as IDashboardV2);
        }
        if (dashboard.version == "v3") {
            dashboard = migrateDashboardToV4(dashboard as IDashboardV3, millNodeId);
        }
    } catch (error) {
        console.error(`error migrating dashboard (${dashboard.id}):`, error);
    }
    if (needToMigrate(dashboard)) {
        console.error(`error migrating dashboard (${dashboard.id})`);
    }
    if (millNodeId) {
        dashboard.millNodeId = millNodeId;
    }
    return dashboard as IDashboard;
};

const migrateDashboardToV2 = (dashboard: IDashboardV1): IDashboardV2 => ({
    ...dashboard,
    widgetsByBreakpoints: _.mapValues(dashboard.widgetsByBreakpoints, (oldWidgets) => _.map(oldWidgets, (widget) => ({
        ...widget,
        props: (() => {
            switch (widget.widgetType) {
                case "KpisTable":
                case "MultiKpis":
                case "TimeGraphs":
                case "TimeTable":
                case "Graphs": {
                    const props = widget.props;

                    // Migrate titleFormula sub property if existing
                    const titleFormula = (() => {
                        if (!props.titleFormula) return {};
                        return {
                            titleFormula: props.titleFormula
                                .replace(/\$machine/g, "$parentNode")
                                .replace(/\$scanner/g, "$node"),
                        };
                    })();

                    // Migrate sort sub property if existing
                    const sort = (() => {
                        if (!props.sort) return {};
                        return {
                            sort: {
                                ...props.sort,
                                by: (() => {
                                    switch (props.sort.by) {
                                        case "scanner_context_kpi": return "node_context_kpi";
                                        case "scanner_kpi_context": return "node_kpi_context";
                                        case "context_scanner_kpi": return "context_node_kpi";
                                        case "context_kpi_scanner": return "context_kpi_node";
                                        case "kpi_context_scanner": return "kpi_context_node";
                                        case "kpi_scanner_context": return "kpi_node_context";
                                        case "kpiDef_kpiPattern_scannerContext": return "kpiDef_kpiPattern_nodeContext";
                                        case "kpiPattern_kpiDef_scannerContext": return "kpiPattern_kpiDef_nodeContext";
                                        default: return props.sort.by;
                                    }
                                })(),
                            },
                        };
                    })();

                    const { scanners, ...kpiQueryRecipes } = props.kpiQueryRecipes;
                    return ({
                        ...props,
                        ...titleFormula,
                        ...sort,
                        kpiQueryRecipes: {
                            ...kpiQueryRecipes,
                            nodes: _.map(scanners, (scanner) => ({
                                id: scanner.id,
                                templateName: "scannerCodec",
                                reported: {
                                    machine: scanner.machineModel,
                                    scannerIndex: scanner.scannerIndex,
                                },
                            })),
                        },

                    });
                }
                case "Machine": {
                    const { machineId, machineModel, ...props } = widget.props;
                    return {
                        ...props,
                        queryRecipe: {
                            id: dashboard.isSystem ? null : machineId,
                            templateName: "machineCodec",
                            machine: machineModel,
                        } as IKpiQueryRecipesNodeMachine,
                    };
                }
                case "DataQuery":
                case "LineSpeed":
                case "TimberViewer2D":
                case "TimberViewer3D":
                case "SolutionViewer": {
                    const { scanner: { scannerId, scannerIndex, machineModel }, ...props } = widget.props;
                    return {
                        ...props,
                        queryRecipe: {
                            id: dashboard.isSystem ? null : scannerId,
                            templateName: "scannerCodec",
                            machine: machineModel,
                            scannerIndex,
                        } as IKpiQueryRecipesNodeScanner,
                    };
                }
            }
            return widget.props;
        })(),
    }))),
    version: "v2",
});

/**
 * In V3, some kpis must be used with a machine node instead of a scanner node.
 * We must find the kpis affected by this, and change or add the parent node (machine) of the scanner node.
 */
const migrateDashboardToV3 = (dashboard: IDashboardV2): IDashboardV3 => ({
    ...dashboard,
    widgetsByBreakpoints: _.mapValues(dashboard.widgetsByBreakpoints, (oldWidgets) => _.map(oldWidgets, (widget) => ({
        ...widget,
        props: (() => {
            switch (widget.widgetType) {
                case "KpisTable":
                case "MultiKpis":
                case "TimeGraphs":
                case "TimeTable":
                case "Graphs": {
                    const props = widget.props;

                    return {
                        ...props,
                        kpiQueryRecipes: {
                            ...props.kpiQueryRecipes,
                            nodes: ((() => {
                                const kpisToMigrateCount = _.reduce(props.kpiQueryRecipes.kpis, (count, kpi) => {
                                    const database = findDatabaseOfKpi(kpi.patternKey);
                                    if (database) count++;
                                    return count; // no migration for this kpi
                                }, 0);
                                if (kpisToMigrateCount > 0) {
                                    const migratedsNodes = _.map(props.kpiQueryRecipes.nodes, (node) => {
                                        if (node.templateName == "scannerCodec") {
                                            return {
                                                id: null,
                                                templateName: "machineCodec",
                                                machine: node.reported.machine,
                                            } as IKpiQueryRecipesNodeMachine;
                                        } else {
                                            return node; // no migration
                                        }
                                    });
                                    if (kpisToMigrateCount == _.size(props.kpiQueryRecipes.kpis)) { // all kpis need to migrate, so we can changes all nodes
                                        return migratedsNodes;
                                    } else { // some kpis need to migrate, so we add the migrated nodes to the ones already existing
                                        return [
                                            ...props.kpiQueryRecipes.nodes,
                                            ...migratedsNodes,
                                        ];
                                    }
                                }
                                return props.kpiQueryRecipes.nodes;
                            })()),
                        },
                    };
                }
                default: {
                    return widget.props;
                }
            }
        })(),
    } as IDashboardV3["widgetsByBreakpoints"]["xxs"][number]))),
    version: "v3",
});

/**
 * In V4, some kpis recipes (kpiQueryRecipes, queryRecipe) had a sub object called "reported", that object has been spread in the parent.
 */
const migrateDashboardToV4 = (dashboard: IDashboardV3, millNodeId: string = null): IDashboard => ({
    ...dashboard,
    millNodeId: millNodeId || dashboard?.millNodeId,
    widgetsByBreakpoints: _.mapValues(dashboard.widgetsByBreakpoints, (oldWidgets) => (
        _.map(oldWidgets, (widget) => ({
            ...widget,
            props: {
                ...widget.props,
                ...(() => {
                    switch (widget.widgetType) {
                        case "KpisTable":
                        case "MultiKpis":
                        case "TimeGraphs":
                        case "TimeTable":
                        case "Graphs": {
                            return produce(widget.props, (draft) => {
                                _.forEach(draft.kpiQueryRecipes.nodes, (_node, key) => {
                                    _.forEach((draft.kpiQueryRecipes.nodes[key].reported), (value, subKey) => {
                                        draft.kpiQueryRecipes.nodes[key][subKey] = value; // re-apply the reported properties at the first level
                                    });
                                    delete draft.kpiQueryRecipes.nodes[key].reported; // then deleted the reported property subojbect
                                });
                            });
                        }
                        case "Machine":
                        case "DataQuery":
                        case "LineSpeed":
                        case "TimberViewer2D":
                        case "TimberViewer3D":
                        case "SolutionViewer": {
                            return produce(widget.props, (draft) => {
                                _.forEach((draft.queryRecipe.reported), (value, key) => {
                                    draft.queryRecipe[key] = value; // re-apply the reported properties at the first level
                                });
                                delete draft.queryRecipe.reported; // then deleted the reported property subojbect
                            });
                        }
                        default: {
                            return widget.props;
                        }
                    }
                })(),
            },
        } as IWidgetDashboard))
    )),
    version: "v4",
});

const findDatabaseOfKpi = (kpiPatternKey: string): (keyof typeof V3Databases | null) => {
    let found: keyof typeof V3Databases = null;
    _.forEach(V3Databases, (kpiPatternKeys, databaseName) => {
        if (kpiPatternKeys.includes(kpiPatternKey)) {
            found = databaseName as keyof typeof V3Databases;
            return false;
        }
        return undefined;
    });
    return found;
};

/**
 * These are the database used by kpis that need their nodes to be migrated from.
 * To create this variable, I used this script in the web console on iep.comact.com
 * @example
 * _.reduce(app.state.kpiPatterns, (acc, next) => {
 *    const databaseToMigrate = ["Bin", "BundleOrder", "Bundle", "downtime", "Fence", "SorterSummary", "Zone"];
 *    if (!databaseToMigrate.includes(next.database)) return acc;
 *    if (!acc[next.database]) acc[next.database] = [];
 *    acc[next.database].push(next.uniqueName)
 *    return acc;
 * }, {});
 */
const V3Databases = {
    downtime: [
        "downtime-causes",
    ],
    SorterSummary: [
        "bin-full-number",
        "bin-cancel-number",
        "bin-used-number",
        "bin-empty-number",
        "bin-count",
        "bin-volume",
        "bin-full-number-ratio",
        "bin-empty-number-ratio",
        "bin-used-number-ratio",
    ],
    Bundle: [
        "bundle-volume-product",
        "bundle-count-product",
        "bundle-volume",
        "bundle-count",
        "bundle-count-board-detail",
        "bundle-count-board-detail-ratio",
        "bundle-count-product-ratio",
        "bundle-volume-product-ratio",
        "bundle-count-hour-uptime",
        "bundle-volume-hour",
        "count-board",
    ],
    Zone: [
        "bin-full-number-zone",
        "bin-empty-number-zone",
        "bin-disable-number-zone",
        "bin-used-number-zone",
        "bin-count-zone",
        "bin-volume-zone",
        "bin-full-number-zone-ratio",
        "bin-empty-number-zone-ratio",
        "bin-used-number-zone-ratio",
    ],
    BundleOrder: [
        "order-count-last",
    ],
    Fence: [
        "fence-error-by-paddle",
        "fence-error-avg",
        "fence-error-std",
        "fence-error-std-per-paddle",
    ],
};