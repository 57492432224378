export enum FormulaType {
    QUERY = "queryFormula",
    FILTER = "filterFormula"
}
export interface IKpiPatternEditorFormulaError {
    local: string[];
    remote: string[];
}

export type IKpiPatternEditorFormulaErrors = { [key in FormulaType]: IKpiPatternEditorFormulaError };
