// Redux
import { createModule } from "@comact/crc";
import * as actions from "./actions";
import * as selectors from "./selectors";
import reducers, { actionsCreators } from "./slices";

export { actions, selectors };

export const ReportsJobsEditModule = createModule({
    reducers, actionsCreators, selectors,
});
