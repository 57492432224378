import { IReportExecutionStatusResponse } from "./model";

let reportToken = 0;
export const mockReportToken = (): number => reportToken++;

const reportStatusCache: number[] = [];
export const mockReportStatus = (token: number): IReportExecutionStatusResponse => {
    if (reportStatusCache[token]) {
        if (Date.now() > reportStatusCache[token]) {
            return { done: true };
        }
    } else {
        reportStatusCache[token] = Date.now() + 2000; // 2 sec wait
    }
    return { done: false };
};
