import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IKpiAlert, IKpiAlerts } from ".";

declare global {
    interface IStoreState {
        kpiAlerts: IKpiAlerts;
    }
}

const useStats = "kpiAlerts";

const kpiAlerts = createSlice({
    name: "kpiAlerts",
    initialState: null as IKpiAlerts,
    reducers: {
        set: (state, { payload }: PayloadAction<IKpiAlerts>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        patch: (state, { payload }: PayloadAction<IKpiAlert[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...kpiAlerts.actions,
};

export default {
    [kpiAlerts.name]: kpiAlerts.reducer,
};