import { ILocalizedString, store, t } from "@comact/crc";
import _ from "lodash";

/** Validate if the localize string is not empty */
export const validateLocalizeString = (localizeString: ILocalizedString) => (
    store.getState().system.localesRequired.reduce((obj, key) => {
        if (_.isEmpty(localizeString.values[key])) {
            obj[key] = t("core:errors.notEmpty");
        }
        return obj;
    }, {} as { [id: string]: string; })
);

// matching file type string provided from backend "pdf" or "xls"
export enum FileType {
    pdf = "pdf",
    xls = "xls"
}

export const downloadFileFromAjaxRequest = (request: XMLHttpRequest) => {
    // The request ask for an arraybuffer. The response must be converted into a blob object
    const contentType = request.getResponseHeader("Content-Type");
    const blob = new Blob([request.response], { type: contentType });

    // A temporary link is created to be able to launch a download action in the browser.
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = request.getResponseHeader("content-disposition").split("filename=")[1].split(";")[0];

    // Launch the download action
    a.click();

    // Clean the temporary objects
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};