import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IEvent, IEvents } from "./model";

declare global {
    interface IStoreState {
        systemPunctualEvents: IEvents;
        systemDurationEvents: IEvents;
    }
}

const useStatsPunctual = "systemPunctualEvents";

const systemPunctualEvents = createSlice({
    name: "systemPunctualEvents",
    initialState: null as IEvents,
    reducers: {
        setPunctualEvents: (state, { payload }: PayloadAction<IEvent[]>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats: useStatsPunctual,
            })
        ),
        clearAllPunctualEvents: () => null,
    },
});

const useStatsDuration = "systemDurationEvents";

const systemDurationEvents = createSlice({
    name: "systemDurationEvents",
    initialState: null as IEvents,
    reducers: {
        setDurationEvents: (state, { payload }: PayloadAction<IEvent[]>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats: useStatsDuration,
            })
        ),
        clearAllDurationEvents: () => null,
    },
});

export const actionsCreators = {
    ...systemPunctualEvents.actions,
    ...systemDurationEvents.actions,
};

export default {
    [systemPunctualEvents.name]: systemPunctualEvents.reducer,
    [systemDurationEvents.name]: systemDurationEvents.reducer,
};