import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { IDensityBySpecies } from "./model";

const rand = createSeeder("mock-species-densties");

export const getSpeciesDensity = (): IDensityBySpecies[] => (
    _.times(5, (i) => ({
        speciesId: (i + 1).toString(),
        density: rand.intBetween(0, 100),
    }))
);