export const exportJobVariables = [
    '$shift-date-short',
    '$shift-date-medium',
    '$shift-date-long',
    '$shift-date-full',
    '$shift-time-12h',
    '$shift-time-24h',
    '$current-date-short',
    '$current-date-medium',
    '$current-date-long',
    '$current-date-full',
    '$current-time-12h',
    '$current-time-24h',
    '$shift-start',
    '$shift-end',
    '$machine-type',
    '$machine-name',
    '$shift-description',
    '$shift-name',
    '$work-team'
];

// Regex to match keywords for all valid expressions from https://docs.influxdata.com/influxdb/v1.7/query_language/functions/
export const influxDbFunctions = [
    "^COUNT",
    "^DISTINCT",
    "^INTEGRAL",
    "^MEAN",
    "^MEDIAN",
    "^MODE",
    "^SPREAD",
    "^STDDEV",
    "^SUM",
    "^BOTTOM",
    "^FIRST",
    "^LAST",
    "^MAX",
    "^MIN",
    "^PERCENTILE",
    "^SAMPLE",
    "^TOP",
    "^ABS",
    "^ACOS",
    "^ASIN",
    "^ATAN",
    "^ATAN2",
    "^CEIL",
    "^COS",
    "^CUMULATIVE_SUM",
    "^DERIVATIVE",
    "^DIFFERENCE",
    "^ELAPSED",
    "^EXP",
    "^FLOOR",
    "^HISTOGRAM",
    "^LN",
    "^LOG",
    "^LOG2",
    "^LOG10",
    "^MOVING_AVERAGE",
    "^NON_NEGATIVE_DERIVATIVE",
    "^NON_NEGATIVE_DIFFERENCE",
    "^POW",
    "^ROUND",
    "^SIN",
    "^SQRT",
    "^TAN",
    "^HOLT_WINTERS",
    "^CHANDE_MOMENTUM_OSCILLATOR",
    "^EXPONENTIAL_MOVING_AVERAGE",
    "^DOUBLE_EXPONENTIAL_MOVING_AVERAGE",
    "^KAUFMANS_EFFICIENCY_RATIO",
    "^KAUFMANS_ADAPTIVE_MOVING_AVERAGE",
    "^TRIPLE_EXPONENTIAL_MOVING_AVERAGE",
    "^TRIPLE_EXPONENTIAL_DERIVATIVE",
    "^RELATIVE_STRENGTH_INDEX",
].join('|');

export const influxDbOperators = [
    "^and",
    "^or",
    "^<=",
    "^>=",
    "^<",
    "^>",
    "^!=",
    "^=",
].join('|');

export const javascriptOperators = [

    { regexInflux: "^=", regexJS: "^==", labelJS: "==", labelInflux: "=", priority: 4 },
    { regexInflux: "^!=", regexJS: "^!==", labelJS: "!==", labelInflux: "!=", priority: 3 },

    { regexInflux: "^<=", regexJS: "^<=", labelJS: "<=", labelInflux: "<=", priority: 1 },
    { regexInflux: "^>=", regexJS: "^>=", labelJS: ">=", labelInflux: ">=", priority: 1 },

    { regexInflux: "^<", regexJS: "^<", labelJS: "<", labelInflux: "<", priority: 2 },
    { regexInflux: "^>", regexJS: "^>", labelJS: ">", labelInflux: ">", priority: 2 },

    { regexInflux: "^and", regexJS: "^\\&\\&", labelJS: "&&", labelInflux: "and", priority: 0 },
    { regexInflux: "^or", regexJS: "^\\|\\|", labelJS: "||", labelInflux: "or", priority: 0 },
];

export const regexFileNameForm = new RegExp('^[a-zA-Z0-9_?$-]+$', 'i');
export const regVariables = new RegExp(`(\\${exportJobVariables.join('|\\')})`, 'i');
export const regexFileNameWord = new RegExp('^\\$[a-zA-Z0-9_-]+', 'i');