import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../../node";
import { IReportConfiguration, IReportConfigurations } from "./model";

/* Get list of IReportConfiguration which are currently "enabled" only */
export const getEnabledConfigurations = memoize((state: IStoreState) => _.filter(state.reportConfigurations, (rc) => rc.enabled));

export const getReportConfigurations = ({ reportConfigurations: reportConfigurations }: IStoreState): IReportConfigurations => reportConfigurations;

export const getReportConfigurationById = ({ reportConfigurations: reportConfigurations }: IStoreState, id: string): IReportConfiguration => reportConfigurations[id];

export const getReportConfigurationsByNodeIdRecursive = (state: IStoreState, nodeId: string): IReportConfigurations => {
    const nodeIds: string[] = [];

    const getChildrenIds = (currentNodeId: string) => {
        const childIds = _.map(NodesModule.selectors.getNodeChildrenByParentId(state, currentNodeId), ({ id }) => id);
        nodeIds.push(...childIds);
        _.forEach(childIds, (nId) => {
            getChildrenIds(nId);
        });
    };

    getChildrenIds(nodeId);

    return _.pickBy(state.reportConfigurations, (config) => !_.isEmpty(_.intersection(config.nodeIds, [nodeId, ...nodeIds])));
};
