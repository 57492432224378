import { ChartGraph, TextBox } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import { IQueryResults } from "js/common";
import { IZoneBackgroundProps, ZoneBackground } from "js/components/ZoneBackground";
import { convertForTextBoxWithGraph } from "js/kpis/kpiQueries/utils";
import _ from "lodash";
import * as React from "react";

export const TextBoxWithGraph = React.memo<{ results: IQueryResults; showTitle?: boolean; showUnit?: boolean; }>(({ results, showTitle = true, showUnit = true }) => {
    const { value, objective } = _.last(results.data);
    const zones = objective?.zones || [];
    const clampedValue = _.clamp(value, objective?.min, objective?.max);
    const targetZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const { textBoxProps, chartGraphProps } = convertForTextBoxWithGraph(results);
    return (
        <TextBoxWithGraphStyle className="textBoxWithGraph" zone={targetZone ? targetZone.value : "empty"}>
            <ChartGraph {...chartGraphProps} />
            <TextBox {...textBoxProps} showUnit={showUnit} showLabel={showTitle} />
        </TextBoxWithGraphStyle>
    );
});

const TextBoxWithGraphStyle = styled((props) => <ZoneBackground {...props} />)<IZoneBackgroundProps>`
    position: relative;
    border-radius: ${CSS.borderRadius}px;
    min-height: 80px;
    .chartGraph {
        position: absolute;
        top: 1.5em;
        left: 0;
        bottom: 1.5em;
        right: 0;
    }

    .textBox {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`;