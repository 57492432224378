import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDensityBySpecies } from "./model";

declare global {
    interface IStoreState {
        densitiesBySpecies: IDensityBySpecies[];
    }
}

const densitiesBySpecies = createSlice({
    name: "densitiesBySpecies",
    initialState: null as IDensityBySpecies[],
    reducers: {
        set: (_state, { payload }: PayloadAction<IDensityBySpecies[]>) => payload, // FIXME: tenter d'utiliser l'utils dans un tel cas (array dans redux)
    },
});

export const actionsCreators = {
    ...densitiesBySpecies.actions,
};

export default {
    [densitiesBySpecies.name]: densitiesBySpecies.reducer,
};