import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import { IProductsServer } from "./model";
import slice from "./slices";

export const getAllProductsByMachineThrottled = getThrottleAjax((machineHost: string, configId: string) => (dispatch, getState) => (
    getState().productsByMachines?.[machineHost]?.[configId]
        ? null // data returned by this api is not supposed to change, so we can skip the update if we already have the data
        : ajaxRequest({
            serverLessResolve: () => import("./mocks").then((module) => module.getAllProductsByMachine(machineHost)),
            url: `http://${machineHost}/Optimizer/api/v2/configs/${configId}`,
            onSuccess: (data: { products: IProductsServer; }) => {
                dispatch(slice.actions.setAllByMachineAndConfig({ machineHost, configId, serverProducts: data.products }));
            },
        })
), 30 * 1000, false);