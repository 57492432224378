import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILabels } from "./model";

declare global {
    interface IStoreState {
        labels: ILabels;
    }
}

const useStats = "labels";

const labels = createSlice({
    name: "labels",
    initialState: null as ILabels,
    reducers: {
        set: (state, { payload }: PayloadAction<ILabels>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        patch: (state, { payload }: PayloadAction<ILabels>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: payload, useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...labels.actions,
};

export default {
    [labels.name]: labels.reducer,
};