import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../constants";
import * as mocks from "./mocks";
import { IInterval, IIntervalCategories, IIntervalCategory } from "./model";
import { actionsCreators } from "./slices";

export const getAllIntervals = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => {
            if (_.size(getState().intervalCategories) > 0) return getState().intervalCategories;
            return mocks.getAllCategories();
        },
        url: `${API_PREFIX_PRODUCTION_MANAGER}/intervals`,
        addPrefs: true,
        onSuccess: ((intervalCategories: IIntervalCategories) => {
            dispatch(actionsCreators.set(intervalCategories));
        }),
    })
), 5 * 1000);

export const patchIntervalsByCategory = getAction((toPatchIntervalCategory: IIntervalCategory) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => toPatchIntervalCategory.intervals,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/intervals/${toPatchIntervalCategory.name}`,
        addPrefs: true,
        method: "PATCH",
        data: toPatchIntervalCategory.intervals,
        onSuccess: ((intervals: IInterval[]) => {
            dispatch(actionsCreators.setByType({ ...toPatchIntervalCategory, intervals }));
        }),
    }).promise
));