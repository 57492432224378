import { IDatabaseDefinitions } from "./model";

const definitions = {

    // collection true
    Log: {
        id: "Log",
        collection: true,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2", type: "float" },
            { name: "var3WithReallyLongName", type: "float" },
        ],
        tags: ["tag1", "tag2"],
    },
    Cant: {
        id: "Cant",
        collection: true,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2", type: "float" },
            { name: "var3", type: "float" },
        ],
        tags: ["tag1", "tag2", "tag3"],
    },
    Board: {
        id: "Board",
        collection: true,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2WithLongName", type: "float" },
        ],
        tags: ["tag1", "tag2WithLongName"],
    },

    // collection false
    Stem: {
        id: "Stem",
        collection: false,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2", type: "float" },
            { name: "var3", type: "float" },
            { name: "var4", type: "integer" },
            { name: "var5WithLongName", type: "float" },
        ],
        tags: [] as string[],
    },
    Lumber: {
        id: "Lumber",
        collection: false,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2", type: "float" },
            { name: "var3", type: "float" },
            { name: "var4", type: "integer" },
            { name: "var5", type: "float" },
            { name: "var6", type: "integer" },
            { name: "var7WithLongName", type: "float" },
        ],
        tags: [] as string[],
    },
    Product: {
        id: "Product",
        collection: false,
        modificationStamp: Date.now(),
        fields: [
            { name: "var1", type: "integer" },
            { name: "var2", type: "float" },
            { name: "var3WithReallyLongName", type: "float" },
        ],
        tags: [] as string[],
    },
};

export const getDatabaseDefinitions = (): IDatabaseDefinitions => definitions;