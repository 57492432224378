import { Gauge } from "@comact/crc";
import { IQueryResults } from "js/common";
import { ZoneBackground } from "js/components/ZoneBackground";
import { convertForRadialGauge } from "js/kpis/kpiQueries";
import _ from "lodash";
import * as React from "react";

export const RadialGauge = React.memo<{ results: IQueryResults; showTitle?: boolean; showUnit?: boolean; }>(({ results, showTitle = true, showUnit = true }) => {
    const { value, objective } = _.last(results.data);
    const zones = objective?.zones || [];
    const clampedValue = _.clamp(value, objective?.min, objective?.max);
    const targetZone = zones.find((z) => clampedValue >= z.min && clampedValue < z.max);
    const props = convertForRadialGauge(results);
    if (!showTitle) props.header = null;
    if (!showUnit) props.unit = null;
    return (
        <ZoneBackground className="radialGauge" zone={targetZone ? targetZone.value : "empty"}>
            <Gauge {...props} />
        </ZoneBackground>
    );
});