import { ajaxRequest } from "@comact/crc";
import { INonceReply } from "js/cameraLiveStream/model";

export const getNonce = async (host: string) => (
    ajaxRequest({
        serverLessResolve: () => ({ nonce: "test", realm: "VMS" }),
        url: `http://${host}/api/getNonce`,
        method: "GET",
        onSuccess: ({ reply }: { reply: INonceReply; }) => reply,
    }).promise
);