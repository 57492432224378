import { DateFromNow, getClassNames, getLocalizedText, routing, t, useMapState } from "@comact/crc";
import { IWidget } from "js/dashboards";
import { IOptionsMachineProps } from "js/dashboards/widgets/OptionsMachine";
import { IMachineCodecNode } from "js/node";
import * as React from "react";
import { NodesModule } from "../../node";
import { WidgetContainer, useWidgetSplitProps } from "../components/WidgetContainer";
import "./_machine.scss";

const WidgetMachine = (mixedProps: IWidget<IOptionsMachineProps, "Machine">) => {
    const { container, props } = useWidgetSplitProps<typeof mixedProps>(mixedProps, WidgetMachine.defaultProps, "Machine");
    const nodeMachine = useMapState((state) => NodesModule.selectors.getNodeById<IMachineCodecNode>(state, props.queryRecipe.id), [props.queryRecipe.id]);

    const { name, machine, productionStatus } = nodeMachine || { reported: {} }; // be careful in case where the machine might be missing
    const { state, description, downtimeDescription, lastStateChange } = productionStatus || {};

    const noData = !name || !state;

    return (
        <WidgetContainer
            noData={noData}
            loading={noData}
            errorMessage={!nodeMachine && t("widgets.genericError")}
            {...container}
        >
            {!noData && (
                <WidgetMachineUnconnected
                    state={state}
                    description={downtimeDescription ? getLocalizedText(downtimeDescription, true) : description}
                    lastStateChange={lastStateChange}
                    name={name}
                    model={machine}
                    {...props}
                />
            )}
        </WidgetContainer>
    );
};
WidgetMachine.defaultProps = {
    props: {
        align: "center",
        showTitle: true,
        showImage: true,
        showStatus: true,
        showDescription: true,
        showTime: true,
        queryRecipe: null,
        widgetType: "Machine",
    } as IWidget<IOptionsMachineProps>["props"],
};

export default WidgetMachine;

interface IWidgetMachineProps extends IOptionsMachineProps {
    name: IMachineCodecNode["name"];
    model: IMachineCodecNode["machine"];
    state: IMachineCodecNode["productionStatus"]["state"];
    description: IMachineCodecNode["productionStatus"]["description"];
    lastStateChange: IMachineCodecNode["productionStatus"]["lastStateChange"];
}

const WidgetMachineUnconnected = React.memo<IWidgetMachineProps>((props) => {
    const { align, showDescription, showImage, showStatus, showTime, showTitle, state, description, lastStateChange, name, model } = props;

    const classNameStatus = (() => {
        switch (state) {
            case "RUNNING":
            case "RUNNING_SHIFT_IN_PROGRESS": return "running";
            case "BREAK": case "IDLE": return "idle";
            case "DOWN": return "error";
            case "UNKNOWN":
            default: return "unknown";
        }
    })();

    return (
        <div className={getClassNames(["machine", classNameStatus, "align-" + align])}>
            {showTitle &&
                <header>{name}</header>
            }
            {showImage && (
                <div className="imageContainer">
                    <img src={`${routing.baseUrl}/static/machines/${model}.png`} />
                </div>
            )}
            {(showStatus || showTime || showDescription) && (
                <footer>
                    {showStatus &&
                        <span className="state">{t("widgets.machine.status." + state)}</span>
                    }
                    {showTime && lastStateChange && (
                        <span className="lastStateChange">
                            <DateFromNow timestamp={lastStateChange} />
                        </span>
                    )}
                    {showDescription && description &&
                        <span className="description">{description}</span>
                    }
                </footer>
            )}
        </div>
    );
});