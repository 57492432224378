// THIS TYPESCRIPT FILE WAS GENERATED BY './build/modelAi_detections.task.gulp.js'. DO NOT MODIFY
/* eslint-disable quote-props */
// eslint-disable-next-line object-curly-spacing
const DETECTIONS = {
    LABEL_BACKGROUND: {
        id: "LABEL_BACKGROUND",
        title: {
            values: {
                en: "Background",
                fr: "Fond",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BATON_VIDE: {
        id: "LABEL_BATON_VIDE",
        title: {
            values: {
                en: "Empty stick",
                fr: "Bâton vide",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_SAIN: {
        id: "LABEL_SAIN",
        title: {
            values: {
                en: "Sound",
                fr: "Sain",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_INDETERMINE: {
        id: "LABEL_INDETERMINE",
        title: {
            values: {
                en: "Unknown",
                fr: "Indéterminé",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_OBJ: {
        id: "LABEL_REJET_OBJ",
        title: {
            values: {
                en: "Rejected object",
                fr: "Rejet d'objet",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_OBJ: {
        id: "TRAITEMENT_OBJ",
        title: {
            values: {
                en: "Object processing ",
                fr: "Traitement d'objet",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BLUE_STAIN: {
        id: "LABEL_BLUE_STAIN",
        title: {
            values: {
                en: "Blue stain",
                fr: "Coloration bleue",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_BLUE_STAIN: {
        id: "LABEL_REJET_BLUE_STAIN",
        title: {
            values: {
                en: "Rejected blue stain",
                fr: "Rejet coloration bleue",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CRACK: {
        id: "LABEL_CRACK",
        title: {
            values: {
                en: "Cracks",
                fr: "Craques",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_CRACK: {
        id: "LABEL_REJET_CRACK",
        title: {
            values: {
                en: "Rejected split",
                fr: "Rejet fente",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BOIS_ALTERE: {
        id: "LABEL_BOIS_ALTERE",
        title: {
            values: {
                en: "Unsound wood",
                fr: "Bois altéré",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_BOIS_ALTERE: {
        id: "LABEL_REJET_BOIS_ALTERE",
        title: {
            values: {
                en: "Rejected unsound wood",
                fr: "Rejet de bois altéré",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_OBJ: {
        id: "FIN_TRAITEMENT_OBJ",
        title: {
            values: {
                en: "End object processing",
                fr: "Traitement de fin d'objet",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_NORMAL: {
        id: "TRAITEMENT_NORMAL",
        title: {
            values: {
                en: "Normal processing",
                fr: "Traitement normal",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_NOEUD_PALE: {
        id: "LABEL_NOEUD_PALE",
        title: {
            values: {
                en: "Blond knots",
                fr: "Noeuds pâles",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_NOEUD_SAIN: {
        id: "LABEL_NOEUD_SAIN",
        title: {
            values: {
                en: "Sound knots",
                fr: "Noeuds sain",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_MOELLE: {
        id: "LABEL_MOELLE",
        title: {
            values: {
                en: "Pith",
                fr: "Moelle",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_NOEUD_TRACHEID: {
        id: "LABEL_NOEUD_TRACHEID",
        title: {
            values: {
                en: "Tracheid knots",
                fr: "Noeuds tracheid",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PITCH_POCKET: {
        id: "LABEL_PITCH_POCKET",
        title: {
            values: {
                en: "Pitch pockets",
                fr: "Poche de résine",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_NORMAL: {
        id: "FIN_TRAITEMENT_NORMAL",
        title: {
            values: {
                en: "End normal processing",
                fr: "Traitement normal de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_TEINTE: {
        id: "TRAITEMENT_TEINTE",
        title: {
            values: {
                en: "Tint treatment",
                fr: "Traitement de teinte",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLORATION_ROUGE: {
        id: "LABEL_COLORATION_ROUGE",
        title: {
            values: {
                en: "Red stain",
                fr: "Coloration rouge",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_TEINTE: {
        id: "FIN_TRAITEMENT_TEINTE",
        title: {
            values: {
                en: "End tint processing",
                fr: "Traitement de teinte de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_FENTE: {
        id: "TRAITEMENT_FENTE",
        title: {
            values: {
                en: "Split processing",
                fr: "Traitement de fentes",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_FONCEE_MINCE: {
        id: "LABEL_FENTE_FONCEE_MINCE",
        title: {
            values: {
                en: "Narrow dark splits",
                fr: "Fentes foncées minces",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_FONCEE_LARGE: {
        id: "LABEL_FENTE_FONCEE_LARGE",
        title: {
            values: {
                en: "Large dark splits",
                fr: "Fentes foncées larges",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_FONCEE_TRES_LARGE: {
        id: "LABEL_FENTE_FONCEE_TRES_LARGE",
        title: {
            values: {
                en: "Narrow open dark splits",
                fr: "Fentes foncées très larges",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_PALE_MINCE: {
        id: "LABEL_FENTE_PALE_MINCE",
        title: {
            values: {
                en: "Narrow light splits",
                fr: "Fentes pâles minces",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_PALE_LARGE: {
        id: "LABEL_FENTE_PALE_LARGE",
        title: {
            values: {
                en: "Large light splits",
                fr: "Fentes pâles larges",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_MOELLE: {
        id: "LABEL_FENTE_MOELLE",
        title: {
            values: {
                en: "Pith splits",
                fr: "Fentes de la moelle",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE_GEO: {
        id: "LABEL_FENTE_GEO",
        title: {
            values: {
                en: "Geo splits",
                fr: "Fentes de la géo",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_FENTE: {
        id: "FIN_TRAITEMENT_FENTE",
        title: {
            values: {
                en: "End processing splits",
                fr: "Traitement des fentes de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_ZONE: {
        id: "TRAITEMENT_ZONE",
        title: {
            values: {
                en: "Processing zone",
                fr: "Zone de traitement",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PIQUE_BLANC: {
        id: "LABEL_PIQUE_BLANC",
        title: {
            values: {
                en: "Whitespecks",
                fr: "Pique blanc",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CARIE_ALVEOLAIRE: {
        id: "LABEL_CARIE_ALVEOLAIRE",
        title: {
            values: {
                en: "Honeycomb",
                fr: "Carie alvéolaire",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLORATION: {
        id: "LABEL_COLORATION",
        title: {
            values: {
                en: "Stain",
                fr: "Coloration",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLORATION_PALE: {
        id: "LABEL_COLORATION_PALE",
        title: {
            values: {
                en: "Light stain",
                fr: "Coloration pâle",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BARK: {
        id: "LABEL_BARK",
        title: {
            values: {
                en: "Bark",
                fr: "Écorce",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_OMISSION: {
        id: "LABEL_OMISSION",
        title: {
            values: {
                en: "Skip",
                fr: "Omission",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BRULURE: {
        id: "LABEL_BRULURE",
        title: {
            values: {
                en: "Burn marks",
                fr: "Brulûres",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_DEFAUT_APPARENCE: {
        id: "LABEL_DEFAUT_APPARENCE",
        title: {
            values: {
                en: "Appearence defects",
                fr: "Défauts d'apparences",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_1: {
        id: "LABEL_PEINTURE_1",
        title: {
            values: {
                en: "ColorMark 1",
                fr: "Peinture 1",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_2: {
        id: "LABEL_PEINTURE_2",
        title: {
            values: {
                en: "ColorMark 2",
                fr: "Peinture 2",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_3: {
        id: "LABEL_PEINTURE_3",
        title: {
            values: {
                en: "ColorMark 3",
                fr: "Peinture 3",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_4: {
        id: "LABEL_PEINTURE_4",
        title: {
            values: {
                en: "ColorMark 4",
                fr: "Peinture 4",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_5: {
        id: "LABEL_PEINTURE_5",
        title: {
            values: {
                en: "ColorMark 5",
                fr: "Peinture 5",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_6: {
        id: "LABEL_PEINTURE_6",
        title: {
            values: {
                en: "ColorMark 6",
                fr: "Peinture 6",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_7: {
        id: "LABEL_PEINTURE_7",
        title: {
            values: {
                en: "ColorMark 7",
                fr: "Peinture 7",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_8: {
        id: "LABEL_PEINTURE_8",
        title: {
            values: {
                en: "ColorMark 8",
                fr: "Peinture 8",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_9: {
        id: "LABEL_PEINTURE_9",
        title: {
            values: {
                en: "ColorMark 9",
                fr: "Peinture 9",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_10: {
        id: "LABEL_PEINTURE_10",
        title: {
            values: {
                en: "ColorMark 10",
                fr: "Peinture 10",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_11: {
        id: "LABEL_PEINTURE_11",
        title: {
            values: {
                en: "ColorMark 11",
                fr: "Peinture 11",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_12: {
        id: "LABEL_PEINTURE_12",
        title: {
            values: {
                en: "ColorMark 12",
                fr: "Peinture 12",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_13: {
        id: "LABEL_PEINTURE_13",
        title: {
            values: {
                en: "ColorMark 13",
                fr: "Peinture 13",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_14: {
        id: "LABEL_PEINTURE_14",
        title: {
            values: {
                en: "ColorMark 14",
                fr: "Peinture 14",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_15: {
        id: "LABEL_PEINTURE_15",
        title: {
            values: {
                en: "ColorMark 15",
                fr: "Peinture 15",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_16: {
        id: "LABEL_PEINTURE_16",
        title: {
            values: {
                en: "ColorMark 16",
                fr: "Peinture 16",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_17: {
        id: "LABEL_PEINTURE_17",
        title: {
            values: {
                en: "ColorMark 17",
                fr: "Peinture 17",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_18: {
        id: "LABEL_PEINTURE_18",
        title: {
            values: {
                en: "ColorMark 18",
                fr: "Peinture 18",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_19: {
        id: "LABEL_PEINTURE_19",
        title: {
            values: {
                en: "ColorMark 19",
                fr: "Peinture 19",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PEINTURE_20: {
        id: "LABEL_PEINTURE_20",
        title: {
            values: {
                en: "ColorMark 20",
                fr: "Peinture 20",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CARIE_BRUNE: {
        id: "LABEL_CARIE_BRUNE",
        title: {
            values: {
                en: "Brown honeycomb",
                fr: "Carie brune",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLORATION_BLEU: {
        id: "LABEL_COLORATION_BLEU",
        title: {
            values: {
                en: "Blue stain",
                fr: "Coloration bleue",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_WORM_EATEN_PITCH: {
        id: "LABEL_WORM_EATEN_PITCH",
        title: {
            values: {
                en: "Worm eaten pitch",
                fr: "Trous de vers remplis de résine",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_ZONE: {
        id: "FIN_TRAITEMENT_ZONE",
        title: {
            values: {
                en: "End zone processing",
                fr: "Traitement de zone de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_TROUS_DE_VERS: {
        id: "LABEL_TROUS_DE_VERS",
        title: {
            values: {
                en: "Grub holes",
                fr: "Trous de vers",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_LASER: {
        id: "LABEL_LASER",
        title: {
            values: {
                en: "Laser",
                fr: "Laser",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_TROUS_DE_VERS_PALE: {
        id: "LABEL_TROUS_DE_VERS_PALE",
        title: {
            values: {
                en: "Light grub holes",
                fr: "Trous de vers pâle",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_GRAIN: {
        id: "TRAITEMENT_GRAIN",
        title: {
            values: {
                en: "Wood grain processing",
                fr: "Traitement du grain",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_GRAIN: {
        id: "LABEL_GRAIN",
        title: {
            values: {
                en: "Wood grain",
                fr: "Grains",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_GRAIN_DISTORTION: {
        id: "LABEL_GRAIN_DISTORTION",
        title: {
            values: {
                en: "Grain distortion",
                fr: "Distortion du grain",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_GRAIN: {
        id: "FIN_TRAITEMENT_GRAIN",
        title: {
            values: {
                en: "End wood grain processing",
                fr: "Traitement du grain de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_TORN_GRAIN: {
        id: "LABEL_TORN_GRAIN",
        title: {
            values: {
                en: "Torn grain",
                fr: "Grains arraché",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    TRAITEMENT_OBJ_ENDCAM: {
        id: "TRAITEMENT_OBJ_ENDCAM",
        title: {
            values: {
                en: "End camera object processing",
                fr: "Traitement d'objet de caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BLUE_STAIN_ENDCAM: {
        id: "LABEL_BLUE_STAIN_ENDCAM",
        title: {
            values: {
                en: "End camera blue stains",
                fr: "Taches bleues de caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_BLUE_STAIN_ENDCAM: {
        id: "LABEL_REJET_BLUE_STAIN_ENDCAM",
        title: {
            values: {
                en: "End camera blue stain rejection",
                fr: "Rejet de taches bleues de caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CRACK_ENDCAM: {
        id: "LABEL_CRACK_ENDCAM",
        title: {
            values: {
                en: "End camera cracks",
                fr: "Craques de caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_CRACK_ENDCAM: {
        id: "LABEL_REJET_CRACK_ENDCAM",
        title: {
            values: {
                en: "End camera rejected splits",
                fr: "Rejet fentes caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BOIS_ALTERE_ENDCAM: {
        id: "LABEL_BOIS_ALTERE_ENDCAM",
        title: {
            values: {
                en: "End camera unsound wood",
                fr: "Bois altéré caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_REJET_BOIS_ALTERE_ENDCAM: {
        id: "LABEL_REJET_BOIS_ALTERE_ENDCAM",
        title: {
            values: {
                en: "End camera rejected unsound wood",
                fr: "Rejet bois altéré caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_GRAIN_ENDCAM: {
        id: "LABEL_GRAIN_ENDCAM",
        title: {
            values: {
                en: "End camera wood grain",
                fr: "Grain caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    FIN_TRAITEMENT_OBJ_ENDCAM: {
        id: "FIN_TRAITEMENT_OBJ_ENDCAM",
        title: {
            values: {
                en: "End camera object end processing",
                fr: "Traitement de fin d'ojet de caméra de bout",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_SPECIES: {
        id: "LABEL_SPECIES",
        title: {
            values: {
                en: "Species",
                fr: "Essences",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_GENERIQUE: {
        id: "LABEL_GENERIQUE",
        title: {
            values: {
                en: "Generic",
                fr: "Générique",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLOR_MARK: {
        id: "LABEL_COLOR_MARK",
        title: {
            values: {
                en: "Color marks",
                fr: "Marques de peinture",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_COLOR_MARK_COLOR: {
        id: "LABEL_COLOR_MARK_COLOR",
        title: {
            values: {
                en: "Color mark color",
                fr: "Couleur de la marque de peinture",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_SAW_CUT: {
        id: "LABEL_SAW_CUT",
        title: {
            values: {
                en: "Saw cut",
                fr: "Traits de scies",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PITH_LOCATION_Y: {
        id: "LABEL_PITH_LOCATION_Y",
        title: {
            values: {
                en: "Pith location Y",
                fr: "Position de la moelle Y",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PITH_LOCATION_X: {
        id: "LABEL_PITH_LOCATION_X",
        title: {
            values: {
                en: "Pith location X",
                fr: "Position de la moelle X",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_DENSITE: {
        id: "LABEL_DENSITE",
        title: {
            values: {
                en: "Density",
                fr: "Densité",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_PIN_HOLE: {
        id: "LABEL_PIN_HOLE",
        title: {
            values: {
                en: "Pin hole",
                fr: "Trou minuscules",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_AOI: {
        id: "LABEL_AOI",
        title: {
            values: {
                en: "Area of interest",
                fr: "Zone d'intérêt",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_FENTE: {
        id: "LABEL_FENTE",
        title: {
            values: {
                en: "Split",
                fr: "Fente",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CASSURE: {
        id: "LABEL_CASSURE",
        title: {
            values: {
                en: "Break",
                fr: "Cassure",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_BARK_WANE: {
        id: "LABEL_BARK_WANE",
        title: {
            values: {
                en: "Bark wane",
                fr: "Flache",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_DOUBLE_BOARD: {
        id: "LABEL_DOUBLE_BOARD",
        title: {
            values: {
                en: "Double board",
                fr: "Double planches",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CUTTING_TOOL: {
        id: "LABEL_CUTTING_TOOL",
        title: {
            values: {
                en: "Cutting tool",
                fr: "Outil de coupe",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CUTTING_TOOL_CANTER_CONIQUE: {
        id: "LABEL_CUTTING_TOOL_CANTER_CONIQUE",
        title: {
            values: {
                en: "Cutting tool conical canter",
                fr: "Outil de coupe canter cônique",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    LABEL_CUTTING_TOOL_TBL: {
        id: "LABEL_CUTTING_TOOL_TBL",
        title: {
            values: {
                en: "Cutting tool circular saws",
                fr: "Outil de coupe scies circulaires",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
    NB_LABEL: {
        id: "NB_LABEL",
        title: {
            values: {
                en: "Number of labels",
                fr: "Nombre de labels",
                pt: "Portugais",
                fi: "Finlandais",
            },
        },
    },
} as const;
export default DETECTIONS;