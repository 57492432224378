import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { ILabelsGroupServer } from "../labelGroups";
import { getLabelGroupsByType } from "../labelGroups/mocks";
import { DEFAULT_NODE_ID, ILabelServer, ILabelTypes, LABELS_TYPE_NAMES } from "./model";

const rand = createSeeder();

export const getLabelsByType = (type: ILabelTypes) => {
    rand.seed(`getLabels_${type}`);
    const labelGroups = getLabelGroupsByType(type);
    return _.reduce(labelGroups, (prev, labelGroup) => {
        _.times(rand.intBetween(5, 20), (index) => {
            prev.push(getLabel(index, labelGroup));
        });
        return prev;
    }, [] as ILabelServer[]);
};

const getLabel = (index: number, labelGroup: ILabelsGroupServer): ILabelServer => ({
    id: `${labelGroup.type}-${index + 1}`,
    code: index,
    name: {
        values: {
            fr: `Libellé ${LABELS_TYPE_NAMES[labelGroup.type]} #${index + 1}`,
            en: `Label ${LABELS_TYPE_NAMES[labelGroup.type]} #${index + 1}`,
        },
    },
    groupId: parseInt(labelGroup.id, 10),
    nodeId: DEFAULT_NODE_ID, // FIXME: mock machine too
    type: labelGroup.type,
});