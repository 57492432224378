import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../../constants";
import { IKpiDataIO } from "./model";
import { actionsCreators } from "./slices";

export const getDataIOsThrottled = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().kpiDataIOs ? _.values(getState().kpiDataIOs) : import("./mocks").then((m) => m.getDataIOs()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/data-io-kpi`,
        onSuccess: (kpiDataIOs: IKpiDataIO[]) => {
            dispatch(actionsCreators.set(kpiDataIOs));
        },
    })
), 10 * 1000);

export const saveKpiDataIOByMachineNodeId = (machineNodeId: string, kpiDataIOs: IKpiDataIO[]) => (dispatch: IStoreDispatch) => ajaxRequest({
    serverLessResolve: () => kpiDataIOs,
    method: "PATCH",
    data: kpiDataIOs,
    url: `${API_PREFIX_PRODUCTION_MANAGER}/data-io-kpi?machineNodeId=${machineNodeId}`,
    showAjaxLoading: true,
    onSuccess: ((returnedKpiDataIOs: IKpiDataIO[]) => {
        dispatch(actionsCreators.patch({ kpiDataIOs: returnedKpiDataIOs, machineNodeId }));
    }),
}).promise;