import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import { API_TWIN_V1 } from "../constants";
import { ITemplate } from "./model";
import { actionsCreators } from "./slices";

export const fetchTemplates = getThrottleAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.fetchTemplates()),
        url: `${API_TWIN_V1}/templates`,
        onSuccess: (templatesFromServer: ITemplate[]) => {
            dispatch(actionsCreators.set(templatesFromServer));
        },
    })
), 5 * 1000);

export const saveTemplate = getAction((template: ITemplate) => async (dispatch) => (
    template.id
        ? ajaxRequest({
            url: `${API_TWIN_V1}/templates/${template.id}`,
            method: "PUT",
            data: template,
            onSuccess: ((templateFromServer: ITemplate) => {
                dispatch(actionsCreators.patch([templateFromServer]));
            }),
        }).promise
        : ajaxRequest({
            url: `${API_TWIN_V1}/templates`,
            method: "POST",
            data: template,
            onSuccess: ((templateFromServer: ITemplate) => {
                dispatch(actionsCreators.patch([templateFromServer]));
            }),
        }).promise
));

export const deleteTemplate = getAction((id: string) => async (dispatch) => (
    ajaxRequest({
        url: `${API_TWIN_V1}/templates/${id}`,
        method: "DELETE",
        onSuccess: (() => {
            dispatch(actionsCreators.delete([id]));
        }),
    }).promise
));
