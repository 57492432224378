import { getLocalizedText } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import { IWidget } from "js/dashboards";
import { IOptionsMultiKpisProps } from "js/dashboards/widgets/OptionsMultiKpis";
import { ViewSingleRenderer } from "js/dashboards/widgets/ViewSingleRenderer";
import { useGetKpiQueryResults } from "js/kpis/kpiQueries";
import _ from "lodash";
import * as React from "react";
import { WidgetContainer, useWidgetSplitProps } from "../components/WidgetContainer";

const WidgetGrid = styled.div`

    body .editMode .resizing & > * {
        outline: 1px dashed rgba(255, 255, 255, .2);
    }

    display: inline-grid;

    grid-template-columns: repeat(var(--colCount), 1fr);
    grid-template-rows: repeat(var(--rowCount), 1fr);
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    > * {
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        > .gauge{
            display: flex;
            width: 100%;
            flex-flow: column;
            justify-content: center;
            height: 100%;
            > svg{
                max-height: 100%;
                width: 100%;
                height: 100%;
            }
        }
    }
    grid-gap: ${CSS.gutter}px;
    &.viewType-Gauge, &.viewType-TextBox, &.viewType-TextBoxInline, &.viewType-LineGraph {
        > *:not(:only-child) { /* when more than one element */
            border-radius: ${CSS.borderRadius}px;
            background-color: rgba(255, 255, 255, .02);
        }
    }
    &.viewType-Gauge{
        > * {
            padding-top: ${CSS.gutter}px;
        }
    }
`;

/**
 * Returns an array of indexes at a specified interval
 * @param array Either an array ([]) or an array length (number)
 * @param limit Max amount of indexes to be returned
 * @param interval Interval at which the indexes are generated
 * @param jumps (optional) specify the amount of jumps between intervals
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCarousel = (array: any[] | number, limit: number, interval: number, jumps = 1) => {
    const [start, setStart] = React.useState(0);
    const indexes = React.useMemo(() => _.times(typeof array === "number" ? array : array.length, (n) => n), [array]);

    const split = React.useMemo(() => {
        if (indexes.length <= limit) return indexes; // No split necessary

        const beginning = indexes.slice(start, limit + start); // split as much as possible
        const end = beginning.length < limit ? indexes.slice(0, limit - beginning.length) : []; // fill the blanks with indexes from the beginning

        return [...beginning, ...end];
    }, [start, indexes, limit]);

    React.useEffect(() => {
        // Carousel mode only when necessary (more indexes than cells)
        if (indexes.length <= limit) {
            if (start != 0) setStart(0); // Reset start to 0 when going from carousel to non-carousel
            return () => null;
        }

        const carouselInterval = window.setInterval(() => {
            setStart((prev) => {
                const next = prev + jumps;
                return next < indexes.length ? next : 0;
            });
        }, interval);

        return () => {
            window.clearInterval(carouselInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setStart, indexes, limit, interval, jumps]); // "start" not in deps to prevent resetting the effect at every interval

    return split;
};

const WidgetMultiKpis = (mixedProps: IWidget<IOptionsMultiKpisProps, "MultiKpis">) => {
    const { container, props } = useWidgetSplitProps<typeof mixedProps>(mixedProps, WidgetMultiKpis.defaultProps, "MultiKpis");

    const { viewType, showTitle, showUnit, colCount, rowCount, interval, jumpMode, title, carouselEnabled, kpiQueryRecipes, sort, titleFormula } = props;
    const limit = viewType != "TextBoxWithGraph" ? 1 : null;
    const fill = viewType == "TextBoxWithGraph" ? "none" : "null";
    const { results, errorMessage, ajaxError, loading } = useGetKpiQueryResults({ queryRecipes: kpiQueryRecipes, titleFormula, limit, fill, sort, callerName: `widgetMultiKpis-${container.id}` });

    const noData = _.isEmpty(results);

    const limitCarousel = colCount * rowCount;
    const kpiIndexes = useCarousel(carouselEnabled && results ? results.length : limitCarousel, limitCarousel, interval, jumpMode == "one" ? 1 : limit);
    return (
        <WidgetContainer
            loading={loading}
            noData={noData}
            errorMessage={errorMessage}
            ajaxError={ajaxError}
            title={getLocalizedText(title)}
            {...container}
        >
            {!noData && (
                <WidgetGrid className={"viewType-" + viewType} style={{ "--colCount": colCount, "--rowCount": rowCount } as React.CSSProperties /* inject CSS variables by style */}>
                    {_.map(kpiIndexes, (i) => {
                        if (!results[i]) return null;
                        const { patternKey, definitionId } = results[i];
                        return <ViewSingleRenderer result={results[i]} viewType={viewType} showTitle={showTitle} showUnit={showUnit} key={`${i}/${patternKey}/${definitionId}`} />;
                    })}
                </WidgetGrid>
            )}
        </WidgetContainer>
    );
};

WidgetMultiKpis.defaultProps = {
    props: {
        title: { values: { en: "", fr: "", pt: "", fi: "" } },
        kpiQueryRecipes: {
            contexts: null,
            kpis: null,
            nodes: null,
        },
        viewType: "Gauge",
        showTitle: true,
        showUnit: true,
        rowCount: 1,
        colCount: 1,
        interval: 3000,
        jumpMode: "one",
        carouselEnabled: false,
        sort: {
            by: "kpi_node_context",
            asc: false,
        },
    } as IWidget<IOptionsMultiKpisProps>["props"],
};

export default WidgetMultiKpis;