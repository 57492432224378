import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export const pickAndPlaceEventTypes = ["pick-and-place-instance", "pick-and-place-image"] as const;
export const punctualEventTypes = ["smart-vision", "punctual-other", ...pickAndPlaceEventTypes] as const;
export const alarmEventTypes = ["duration-other"] as const;
export const eventTypes = [...punctualEventTypes, ...alarmEventTypes] as const;
export type IEventType = typeof eventTypes[number];
export const prioritiesList = ["LOW", "NORMAL", "HIGH", "EMERGENCY"] as const;
export const severitiesList = ["TRIVIAL", "NORMAL", "MAJOR", "BLOCKER", "CRITICAL"] as const;

export const idTypes = ["plcId", "optiId"] as const;
export type IIdType = typeof idTypes[number];

export const sortOrders = ["asc", "desc"] as const;
export type ISortOrder = typeof sortOrders[number];

interface IEventAbstract {
    id: string; // Unique ID, GUID format
    nodeId: string; // ID of the node attached to the event
    type: unknown;
    date: number; // UNIX Timestamp indicating the moment of the event
    title: ILocalizedString; // Localized title of the event
    description: ILocalizedString; // Localized description of the event
    [key: string]: unknown; // Depending on the type, additional data will be added.
}

interface IEventAlarmAbstract extends IEventAbstract {
    endDate: number;
    priority: typeof prioritiesList[number];
    severity: typeof severitiesList[number];
}

export interface IEventPunctualServer extends Omit<IEventAbstract, "type"> {
    type: unknown;
}

export interface IEventAlarmServer extends Omit<IEventAlarmAbstract, "type"> {
    type: unknown;
}

export interface IEventSmartVision extends IEventAbstract {
    type: "smart-vision";
    dateStr: string; // Date in string, used for correspondance with archives
}

export interface IEventPickAndPlaceInstance extends IEventAbstract {
    type: "pick-and-place-instance";
    payloadId: string;
    imageUuids: string[];
    imageIds: number[];
    optiId: string;
    plcId: string;
    length: number;
    width: number;
    height: number;
    angle: number;
    pitch: number;
    roll: number;
    surface: number;
    surfaceBox: number;
    volume: number;
    volumeBox: number;
    xCenter: number;
    yCenter: number;
    zCenter: number;
}

export interface IEventPickAndPlaceImage extends IEventAbstract {
    type: "pick-and-place-image";
    payloadId: string;
    imageUuid: string;
    imageId: number;
    width: number;
    height: number;
    xOrigin: number;
    xRes: number;
    yOrigin: number;
    yRes: number;
    zLine0: number;
    zRes: number;
}

export interface IEventPunctualOther extends IEventAbstract {
    type: "punctual-other";
}

export type IEventPunctual = IEventSmartVision | IEventPickAndPlaceInstance | IEventPickAndPlaceImage | IEventPunctualOther;

export interface IEventAlarm extends IEventAlarmAbstract {
    type: typeof alarmEventTypes[number];
}

export type IEvent = IEventPunctual | IEventAlarm;

export type IEvents = Record<string, IEvent>;

export const isEventPunctual = (event: IEvent): event is IEventPunctual => _.includes(punctualEventTypes, event.type);
export const isEventAlarm = (event: IEvent): event is IEventAlarm => _.includes(alarmEventTypes, event.type);
export const isValidEventType = (eventType: string): eventType is IEventType => _.includes(eventTypes, eventType);

export interface IArchive {
    id: string;
    originalFileName: string;
    date: string;
}

export interface IFile {
    name: string;
    type: string;
    size: number;
}