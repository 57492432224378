import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { fieldTypes, IField, IZoneConfigurationServer, IZoneTemplate } from "./model";

const rand = createSeeder();

export const getZoneConfigurationBasic = (): IZoneConfigurationServer => {
    rand.seed("getZoneConfiguration");
    return {
        active: !rand.intBetween(0, 1),
        template: "template",
        fields: (
            _.times(rand.intBetween(5, 20), (i) => {
                const type = fieldTypes[i % _.size(fieldTypes)];
                return ({
                    id: i.toString(),
                    type,
                    label: {
                        en: `label${i}`,
                        fr: `label${i}`,
                    },
                    desc: {
                        en: `desc${i}`,
                        fr: `desc${i}`,
                    },
                    value: (() => {
                        switch (type) {
                            case "str": return `str${i}`;
                            case "ipaddress": return `${i}.${i + 1}.${i + 2}.${i + 3}`;
                            case "bool": return !rand.intBetween(0, 1);
                            case "float": return rand.floatBetween(0, 100);
                            case "int": return rand.intBetween(0, 100);
                            case "warp": return ([[10, 10], [90, 10], [90, 90], [10, 90]]);
                            case "choice": return "choice 1";
                            case "aoi": return ([0, 15, 511, 70]);
                        }
                    })(),
                    choices: type == "choice" ? ["choice 1", "choice 2"] : null,
                } as IField);
            })),
    };
};

export const getZoneConfigurationAdvanced = (): string => (
    `- template: transversalanomaly
- !Pipeline
    name: TransversalAnomaly
    stages:
        - !Stage {
            name: Clock-1,
            worker: chaperon.HeartBeat,
            rate: 4,
            editables: [rate],
        }`
);

export const getConfigTemplates = (): IZoneTemplate[] => (
    _.times(5, (i) => ({
        id: `new_${i}`,
        label: {
            en: "label",
            fr: "label",
        },
        desc: {
            en: "desc",
            fr: "desc",
        },
    }))
);
