import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ILineSpeed, ILineSpeeds } from "./model";

declare global {
    interface IStoreState {
        lineSpeeds: ILineSpeeds;
    }
}

const useStats = "lineSpeeds";

const lineSpeeds = createSlice({
    name: "lineSpeeds",
    initialState: null as ILineSpeeds,
    reducers: {
        patch: (state, { payload }: PayloadAction<ILineSpeed[]>) => (
            Utils.slices.patchWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ scannerId }) => scannerId), useStats })
        ),
    },
});

export const actionsCreators = {
    ...lineSpeeds.actions,
};

export default {
    [lineSpeeds.name]: lineSpeeds.reducer,
};