import { Cache } from "@comact/crc";
import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { getAllNodes } from "../../node/mocks";
import { getContextDefinitions } from "../contextDefinitions/mocks";
import { getKpiPatterns } from "../kpiPatterns/mocks";
import { createNewKpiDataIO } from "./model";

const rand = createSeeder();

export const getDataIOs = () => (
    Cache.remember("getDataIOs", () => {
        rand.seed("getDataIOs");

        const kpiPatterns = _.reduce(getKpiPatterns(), (acc, { collection, uniqueName, kpiDefinitions }) => {
            _.forEach(kpiDefinitions, ({ id }) => {
                if (collection && id == "default") return;
                acc.push({ uniqueName, definitionId: id });
            });
            return acc;
        }, []);

        const contextDefinitionIds = _.map(getContextDefinitions(), ({ id }) => id);

        const nodeMocks = getAllNodes();

        const scanners = _.chain(nodeMocks)
            .filter(({ templateName }) => templateName == "scannerCodec")
            .map(({ id, parentId }) => ({ id, parentId }))
            .value();

        const machineNodeIds = _.chain(nodeMocks)
            .filter(({ templateName }) => templateName == "machineCodec")
            .map(({ id }) => id)
            .value();

        return _.times(50, (index) => {
            const kpiPatternIndex = rand.intBetween(0, _.size(kpiPatterns) - 1);
            const machineNodeId = machineNodeIds[rand.intBetween(0, _.size(machineNodeIds) - 1)];
            const scannersFiltered = _.filter(scanners, ({ parentId }) => parentId == machineNodeId);

            return createNewKpiDataIO({
                patternKey: kpiPatterns[kpiPatternIndex].uniqueName,
                definitionId: kpiPatterns[kpiPatternIndex].definitionId,
                contextId: contextDefinitionIds[rand.intBetween(0, _.size(contextDefinitionIds) - 1)],
                nodeId: scannersFiltered[rand.intBetween(0, _.size(scannersFiltered) - 1)].id,
                factor: index + 1,
                index,
                machineNodeId,
            });
        });
    })
);