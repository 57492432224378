import { memoize } from "proxy-memoize";
import { ISmartVisionZoneNode } from "../node";
import { ISmartVisionZoneAddress } from "./model";

export const getBasicConfigById = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => state.zoneConfigurationsBasic?.[nodeId];

export const getAdvancedConfigById = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => state.zoneConfigurationsAdvanced?.[nodeId];

export const getConfigTemplates = ({ zoneConfigurationsTemplates }: IStoreState) => zoneConfigurationsTemplates;

export const getSmartVisionZoneAddress = memoize(({ state, nodeId }: { state: IStoreState; nodeId: string; }): ISmartVisionZoneAddress => {
    const { host, port } = state.nodes[nodeId] as ISmartVisionZoneNode;
    return { host, port };
});

export const getConfigStatus = ({ state, nodeId }: { state: IStoreState; nodeId: string; }) => state.zoneConfigurationsActivationStatus?.[nodeId];